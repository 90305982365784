import { useCallback, useEffect, useState } from 'react'

import { Button } from 'antd'
import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete'
import Input from 'antd/lib/input'
import axios from 'axios'
import Modal from 'components/Modal'
import Spacing from 'components/Spacing'
import {
  ADDRESS_COAST_OPTIONS,
  ADDRESS_SKI_STATION_OPTIONS,
  BLACK_LIST_WORD_ADDRESS,
} from 'constants/address'
import { TRANSFER_TYPES } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import usePlacesAutocomplete, {
  HookArgs,
  getDetails,
} from 'use-places-autocomplete'

import * as S from './styles'
type AddressSelectProps = {
  translationKey: string
  formKey: string
  transferType?: string
  disabled?: boolean
}

export type PlaceDetails = {
  addressParts: AddressPart[]
  geo_code: string
}

type AddressPart = {
  long_name: string
  short_name: string
  types: string[]
}

type AddressRequest = {
  cidade: string
  bairro: string
  endereco: string
}

type HandleSetAddressProps = {
  address: AddressRequest
  value: string
}

export enum AddressType {
  ROUTE = 'route',
  LOCALITY = 'locality',
  NEIGHBORHOOD = 'neighborhood',
  ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
}

export const handleSetAddress: Record<
  string,
  ({ address, value }: HandleSetAddressProps) => void
> = {
  [AddressType.ROUTE]: ({ address, value }: HandleSetAddressProps) => {
    address.endereco = value
  },
  [AddressType.LOCALITY]: ({ address, value }: HandleSetAddressProps) => {
    if (address.endereco === '' || !address.endereco) {
      address.endereco = value
    }
    address.cidade = value
    address.bairro = value
  },
  [AddressType.NEIGHBORHOOD]: ({ address, value }: HandleSetAddressProps) => {
    address.bairro = value
  },
  [AddressType.ADMINISTRATIVE_AREA_LEVEL_2]: ({
    address,
    value,
  }: HandleSetAddressProps) => {
    address.cidade = value
  },
  [AddressType.ADMINISTRATIVE_AREA_LEVEL_3]: ({
    address,
    value,
  }: HandleSetAddressProps) => {
    address.bairro = value
  },
}

export default function AddressSelect({
  translationKey,
  formKey,
  transferType,
  disabled,
}: AddressSelectProps) {
  const {
    openModalInvalidAddress,
    setCurrentTransferType,
    openModalAddressIncludesAirport,
  } = useGlobalState()
  const [isLoading, setIsLoading] = useState(false)
  const [openModalOtherTab, setOpenModalOtherTab] = useState(false)
  const [otherTab, setOtherTab] = useState<{
    label?: string
    value?: string
    typeTransfer?: TransferType
  }>()
  const [options, setOptions] = useState<AutoCompleteProps['options']>([])
  const [settings, setSettings] = useState<HookArgs | null>()
  const reactHookForm = useFormContext()
  const { t } = useTranslation()

  function getAddressField() {
    if (formKey === 'address' || formKey === 'arrivalAddress') {
      return 'arrivalLocation'
    }
    return 'departureLocation'
  }

  function generateErrorAddress() {
    reactHookForm.setError(
      formKey,
      {
        message: t('quotation.inputInvalidAddress') as string,
      },
      {
        shouldFocus: true,
      },
    )
    reactHookForm.setValue(formKey, undefined)
    openModalInvalidAddress()
  }

  async function validateAddress({ addressParts, geo_code }: PlaceDetails) {
    setIsLoading(true)
    const origin = reactHookForm.getValues('origin')
    const address = {
      cidade: '',
      bairro: '',
      endereco: '',
    }
    for (const addressPart of addressParts) {
      const addressType = addressPart.types[0] as AddressType
      handleSetAddress[addressType]?.({
        address,
        value: addressPart.long_name,
      })
    }
    try {
      const formData = new FormData()
      formData.append('bairro', address.bairro)
      formData.append('cidade', address.cidade)
      formData.append('endereco', address.endereco)
      formData.append('origem', transferType === 'puntaCana' ? origin : '1')
      const responseValidateAddress = await axios.post(
        'https://reservas.transferbrasil.cl/Services/consulta_bairro',
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
          },
        },
      )
      reactHookForm.clearErrors(formKey)
      if (!responseValidateAddress.data) {
        setIsLoading(false)
        generateErrorAddress()
        return
      }
      if (!address.endereco || address.endereco === '') {
        address.endereco = reactHookForm.getValues('address') || ''
      }
      reactHookForm.setValue(`${getAddressField()}.district`, address.bairro)
      reactHookForm.setValue(`${getAddressField()}.city`, address.cidade)
      reactHookForm.setValue(`${getAddressField()}.street`, address.endereco)
      reactHookForm.setValue(`${getAddressField()}.location`, address.endereco)
      reactHookForm.setValue(`${getAddressField()}.geo_code`, geo_code)
    } catch (error) {
      generateErrorAddress()
    }
    setIsLoading(false)
  }
  const { suggestions, setValue } = usePlacesAutocomplete({
    ...settings,
  })

  useEffect(() => {
    setSettings({
      requestOptions: {
        componentRestrictions: {
          country: transferType === 'puntaCana' ? ['do'] : ['cl'],
        },
      },
      callbackName: 'initMap',
      debounce: 500,
    })
  }, [suggestions, transferType])
  useEffect(() => {
    setOptions(
      suggestions.data.map((result) => ({
        label: result.description,
        value: `${result.place_id}|${result.description}`,
      })),
    )
  }, [suggestions])

  const handleVerifyAddressIsSameSkiStationOrCoast = useCallback(
    (value: string) => {
      const firstValue = value.split(' ')[0]
      let sameAddress = {
        ...ADDRESS_SKI_STATION_OPTIONS.find((address) => {
          return address.value.includes(firstValue)
        }),
        typeTransfer: TRANSFER_TYPES[1],
      }
      if (!sameAddress.value) {
        sameAddress = {
          ...ADDRESS_COAST_OPTIONS.find((address) => {
            return address.value.includes(firstValue)
          }),
          typeTransfer: TRANSFER_TYPES[2],
        }
      }
      if (sameAddress.value) {
        setOtherTab(sameAddress)
        setOpenModalOtherTab(true)
        return true
      }
      return false
    },
    [],
  )

  const verifyIfAddressIncludesAirport = useCallback(
    (value: string) => {
      if (!value) return false
      if (
        !BLACK_LIST_WORD_ADDRESS.some((word) =>
          value.toLocaleLowerCase().includes(word),
        )
      )
        return false
      reactHookForm.setError(
        formKey,
        {
          message: t('quotation.inputInvalidAddress') as string,
        },
        {
          shouldFocus: true,
        },
      )
      reactHookForm.setValue(formKey, '')
      openModalAddressIncludesAirport()
      return true
    },
    [formKey, openModalAddressIncludesAirport, reactHookForm, t],
  )

  return (
    <>
      <AutoComplete
        options={options}
        onSearch={setValue}
        value={reactHookForm.watch(formKey)}
        disabled={disabled}
        onSelect={async (value) => {
          const [placeId, description] = value.split('|')

          const includesAirport = verifyIfAddressIncludesAirport(description)
          if (includesAirport) {
            return
          }
          const isOtherTab =
            handleVerifyAddressIsSameSkiStationOrCoast(description)
          if (isOtherTab) return
          reactHookForm.setValue(formKey, description)
          reactHookForm.setValue(`${getAddressField()}.address`, description)
          reactHookForm.setError(formKey, {})

          const placeDetails = (await getDetails({
            placeId,
          })) as google.maps.places.PlaceResult
          await validateAddress({
            addressParts: placeDetails.address_components || [],
            geo_code: `${placeDetails.geometry?.location?.lat?.()},${placeDetails.geometry?.location?.lng?.()}`,
          })
        }}
      >
        <Input.Search
          placeholder={t(translationKey) as string}
          enterButton
          status={
            reactHookForm.formState.errors?.[formKey]?.message ? 'error' : ''
          }
          disabled={disabled}
          onChange={(event) => {
            const value = event.target.value
            const includesAirport = verifyIfAddressIncludesAirport(value)
            if (includesAirport) return
            reactHookForm.clearErrors(formKey)
            reactHookForm.setValue(formKey, value)
            reactHookForm.setValue(`${getAddressField()}.address`, value)
          }}
          loading={isLoading}
        />
      </AutoComplete>

      <Modal
        visible={openModalOtherTab}
        onClose={() => {
          setOpenModalOtherTab(false)
        }}
      >
        <S.Column style={{ maxWidth: '670px' }}>
          <S.Title>{t('quotation.foundAddressSame')}</S.Title>

          <S.Row>
            <S.SubTitle>
              {t('quotation.addressSameSkiStationOrCoast')}
              <Spacing size={18} />
            </S.SubTitle>
          </S.Row>
          <S.Row style={{ justifyContent: 'end' }}>
            <Button
              type="primary"
              onClick={() => {
                if (!otherTab?.typeTransfer) return
                reactHookForm.setValue('transferType', otherTab.typeTransfer)
                reactHookForm.setValue(otherTab.typeTransfer, otherTab.label, {
                  shouldValidate: true,
                })
                reactHookForm.setValue('address', otherTab.value)
                reactHookForm.setError('transferType', {})
                setCurrentTransferType(otherTab.typeTransfer)
              }}
            >
              Sim
            </Button>
            <Button>Não</Button>
          </S.Row>
        </S.Column>
      </Modal>
    </>
  )
}
