interface WhatsappLinkParams {
  number: string
  message: string
}

export function generateLinkWhatsapp({
  number,
  message,
}: WhatsappLinkParams): string {
  const baseUrl = 'https://api.whatsapp.com/send'
  const encodedMessage = encodeURIComponent(message)
  const link = `${baseUrl}?phone=${number}&text=${encodedMessage}`
  return link
}
