import Modal, { ModalProps } from 'components/Modal'
import Spacing from 'components/Spacing'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function WelcomeKit(
  props: Pick<ModalProps, 'visible' | 'onClose'> & { currentTab: string },
) {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('welcomeKit.title')}</S.Title>

        <Spacing size={12} />

        <S.Text as="span">{t('welcomeKit.text1')}</S.Text>
        {props.currentTab === 'puntaCana' ? (
          <>
            <S.Text>{t('welcomeKit.text6')}</S.Text>
            <S.Text>{t(`welcomeKit.${props.currentTab}text5`)}</S.Text>
          </>
        ) : (
          <>
            <S.Text>{t('welcomeKit.text2')}</S.Text>
            <S.Text>{t('welcomeKit.text3')}</S.Text>
            <S.Text as="span" style={{ fontSize: '10px' }}>
              {t(`welcomeKit.${props.currentTab}text5`)}
            </S.Text>
          </>
        )}
      </S.Container>
    </Modal>
  )
}
