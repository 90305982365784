import ptBR from './pt'

const en = {
  ...ptBR,
  menu: {
    ...ptBR.menu,
    language: 'Language',
  },
}

export default en
