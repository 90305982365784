import chat from 'assets/benefits/bubble-chat.png'
import phone from 'assets/benefits/phone-ringing.png'
import pix from 'assets/benefits/pix.png'
import planing from 'assets/benefits/planning.png'

import * as S from './styles'

type BenefitsProps = {
  topGallery?: boolean
}

export default function Benefits(props: BenefitsProps) {
  return (
    <S.Container>
      <S.Card>
        <img src={phone} alt="" />
        <div>
          <h5>Você conectado no Chile</h5>
          <p>
            chip de celular com internet ILIMITADA para Whatsapp e 2Gb navegação
            livre
          </p>
        </div>
      </S.Card>
      <S.Card>
        <img src={pix} alt="" />
        <div>
          <h5>Pagamento por PIX</h5>
          <p>Em reais no Brasil (antes da sua viagem)</p>
        </div>
      </S.Card>
      <S.Card>
        <img src={chat} alt="" />
        <div>
          <h5>Atendimento em Português</h5>
          <p>
            Especialistas em viagens para atender você em nosso Whatsapp em seu
            idioma
          </p>
        </div>
      </S.Card>
      <S.Card>
        <img src={planing} alt="" />
        <div>
          <h5>Grátis E-BOOK</h5>
          <p>Planeja sua viagem com nosso e-book e tenha tudo organizado</p>
        </div>
      </S.Card>
    </S.Container>
  )
}
