import styled, { css } from 'styled-components'

export const Container = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-flow: column;

  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : undefined}
`

export const InputLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
`
