import * as S from './styles'

export default function Slogan() {
  return (
    <S.Container>
      <div>
        <p>
          Transfer Brasil é para você que busca segurança e conforto ao chegar
          em outro país, com um suporte especializado em Turismo para seu
          Transfer Aeroporto e Passeio
        </p>
      </div>
    </S.Container>
  )
}
