import { useCallback, useState } from 'react'

import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg'
import team1 from 'assets/equipe1.png'
import team2 from 'assets/equipe2.png'
import team3 from 'assets/equipe3.png'
import team5 from 'assets/equipe5.png'
import team6 from 'assets/equipe6.png'
import team7 from 'assets/equipe7.png'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const pages = 2

export default function OurTeam() {
  const { t } = useTranslation()

  const [current, setCurrent] = useState<number>(0)

  const next = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === pages - 1) {
        return 0
      }

      return prevState + 1
    })
  }, [])

  const previous = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === 0) {
        return pages - 1
      }

      return prevState - 1
    })
  }, [])

  return (
    <S.Container>
      <S.Title>{t('ourTeam.title')}</S.Title>
      <S.TeamContainer>
        <S.RowCards current={current} index={0}>
          <S.Card>
            <img src={team1} alt="Alex Baez" />
            <S.Description>
              <S.Name>Alex Baez</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>

          <S.Card>
            <img src={team2} alt="Julio Velozo" />
            <S.Description>
              <S.Name>Julio Velozo</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>

          <S.Card>
            <img src={team3} alt="Eduardo Javier" />
            <S.Description>
              <S.Name>Eduardo Javier</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>
        </S.RowCards>
        <S.RowCards current={current} index={1}>
          <S.Card>
            <img src={team5} alt="Javier Muñoz" />
            <S.Description>
              <S.Name>Javier Muñoz</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>
          <S.Card>
            <img src={team6} alt="Pablo Cuevas" />
            <S.Description>
              <S.Name>Pablo Cuevas</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>
          <S.Card>
            <img src={team7} alt="Javier Diaz" />
            <S.Description>
              <S.Name>Javier Diaz</S.Name>
              <S.Function>{t('ourTeam.driver')}</S.Function>
            </S.Description>
          </S.Card>
        </S.RowCards>
        <S.NavigationContainer>
          <ArrowUp onClick={previous} />

          <ArrowDown onClick={next} />
        </S.NavigationContainer>
      </S.TeamContainer>
    </S.Container>
  )
}
