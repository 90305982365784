import apiInstance from './axiosInstance'
import * as Contact from './contact'

const persistToken = (token: string) => {
  apiInstance.defaults.headers['Authorization'] = `Bearer ${token}`
}
persistToken(process.env.REACT_APP_PUBLIC_TOKEN as string)

const ApiService = {
  Contact,
}

export default ApiService
