import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devicesMinWidth.laptop} {
    justify-content: space-between;
  }

  #menu-hamburger {
    justify-self: flex-start;

    @media ${devicesMinWidth.laptop} {
      display: none;
    }
  }

  #menu-container {
    display: none;

    @media ${devicesMinWidth.laptop} {
      display: flex;
    }
  }

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    #header-logo {
      margin-left: -24px;
      height: 52px;
      width: auto;
    }

    @media ${devicesMinWidth.laptop} {
      width: auto;

      #header-logo {
        margin-left: 0;
        height: 119px;
      }
    }
  }
`

export const MenuItem = styled.a`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.14em;
  display: flex;
  align-items: center;
  margin-left: 0;
  cursor: pointer;
  min-height: 24px;
  text-decoration: none;
  color: black;
  @media ${devicesMinWidth.laptop} {
    margin-left: 31px;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
  }
`

export const LanguageContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;

  span + span {
    margin-left: 12px;
  }

  span:nth-child(2) {
    margin-left: 0;
  }
`

export const LanguageLabel = styled.span`
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 2px;
`

export const FloatingMenuContainer = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;
  border-radius: 0px 20px 20px 20px;
  display: flex;
  flex-flow: column wrap;
  top: 65px;
  left: 18px;
  padding: 20px;
  cursor: default;
  z-index: 1;

  a {
    cursor: pointer;
  }
`

export const MobileMenuLogin = styled(MenuItem)`
  @media ${devicesMinWidth.laptop} {
    display: none;
  }
`
