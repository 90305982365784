export function unMaskMonetary(value: string | number) {
  return Number(value.toString().replaceAll('.', '').replaceAll(',', '.'))
}
export function monetaryMask(
  value?: number,
  locale?: string,
  settings?: Intl.NumberFormatOptions,
) {
  const options = { minimumFractionDigits: 2, ...settings }
  const result = new Intl.NumberFormat(locale || 'pt-BR', options).format(
    value || 0,
  )

  return result
}

export function roundUpperNumber(value?: number): number {
  return Number(Math.ceil(value || 0))
}
