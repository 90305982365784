import { useEffect, useState } from 'react'

import { DatePicker as DatePickerContainer } from 'antd'
import { ConfigProvider } from 'antd'
import { DatePickerProps as DatePickerContainerProps } from 'antd/lib/date-picker'
import { Locale } from 'antd/lib/locale'
import localeEN from 'antd/locale/en_US'
import localeES from 'antd/locale/es_ES'
import localePt from 'antd/locale/pt_BR'
import { MASK } from 'constants/mask'
import { useGlobalState } from 'contexts/global-state'
import dayjs from 'dayjs'

const LOCALE_DATE: Record<LanguagesTypes, Locale> = {
  pt: localePt,
  es: localeES,
  en: localeEN,
}

const LOCALE_DATE_MASK: Record<LanguagesTypes, string> = {
  pt: MASK.DATE.LOCALE,
  es: MASK.DATE.LOCALE,
  en: MASK.DATE.EUA,
}

export default function DatePicker({
  value,
  onChange,
  status,
  ...props
}: DatePickerContainerProps) {
  const { languageSelected } = useGlobalState()
  const [language, setLanguage] = useState(localePt)
  useEffect(() => {
    setLanguage(LOCALE_DATE[languageSelected])
  }, [languageSelected])
  return (
    <ConfigProvider locale={language}>
      <DatePickerContainer
        defaultValue={value ? dayjs(value, MASK.DATE.LOCALE) : undefined}
        format={LOCALE_DATE_MASK[languageSelected]}
        status={status}
        onChange={onChange}
        {...props}
      />
    </ConfigProvider>
  )
}
