import React from 'react'

import Copyrights from 'components/Copyrights'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { devicesMinWidth } from 'constants/media-queries'
import { GlobalStateProvider } from 'contexts/global-state'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import router from 'router'
import styled from 'styled-components'
import { WhatsAppChannel, WhatsAppColored } from 'styles'

import reportWebVitals from './reportWebVitals'

import 'react-toastify/dist/ReactToastify.css'
import './translation'
import './index.css'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding: 16px 19px 0;

  @media ${devicesMinWidth.laptop} {
    padding: 19px 122px 0;
  }

  @media ${devicesMinWidth.desktop} {
    padding: 19px 15% 0;
  }
`

function Providers({ children }: { children: React.ReactNode }) {
  return <GlobalStateProvider>{children}</GlobalStateProvider>
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Providers>
      <Container>
        <Header />
        <RouterProvider router={router} />
        <Footer />
        <WhatsAppChannel
          href="https://api.whatsapp.com/send?phone=56941277914"
          target="_blank"
        >
          <WhatsAppColored height={72} width={72} count="1" />
        </WhatsAppChannel>
        <div style={{ minHeight: '100px', width: '100%' }}></div>
        <Copyrights />

        <ToastContainer
          enableMultiContainer
          autoClose={5_000}
          theme="colored"
        />
      </Container>
    </Providers>
  </React.StrictMode>,
)

reportWebVitals()
