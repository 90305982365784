import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
`

export const Title = styled.h1`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  color: #000000;
  width: 100%;
  text-align: center;
`

export const TitleTopic = styled.h3`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  width: 100%;
`

export const Item = styled.p`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
  white-space: pre-line;
`

export const Table = styled.table`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
  width: 100%;
  border: 1px solid #f1f1f1;
  border-collapse: collapse;
`

export const TableHeader = styled.thead`
  th {
    text-align: left;
    padding: 1rem;
  }
`

export const TableBody = styled.tbody`
  td {
    text-align: left;
    padding: 1rem;
  }
  tr.gray {
    background-color: #f1f1f1;
  }
`
