import ENDPOINTS from 'constants/endpoints'

import oldApiAxiosInstance from './oldApiAxiosInstance'

export const get = async (data: FormData): Promise<QuoteApiResponse> => {
  try {
    const response = await oldApiAxiosInstance.post<QuoteApiResponse>(
      ENDPOINTS.OLD.QUOTE.CREATE,
      data,
    )

    return response.data
  } catch (sendError) {
    return Promise.reject()
  }
}
