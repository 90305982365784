import { useMemo } from 'react'

import { Form } from 'antd'
import Button from 'antd/lib/button'
import Radio from 'antd/lib/radio'
import Modal, { ModalProps } from 'components/Modal'
import TextWithLabel from 'components/TextWithLabel'
import { MASK } from 'constants/mask'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { monetaryMask, roundUpperNumber } from 'utils/maks'

import * as S from './styles'
import {
  getTransferLabel,
  getTransferTypeLabel,
  getTypeOfTransportLabel,
} from './utils'

type Step3Props = Pick<ModalProps, 'visible'> & {
  quoteData?: QuoteApiResponse
  sameAddress?: boolean
  onNextClick: () => void
  onPreviousClick: () => void
  onClose: () => void
}

enum PaymentForms {
  CREDIT_CARD = 'creditCard',
  PIX = 'pix',
  WISE = 'wise',
}

export default function Details({
  quoteData,
  visible,
  onNextClick,
  onClose,
  sameAddress,
}: Step3Props) {
  const { t } = useTranslation()

  const reactHookForm = useFormContext<QuotationApiRequest>()
  const values = reactHookForm.getValues()
  const paymentForm = reactHookForm.watch('paymentForm')

  const GET_FORMAT_MONETARY: Record<string, string> = useMemo(() => {
    return {
      puntaCana: monetaryMask(
        roundUpperNumber(quoteData?.valueCreditCard),
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol',
        },
      )
        ?.replaceAll(',', 's')
        ?.replaceAll('.', ',')
        ?.replaceAll('s', '.')
        .replaceAll('$', 'US$ '),
    }
  }, [quoteData?.valueCreditCard])

  return (
    <Modal visible={visible} onClose={onClose}>
      <S.Column>
        <S.Title>{t('quotation.quotationDetails')}</S.Title>
        <S.Row style={{ justifyContent: 'space-between', gap: 'none' }}>
          <TextWithLabel
            label={t('quotation.transfer')}
            value={getTransferLabel(t, values.transfer)}
          />

          <TextWithLabel
            label={t('quotation.transferType')}
            value={getTransferTypeLabel(t, values.transferType)}
          />

          <TextWithLabel
            label={t('quotation.transport')}
            value={getTypeOfTransportLabel(
              t,
              values.typeOfTransport as TypeOfTransport,
            )}
          />
        </S.Row>
        <S.Row>
          <TextWithLabel
            label={t('quotation.address')}
            value={
              (values.arrivalLocation?.district +
                ' - ' +
                `${
                  values?.arrivalLocation?.location?.length
                    ? values?.arrivalLocation?.location
                    : ''
                }`) as string
            }
            noWrap={false}
          />
        </S.Row>
        {sameAddress === false && values.departureLocation && (
          <S.Row>
            <TextWithLabel
              label={t('quotation.departureAddress')}
              value={
                (values.departureLocation?.district +
                  ' - ' +
                  `${
                    values?.departureLocation?.location?.length
                      ? values?.departureLocation?.location
                      : ''
                  }`) as string
              }
              noWrap={false}
            />
          </S.Row>
        )}
        <S.Title>{t('quotation.passengers')}</S.Title>
        <S.Row style={{ justifyContent: 'space-between', gap: 'none' }}>
          <TextWithLabel
            label={t('quotation.adults')}
            value={values.adults as number}
          />

          <TextWithLabel
            label={t('quotation.children')}
            value={values.children as number}
          />

          <TextWithLabel
            label={t('quotation.babies')}
            value={values.babies as number}
          />
          <TextWithLabel
            label={t('quotation.totalPassengers')}
            value={
              (values.adults || 0) +
              (values.children || 0) +
              (values.babies || 0)
            }
          />
        </S.Row>
        {quoteData?.consultaItens?.['2'] && (
          <>
            <S.Title>{t('quotation.additionalItem')}</S.Title>
            <S.Row style={{ justifyContent: 'space-between' }}>
              <TextWithLabel
                label={t('quotation.name')}
                value={
                  quoteData?.consultaItens['2'].ItensAdicional.nome as string
                }
              />
              <TextWithLabel
                label={t('quotation.unitValue')}
                value={`${
                  values.transferType === 'puntaCana' ? 'USD' : 'CLP'
                } ${quoteData?.consultaItens['2'].ItensAdicional.valor}`}
              />
              <div
                style={{
                  minWidth: '120px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <TextWithLabel
                  label={t('quotation.quantity')}
                  value={
                    quoteData?.consultaItens[
                      '2'
                    ].ItensAdicional.quantidade.toString() as string
                  }
                />
              </div>
            </S.Row>
          </>
        )}
        <S.Title>{t('quotation.reservationValue')}</S.Title>
        <S.Row style={{ justifyContent: 'space-between' }}>
          {values.origin === 1 && (
            <div style={{ minWidth: '143px' }}>
              <TextWithLabel
                label={t('quotation.transfer')}
                value={monetaryMask(
                  roundUpperNumber(quoteData?.valueTrip),
                  'pt-BR',
                  {
                    style: 'currency',
                    currency:
                      values.transferType === 'puntaCana' ? 'USD' : 'CLP',
                    currencyDisplay: 'symbol',
                    maximumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                    minimumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                  },
                )}
              />
            </div>
          )}
          {quoteData?.consultaItens && (
            <>
              {values.origin === 1 && (
                <TextWithLabel
                  label={t('quotation.additional')}
                  value={monetaryMask(
                    roundUpperNumber(quoteData?.valueSpecialBag),
                    'pt-BR',
                    {
                      style: 'currency',
                      currency:
                        values.transferType === 'puntaCana' ? 'USD' : 'CLP',
                      currencyDisplay: 'symbol',
                      maximumFractionDigits:
                        values.transferType !== 'puntaCana' ? 0 : 2,
                      minimumFractionDigits:
                        values.transferType !== 'puntaCana' ? 0 : 2,
                    },
                  )}
                />
              )}
              <TextWithLabel
                label={t('quotation.total')}
                value={monetaryMask(
                  roundUpperNumber(
                    quoteData?.valueSpecialBag + quoteData?.valueTrip,
                  ),
                  'pt-BR',
                  {
                    style: 'currency',
                    currency:
                      values.transferType === 'puntaCana' ? 'USD' : 'CLP',
                    currencyDisplay: 'symbol',
                    maximumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                    minimumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                  },
                )}
              />
            </>
          )}
        </S.Row>
        <S.Title>{t('quotation.totalValueIn')}</S.Title>
        <Form.Item
          style={{ marginBottom: 0 }}
          help={reactHookForm.formState.errors.paymentForm?.message}
          validateStatus={
            reactHookForm.formState.errors.paymentForm?.message ? 'error' : ''
          }
        >
          <S.Row style={{ gap: '6rem' }}>
            <S.Row
              style={{
                // gap: '0.5rem',
                // maxHeight: '50px',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Radio
                  checked={paymentForm === PaymentForms.CREDIT_CARD}
                  className="custom-radio"
                  onClick={() => {
                    reactHookForm.setValue(
                      'paymentForm',
                      PaymentForms.CREDIT_CARD,
                    )
                    reactHookForm.setError('paymentForm', {})
                  }}
                />
                <TextWithLabel
                  label={t('quotation.reserveInCardPayment')}
                  value={
                    GET_FORMAT_MONETARY[values.transferType] ||
                    monetaryMask(
                      roundUpperNumber(quoteData?.valueCreditCard),
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                        currencyDisplay: 'symbol',
                      },
                    )
                  }
                />
              </div>

              <strong style={{ color: 'red', width: 300 }}>
                {t('quotation.valuePaymentPaidLess15Days')}
              </strong>
            </S.Row>
            {values.transferType !== 'puntaCana' && (
              <S.Row style={{ flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Radio
                    className="custom-radio"
                    checked={paymentForm === PaymentForms.PIX}
                    onClick={() => {
                      reactHookForm.setValue('paymentForm', PaymentForms.PIX)
                      reactHookForm.setError('paymentForm', {})
                    }}
                  />
                  <TextWithLabel
                    label={t('quotation.reserveInPixPayment')}
                    value={monetaryMask(
                      roundUpperNumber(quoteData?.valuePix),
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                        currencyDisplay: 'symbol',
                      },
                    )}
                  />
                </div>
                <strong style={{ color: 'red', width: 300 }}>
                  {t('quotation.valuePixOnlyTodayQuote')} (
                  {dayjs().format(MASK.DATE.LOCALE)})
                </strong>
              </S.Row>
            )}
            <S.Row
              style={{
                gap: '0.5rem',
                maxHeight: '50px',
              }}
            >
              <Radio
                className="custom-radio"
                checked={paymentForm === PaymentForms.WISE}
                onClick={() => {
                  reactHookForm.setValue('paymentForm', PaymentForms.WISE)
                  reactHookForm.setError('paymentForm', {})
                }}
              />
              <TextWithLabel
                style={{
                  flexFlow: 'column',
                }}
                label={t('quotation.reserveInWisePayment')}
                value={monetaryMask(
                  roundUpperNumber(
                    (quoteData?.valueSpecialBag || 0) +
                      (quoteData?.valueTrip || 0),
                  ),
                  'pt-BR',
                  {
                    style: 'currency',
                    currency:
                      values.transferType === 'puntaCana' ? 'USD' : 'CLP',
                    currencyDisplay: 'symbol',
                    maximumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                    minimumFractionDigits:
                      values.transferType !== 'puntaCana' ? 0 : 2,
                  },
                )}
              />
            </S.Row>
          </S.Row>
        </Form.Item>
        <S.Row style={{ alignSelf: 'flex-end' }}>
          <Button type="primary" onClick={onNextClick}>
            {t('quotation.next')}
          </Button>
        </S.Row>
      </S.Column>
    </Modal>
  )
}
