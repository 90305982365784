import { ReactComponent as X } from 'assets/X.svg'
import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const Container = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 4px 7px 16px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  max-width: 95vw;
  max-height: 90vh;
  overflow: auto;
  padding: 30px 25px;

  @media ${devicesMinWidth.laptop} {
    padding: 45px;
  }
`

export const CloseIcon = styled(X)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 25px;

  @media ${devicesMinWidth.laptop} {
    top: 25px;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  min-width: 300px;
  margin-top: 20px;

  button {
    min-width: 100px;
  }
`
