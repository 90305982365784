import effect from 'assets/effect.svg'
import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 12px;

  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    right: 23px;
    width: 150px;
    height: 138px;
    background-image: url(${effect});
    z-index: -1;
  }

  @media ${devicesMinWidth.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devicesMinWidth.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const Title = styled.h2`
  width: 100%;
  margin-top: 90px;
  text-align: center;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 28px;
  color: #14183e;
`

export const Item = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 74px;
  cursor: pointer;
  width: 222px;
  height: 261px;
  margin: auto;

  #payments {
    margin-bottom: 29px;
  }

  #semi-shared {
    margin-bottom: -21px;
  }

  #welcome-kit {
    margin-bottom: 37px;
  }

  #protocol-covid {
    margin-bottom: 13px;
  }

  #reserve {
    margin-bottom: 37px;
  }

  #requirements {
    margin-bottom: 7px;
  }

  #questions {
    margin-bottom: 37px;
  }

  #links {
    margin-bottom: 37px;
  }
`

export const ItemWithBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 222px;
  height: 261px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10%;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);

  &:after {
    position: absolute;
    content: ' ';
    display: block;
    width: 83px;
    height: 83px;
    bottom: -25px;
    left: -25px;
    z-index: -1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 25px;
    background: #df6951;
  }

  #semi-shared {
    height: 131px;
    width: 131px;
  }
`

export const ItemLabel = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1e1d4c;
  text-align: center;
`

export const TabItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 13px;
  }

  img {
    width: auto;
    height: 30px;
    margin-right: 12px;
  }

  @media ${devicesMinWidth.tablet} {
    gap: 10px;
    padding: 0px 30px;
    span {
      font-size: 16px;
    }
    span + span {
      margin-left: 5px;
    }
    img {
      height: 50px;
    }
  }
`

export const TabItemText = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devicesMinWidth.tablet} {
    flex-direction: row;
    span + span {
      margin-left: 5px;
    }
  }
`
