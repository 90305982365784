import Modal, { ModalProps } from 'components/Modal'
import Spacing from 'components/Spacing'
import ROUTES from 'constants/router'
import { useTranslation } from 'react-i18next'

import HowToFillFormAduana from '../../../../assets/documents/como-preencher-formulario-aduana.jpeg'
import Declaration from '../../../../assets/documents/joint-customs-declaration.pdf'
import Procedures from '../../../../assets/documents/procedimentos.jpeg'
import * as S from './styles'

export default function ImportantLinks(
  props: Pick<ModalProps, 'visible' | 'onClose'> & { currentTab: string },
) {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('usefulLinks.title')}</S.Title>

        <Spacing size={12} />

        <S.Text>
          <a href={ROUTES.TERMS_CONDITIONS}>{t('usefulLinks.link1')}</a>
        </S.Text>

        {props.currentTab !== 'puntaCana' && (
          <>
            <S.Text>
              <a
                href={HowToFillFormAduana}
                download="Como preencher o formulário de aduana.jpeg"
              >
                {t('usefulLinks.link4')}
              </a>
            </S.Text>
            <S.Text>
              <a href={Procedures} download="Procedimentos ao desembarcar.jpeg">
                {t('usefulLinks.link5')}
              </a>
            </S.Text>
            <S.Text>
              <a
                href={Declaration}
                download="declaracion_conjunta_sag_aduanas.pdf"
              >
                {t('usefulLinks.link3')}
              </a>
            </S.Text>
          </>
        )}
      </S.Container>
    </Modal>
  )
}
