import { ReactComponent as DecoreIcon } from 'assets/decore.svg'
import VanImage from 'assets/van.png'
import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Decore = styled(DecoreIcon)`
  display: none;

  @media ${devicesMinWidth.laptop} {
    display: block;
    position: absolute;
    right: 0;
    z-index: -1;
    height: 65vh;
    width: auto;
    top: 0;
  }
`

export const Van = styled.img.attrs({ src: VanImage })`
  display: none;
  height: auto;
  width: 90vw;
  margin-left: 5vw;
  z-index: -1;

  @media ${devicesMinWidth.laptop} {
    width: 45vw;
    position: absolute;
    right: 3%;
    margin-left: 0;
  }

  @media ${devicesMinWidth.laptopL} {
    display: flex;
    width: 45vw;
  }

  @media ${devicesMinWidth.desktop} {
    width: 40vw;
    right: 14%;
  }
`

export const ContainerVideos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
  @media ${devicesMinWidth.laptopL} {
    flex-direction: row;
  }
`
