const ENDPOINTS = {
  CONTACT: '/contact',
  TOUR_LINK: {
    ROOT: 'https://loja-transferbrasilspa.paytour.com.br/',
  },
  WHATSAPP: {
    ROOT: 'https://api.whatsapp.com/send?phone=56941277914',
  },
  OLD: {
    QUOTE: {
      CREATE: '/cotacao',
    },
    PARTNER: {
      GET: '/lista_parceiros',
    },
    RESERVATION: {
      CREATE: '/finaliza',
      GET_LINK_PAYMENT: (id: string) => `/paypal/${id}`,
      CHECK_AVAILABLE: '/consulta_disponibilidade',
    },
  },
}

export default ENDPOINTS
