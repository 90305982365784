import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ${devicesMinWidth.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devicesMinWidth.laptopL} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const TeamContainer = styled.section`
  display: block;
  justify-content: center;

  @media ${devicesMinWidth.tablet} {
    display: flex;
  }
`

export const Title = styled.span`
  width: fit-content;
  padding: 5px;
  margin-top: 110px;
  margin-bottom: 25px;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 28px;
  color: #004a7e;
  line-height: 50px;

  border-bottom: 5px solid #fefe01ff;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;

  img {
    width: auto;
    height: 200px;
  }
`

export const Description = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  padding: 5px;
  flex-direction: column;
`

export const Name = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #004a7e;
`

export const Function = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #004a7e;
`

export const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const RowCards = styled.div<{ current: number; index: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;

  @media ${devicesMinWidth.tablet} {
    flex-direction: row;
    display: ${(props) => (props.current !== props.index ? 'none' : 'flex')};
  }
`

export const CurrentTeam = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
`

export const NextTeam = styled(CurrentTeam)`
  position: absolute;
  z-index: -1;
  background-color: white;
  left: 15px;
  box-shadow: none;
  border: 2px solid #f7f7f7;
  width: 80%;
  height: 100%;
  top: unset;
  bottom: -72px;
  justify-content: flex-end;

  @media ${devicesMinWidth.laptop} {
    top: 80px;
    left: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: column;
  }
`

export const NavigationContainer = styled.div`
  display: none;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 80px;
  margin-left: 40px;
  margin-top: 100px;

  svg {
    cursor: pointer;
  }

  @media ${devicesMinWidth.tablet} {
    display: flex;
  }
`
