import { useMemo, useState } from 'react'

import { Checkbox } from 'antd'
import Button from 'antd/lib/button'
import Modal, { ModalProps } from 'components/Modal'
import TextWithLabel from 'components/TextWithLabel'
import {
  HANDLE_SET_ADDRESS_COAST,
  HANDLE_SET_ADDRESS_SKI_STATION,
} from 'constants/address'
import { MASK } from 'constants/mask'
import { PAYMENT_SYMBOLS } from 'constants/payment-symbol'
import dayjs from 'dayjs'
import TermsConditions from 'pages/useful-links/terms-conditions'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { monetaryMask, roundUpperNumber } from 'utils/maks'

import * as S from './styles'
import {
  getTransferLabel,
  getFlightTypeLabel,
  getTransferTypeLabel,
  getTypeOfTransportLabel,
} from './utils'

type ConfirmationProps = Pick<ModalProps, 'visible'> & {
  onClose: () => void
  onPreviousClick?: () => void
  onNextClick: () => void
  quoteData?: QuoteApiResponse
}

export default function Resume({
  quoteData,
  visible,
  onClose,
  onNextClick,
  onPreviousClick,
}: ConfirmationProps) {
  const { t } = useTranslation()
  const reactHookForm = useFormContext<QuotationApiRequest>()
  const values = reactHookForm.getValues()
  const [showTermsConditions, setShowTermsConditions] = useState<boolean>(false)
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(false)
  const handleGetLabelPaymentForm = useMemo(
    () => ({
      creditCard: t('quotation.reserveInCardPayment'),
      pix: t('quotation.reserveInPixPayment'),
      wise: t('quotation.reserveInWisePayment'),
    }),
    [t],
  )

  const handleGetValueReservation: Record<PaymentForms, number | undefined> =
    useMemo(
      () => ({
        creditCard: quoteData?.valueCreditCard,
        pix: quoteData?.valuePix,
        wise: (quoteData?.valueTrip || 0) + (quoteData?.valueSpecialBag || 0),
      }),
      [
        quoteData?.valueCreditCard,
        quoteData?.valuePix,
        quoteData?.valueSpecialBag,
        quoteData?.valueTrip,
      ],
    )
  return (
    <>
      <Modal visible={visible} onClose={onClose}>
        <S.Column style={{ width: '760px', gap: '12px' }}>
          <S.Title>{t('quotation.resume')}</S.Title>

          <S.Row>
            <S.Title style={{ fontSize: '16px' }}>
              {t('quotation.reservationDetails')}
            </S.Title>
          </S.Row>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel
              label={t('quotation.transfer')}
              value={getTransferLabel(t, values.transfer)}
            />

            <TextWithLabel
              label={t('quotation.transferType')}
              value={getTransferTypeLabel(t, values.transferType)}
            />

            <TextWithLabel
              label={t('quotation.transport')}
              value={getTypeOfTransportLabel(
                t,
                values.typeOfTransport as TypeOfTransport,
              )}
            />
          </S.Row>
          <S.Row>
            {values.transferType === 'airport' && (
              <TextWithLabel
                label={t('quotation.address')}
                value={values.address as string}
              />
            )}

            {values.transferType === 'skiStation' && (
              <TextWithLabel
                label={t('quotation.skiStationAddress')}
                value={
                  `${values.location?.length ? values.location + ',' : ''} ${
                    HANDLE_SET_ADDRESS_SKI_STATION[values?.skiStation as string]
                      ?.address
                  }` as string
                }
              />
            )}

            {values.transferType === 'coast' && (
              <TextWithLabel
                label={t('quotation.coastAddress')}
                value={
                  `${values.location?.length ? values.location + ',' : ''} ${
                    HANDLE_SET_ADDRESS_COAST[values?.coast as string]?.address
                  }` as string
                }
              />
            )}

            {values.transferType === 'puntaCana' && (
              <TextWithLabel
                label={t('quotation.address')}
                value={
                  (values.arrivalLocation?.district +
                    ' - ' +
                    `${
                      values?.arrivalLocation?.location?.length
                        ? values?.arrivalLocation?.location
                        : ''
                    }`) as string
                }
              />
            )}
          </S.Row>

          <S.Title style={{ fontSize: '16px' }}>
            {t('quotation.passengers')}
          </S.Title>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel
              label={t('quotation.adults')}
              value={values.adults || 0}
            />

            <TextWithLabel
              label={t('quotation.babies')}
              value={values.babies || 0}
            />

            <TextWithLabel
              label={t('quotation.children')}
              value={values.children || 0}
            />

            <TextWithLabel
              label={t('quotation.totalPassengers')}
              value={
                (values.adults || 0) +
                (values.children || 0) +
                (values.babies || 0)
              }
            />
          </S.Row>

          <S.Title style={{ fontSize: '16px' }}>
            {t('quotation.flightData')}
          </S.Title>

          {(values.transfer === 'roundtrip' ||
            values.transfer === 'oneWayTrip') && (
            <>
              <S.Row style={{ justifyContent: 'space-between' }}>
                <TextWithLabel label={t('quotation.type')} value="Chegada" />
                <TextWithLabel
                  label={t('quotation.scheduled')}
                  value={
                    dayjs(values.arrivalDate).format(MASK.DATE.LOCALE) +
                    ` ${values.arrivalFlightTime}`
                  }
                />
                <TextWithLabel
                  label={t('quotation.flightNumber')}
                  value={values.arrivalFlightNumber}
                />

                <div style={{ width: '103px' }}>
                  <TextWithLabel
                    label={t('quotation.flight')}
                    value={getFlightTypeLabel(t, values.arrivalFlightType)}
                  />
                </div>
              </S.Row>
            </>
          )}

          {(values.transfer === 'roundtrip' ||
            values.transfer === 'returnTrip') && (
            <>
              <S.Row style={{ justifyContent: 'space-between' }}>
                <div style={{ minWidth: '70px' }}>
                  <TextWithLabel label={t('quotation.type')} value="Saída" />
                </div>
                <TextWithLabel
                  label={t('quotation.scheduled')}
                  value={
                    dayjs(values.departureDate).format(MASK.DATE.LOCALE) +
                    ` ${values.departureFlightTime}`
                  }
                />
                <TextWithLabel
                  label={t('quotation.flightNumber')}
                  value={values.departureFlightNumber}
                />
                <div style={{ width: '103px' }}>
                  <TextWithLabel
                    label={t('quotation.flight')}
                    value={getFlightTypeLabel(t, values.departureFlightType)}
                  />
                </div>
              </S.Row>
            </>
          )}
          <S.Title style={{ fontSize: '16px' }}>
            {t('quotation.clientDetails')}
          </S.Title>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel label={t('quotation.name')} value={values.name} />
            <TextWithLabel label={t('quotation.email')} value={values.email} />
          </S.Row>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel label={t('quotation.cpf')} value={values.document} />
            <TextWithLabel
              label={t('quotation.cellPhone')}
              value={values.cellPhone}
            />
            <TextWithLabel
              label={t('quotation.whereYouMetUs')}
              value={quoteData?.parceiros?.[values.whereYouMetUs] || ''}
            />
          </S.Row>
          <S.Title style={{ fontSize: '16px' }}>
            {t('quotation.paymentDetails')}
            {!!(values.paymentForm === 'pix') && (
              <strong>
                {' '}
                -
                <strong style={{ color: 'red' }}>
                  {' '}
                  {t('quotation.valuePixOnlyTodayQuote')} (
                  {dayjs().format(MASK.DATE.LOCALE)})
                </strong>
              </strong>
            )}
          </S.Title>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel
              label={t('quotation.paymentForm')}
              value={
                handleGetLabelPaymentForm[values.paymentForm as PaymentForms]
              }
            />
            <TextWithLabel
              label={t('quotation.reservationValue')}
              value={monetaryMask(
                roundUpperNumber(
                  (quoteData?.valueSpecialBag || 0) +
                    (quoteData?.valueTrip || 0),
                ),
                'pt-BR',
                {
                  style: 'currency',
                  currency: values.transferType !== 'puntaCana' ? 'CLP' : 'USD',
                  currencyDisplay: 'symbol',
                  maximumFractionDigits:
                    values.transferType !== 'puntaCana' ? 0 : 2,
                  minimumFractionDigits:
                    values.transferType !== 'puntaCana' ? 0 : 2,
                },
              )}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextWithLabel
                label={t('quotation.paymentValue')}
                value={monetaryMask(
                  roundUpperNumber(
                    handleGetValueReservation[
                      values.paymentForm as PaymentForms
                    ] || 0,
                  ),
                  'pt-BR',
                  {
                    style: 'currency',
                    currency:
                      values.transferType === 'puntaCana' &&
                      (values.paymentForm === 'creditCard' ||
                        values.paymentForm === 'wise')
                        ? 'USD'
                        : PAYMENT_SYMBOLS[values.paymentForm as PaymentForms] ||
                          'CLP',
                    currencyDisplay: 'symbol',
                    maximumFractionDigits:
                      values.paymentForm === 'wise' &&
                      values.transferType !== 'puntaCana'
                        ? 0
                        : 2,
                    minimumFractionDigits:
                      values.paymentForm === 'wise' &&
                      values.transferType !== 'puntaCana'
                        ? 0
                        : 2,
                  },
                )}
              />
            </div>
          </S.Row>
          <S.Row style={{ justifyContent: 'space-between' }}>
            <TextWithLabel
              label={t('quotation.observations')}
              value={values.observations || ''}
            />
          </S.Row>
          <S.Row style={{ alignItems: 'center', gap: '12px' }}>
            <Checkbox
              onChange={() => {
                setAcceptedTermsConditions(!acceptedTermsConditions)
              }}
            />
            <div
              onClick={() => {
                setShowTermsConditions(true)
              }}
              dangerouslySetInnerHTML={{
                __html: t('quotation.readTermsConditions', {
                  onClick: () => setShowTermsConditions(true),
                }),
              }}
            />
          </S.Row>

          <S.Row style={{ justifyContent: 'space-between' }}>
            <Button type="primary" ghost onClick={onPreviousClick}>
              {t('quotation.previous')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setAcceptedTermsConditions(false)
                onNextClick()
              }}
              disabled={!acceptedTermsConditions}
            >
              {t('quotation.confirm')}
            </Button>
          </S.Row>
        </S.Column>
      </Modal>
      <Modal
        visible={showTermsConditions}
        onClose={() => setShowTermsConditions(false)}
      >
        <TermsConditions />
      </Modal>
    </>
  )
}
