import { useCallback } from 'react'

import * as S from './styles'

export default function Video({ link }: { link: string }) {
  const getVideoId = useCallback((link: string) => {
    if (!link) return ''

    const currentUrl = new URL(link)
    const searchParams = currentUrl.searchParams
    const videoId = searchParams.get('v') || ''

    return videoId
  }, [])

  return (
    <S.Container>
      <iframe
        src={`https://www.youtube.com/embed/${getVideoId(link)}`}
        title="YouTube video player"
        style={{ borderWidth: 0, width: '100%', height: '300px' }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </S.Container>
  )
}
