export const MASK = {
  CPF: '999.999.999-99',
  CELL_PHONE: '(99) 9 9999-9999',
  DATE: {
    LOCALE: 'DD/MM/YYYY',
    EUA: 'MM/DD/YYYY',
    ISO: 'YYYY-MM-DD',
    TIME: 'HH:mm',
  },
}
