import { useCallback } from 'react'

import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import InputField from 'components/InputField'
import Modal, { ModalProps } from 'components/Modal'
import Spacing from 'components/Spacing'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ApiService from 'services/api'

import * as S from './styles'

type ContactProps = Pick<ModalProps, 'visible' | 'onClose'>

export default function Contact({ visible, onClose }: ContactProps) {
  const { t } = useTranslation()

  const { handleSubmit, control, reset } = useForm<ContactApiRequest>()

  const onSubmit = useCallback(
    async (data: ContactApiRequest) => {
      ApiService.Contact.send(data)
        .then(() => {
          reset()
          onClose?.()
          toast.success(t('contact.success'))
        })
        .catch(() => {
          toast.error(t('contact.error'))
        })
    },
    [t, onClose, reset],
  )

  return (
    <Modal visible={visible} onClose={onClose}>
      <S.Container onSubmit={handleSubmit(onSubmit)}>
        <S.Title>{t('contact.title')}</S.Title>
        <S.Description>{t('contact.description')}</S.Description>

        <Spacing size={38} />

        <InputField label={t('contact.name')} fullWidth>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input onChange={onChange} value={value} />
            )}
          />
        </InputField>

        <Spacing size={15} />

        <InputField label={t('contact.phone')} fullWidth>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input onChange={onChange} value={value} />
            )}
          />
        </InputField>

        <Spacing size={15} />

        <InputField label={t('contact.message')} fullWidth>
          <Controller
            name="message"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input.TextArea rows={4} onChange={onChange} value={value} />
            )}
          />
        </InputField>

        <Spacing size={26} />

        <Button
          type="primary"
          shape="round"
          style={{ width: '100%' }}
          htmlType="submit"
        >
          {t('contact.send')}
        </Button>
      </S.Container>
    </Modal>
  )
}
