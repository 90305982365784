import { ReactComponent as DecoreIcon } from 'assets/decore.svg'
import VanImage from 'assets/van.png'
import { WhatsAppColored as WhatsAppColoredIcon } from 'assets/WhatsAppColored'
import { devicesMinWidth } from 'constants/media-queries'
import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  padding: 16px 19px 0;

  @media ${devicesMinWidth.laptop} {
    padding: 19px 122px 0;
  }

  @media ${devicesMinWidth.desktop} {
    padding: 19px 15% 0;
  }
`

export const Decore = styled(DecoreIcon)`
  position: absolute;
  right: 0;
  z-index: -1;
  height: 65vh;
  width: auto;
  top: -170px;

  @media ${devicesMinWidth.tablet} {
    top: 0;
  }
`

export const Van = styled.img.attrs({ src: VanImage })`
  height: auto;
  width: 90vw;
  margin-left: 5vw;
  z-index: -1;

  @media ${devicesMinWidth.laptop} {
    width: 45vw;
    position: absolute;
    right: 3%;
    margin-left: 0;
  }

  @media ${devicesMinWidth.laptopL} {
    width: 45vw;
  }

  @media ${devicesMinWidth.desktop} {
    width: 30vw;
    top: 0;
    right: 14%;
  }
`

export const WhatsAppChannel = styled.a`
  position: fixed;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 100000;
`

const shaking = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(5px); }
  50% { transform: translateY(-5px); }
  75% { transform: translateY(5px); }
  100% { transform: translateY(0); }
`

export const WhatsAppColored = styled(WhatsAppColoredIcon)<{ count: string }>`
  animation: ${shaking} 0.8s infinite;
`
