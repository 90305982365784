import styled from 'styled-components'

type SpacingProps = {
  size: number
}

const Spacing = styled.div<SpacingProps>`
  margin-top: ${(props) => props.size}px;
`

export default Spacing
