import styled from 'styled-components'

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #181e4b;
`

export const SubTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
`

export const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 12px;
`

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;

  .custom-radio {
    .ant-radio .ant-radio-inner {
      border-color: #1677ff !important ;
    }
  }
`
