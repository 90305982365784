import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function TermsConditions() {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>{t('termsAndConditions.title')}</S.Title>

      <S.TitleTopic>
        {t('termsAndConditions.generalTransportConditions')}
      </S.TitleTopic>
      <S.Item>{t('termsAndConditions.generalTransportConditionsText')}</S.Item>

      <S.TitleTopic>{t('termsAndConditions.baggage')}</S.TitleTopic>
      <S.Item>{t('termsAndConditions.baggageText')}</S.Item>
      <S.Table>
        <S.TableHeader>
          <tr>
            <th>{t('termsAndConditions.baggageTable.headers.service')}</th>
            <th>
              {t('termsAndConditions.baggageTable.headers.personalArticle')}
            </th>
            <th>{t('termsAndConditions.baggageTable.headers.handbag')}</th>
            <th>
              {t('termsAndConditions.baggageTable.headers.bodegaSuitcase')}
            </th>
          </tr>
        </S.TableHeader>
        <S.TableBody>
          <tr className="gray">
            <td>
              {t('termsAndConditions.baggageTable.serviceColumn.semiSharedVan')}
            </td>
            <td rowSpan={2}>
              {t(
                'termsAndConditions.baggageTable.personalArticleColumn.semiSharedVan',
              )}
            </td>
            <td rowSpan={2}>
              {t('termsAndConditions.baggageTable.handbagColumn.semiSharedVan')}
            </td>
            <td rowSpan={2}>
              {t(
                'termsAndConditions.baggageTable.bodegaSuitcase.semiSharedVan',
              )}
            </td>
          </tr>
          <tr className="gray">
            <td>
              {t(
                'termsAndConditions.baggageTable.serviceColumn.quantityPerPerson',
              )}
            </td>
          </tr>
          <tr>
            <td>
              {t('termsAndConditions.baggageTable.serviceColumn.private')}
            </td>
            <td rowSpan={2}>
              {t(
                'termsAndConditions.baggageTable.personalArticleColumn.private',
              )}
            </td>
            <td rowSpan={2}>
              {t('termsAndConditions.baggageTable.handbagColumn.private')}
            </td>
            <td rowSpan={2}>
              {t('termsAndConditions.baggageTable.bodegaSuitcase.private')}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'termsAndConditions.baggageTable.serviceColumn.quantityPerCar',
              )}
            </td>
          </tr>
        </S.TableBody>
      </S.Table>
      <S.Item>{t('termsAndConditions.baggageComplement')}</S.Item>

      <S.TitleTopic>{t('termsAndConditions.payment')}</S.TitleTopic>
      <S.Item>{t('termsAndConditions.paymentText')}</S.Item>

      <S.TitleTopic>
        {t('termsAndConditions.generalConditionsService')}
      </S.TitleTopic>
      <S.Item>{t('termsAndConditions.generalConditionsServiceText')}</S.Item>

      <S.TitleTopic>{t('termsAndConditions.others')}</S.TitleTopic>
      <S.Item>{t('termsAndConditions.othersText')}</S.Item>
    </S.Container>
  )
}
