import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  min-width: 80vw;
  max-width: 470px;

  button {
    width: 100%;
  }

  @media ${devicesMinWidth.laptop} {
    min-width: 470px;
  }
`

export const Title = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #181e4b;
`

export const Description = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
`
