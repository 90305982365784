import { useCallback, useEffect } from 'react'

import { Input } from 'antd'
import Select from 'antd/lib/select'
import axios from 'axios'
import AddressSelect from 'components/AddressSelect'
import Modal from 'components/Modal'
import Spacing from 'components/Spacing'
import {
  HANDLE_SET_ADDRESS_COAST,
  HANDLE_SET_ADDRESS_PUNTA_CANA,
  HANDLE_SET_ADDRESS_SKI_STATION,
} from 'constants/address'
import { useGlobalState } from 'contexts/global-state'
import { t } from 'i18next'
import { useFormContext, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import originsMap from '../../../assets/origins-map.png'
import { Whatsapp } from '../../../components/WhatsappIcon'
import * as S from './styles'
type AddressProps = {
  transferType: TransferType
  transfer?: Transfer
  sameAddress?: boolean
}

function generateErrorAddress({
  reactHookForm,
  openModalInvalidAddress,
}: {
  reactHookForm: UseFormReturn<QuotationApiRequest>
  openModalInvalidAddress: () => void
}) {
  reactHookForm.setError(
    'region',
    {
      message: t('quotation.inputInvalidAddress') as string,
    },
    {
      shouldFocus: true,
    },
  )
  reactHookForm.setValue('region', undefined)
  openModalInvalidAddress()
}

function PuntaCanaForm({
  formKey,
  hasOrigin,
  hasMap,
}: {
  formKey: 'arrival' | 'departure'
  hasOrigin?: boolean
  hasMap?: boolean
}) {
  const { openModalInvalidAddress, setLoadingValidateAddress } =
    useGlobalState()
  const reactHookForm = useFormContext<QuotationApiRequest>()

  const validateAddress = useCallback(async () => {
    setLoadingValidateAddress(true)
    const origin = reactHookForm.getValues('origin')
    const address = reactHookForm.getValues('arrivalLocation')
    if (!address || !origin) return
    try {
      const formData = new FormData()
      formData.append('bairro', address.district)
      formData.append('cidade', address.city)
      formData.append('endereco', '')
      formData.append('origem', origin.toString())
      const responseValidateAddress = await axios.post(
        'https://reservas.transferbrasil.cl/Services/consulta_bairro',
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
          },
        },
      )
      reactHookForm.clearErrors('location')
      if (!responseValidateAddress.data) {
        setLoadingValidateAddress(false)
        generateErrorAddress({
          openModalInvalidAddress,
          reactHookForm,
        })
        return
      }
    } catch (error) {
      generateErrorAddress({
        openModalInvalidAddress,
        reactHookForm,
      })
    }
    setLoadingValidateAddress(false)
  }, [openModalInvalidAddress, reactHookForm, setLoadingValidateAddress])

  const handleSetAddress = useCallback(
    (value: string) => {
      const puntaCanaAddress = HANDLE_SET_ADDRESS_PUNTA_CANA[value]
      reactHookForm.setValue(
        `arrivalLocation.district`,
        puntaCanaAddress?.district,
      )
      reactHookForm.setValue(
        `arrivalLocation.street`,
        puntaCanaAddress?.address,
      )
      reactHookForm.setValue(`arrivalLocation.city`, puntaCanaAddress?.city)
      reactHookForm.setValue(
        `arrivalLocation.geo_code`,
        puntaCanaAddress?.geo_code,
      )
      reactHookForm.setValue(
        `departureLocation.district`,
        puntaCanaAddress?.district,
      )
      reactHookForm.setValue(
        `departureLocation.street`,
        puntaCanaAddress?.address,
      )
      reactHookForm.setValue(`departureLocation.city`, puntaCanaAddress?.city)
      reactHookForm.setValue(
        `departureLocation.geo_code`,
        puntaCanaAddress?.geo_code,
      )
    },
    [reactHookForm],
  )

  return (
    <>
      {hasMap && (
        <>
          <Spacing size={5} />
          <a
            href={originsMap}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: '0.9rem' }}
          >
            {t('quotation.locateYourStay')}
          </a>
        </>
      )}
      {hasOrigin && (
        <>
          <Spacing size={18} />
          <Select
            style={{ width: '100%' }}
            value={reactHookForm.watch(`origin`)}
            onChange={async (value) => {
              reactHookForm.setValue(`origin`, value)
              reactHookForm.setError('coast', {})
              reactHookForm.setError('region', {})
              await validateAddress()
            }}
            placeholder={t(`quotation.origin`) as string}
            status={
              reactHookForm.formState.errors.origin?.message ? 'error' : ''
            }
            options={[
              { value: 2, label: 'Aeroporto Internacional de Punta Cana' },
              { value: 3, label: 'Aeroporto Internacional Las Américas' },
            ]}
          />
        </>
      )}
      <Spacing size={18} />
      <Select
        style={{ width: '100%' }}
        value={reactHookForm.watch('region')}
        status={reactHookForm.formState.errors?.region?.message ? 'error' : ''}
        onChange={async (value) => {
          reactHookForm.setValue(`${formKey}Region`, value)
          reactHookForm.setError('coast', {})
          handleSetAddress(value)
          await validateAddress()
        }}
        placeholder={t(`quotation.${formKey}LocationHotel`) as string}
        options={[
          { label: 'Arena gorda', value: 'arena_gorda' },
          { label: 'Bávaro', value: 'bávaro' },
          { label: 'Bayahibe', value: 'bayahibe' },
          { label: 'Cabeza de toro', value: 'cabeza_de_toro' },
          { label: 'Cap cana', value: 'cap_cana' },
          { label: 'El cortecito', value: 'el_cortecito' },
          { label: 'La romana', value: 'la_romana' },
          { label: 'Macao', value: 'macao' },
          { label: 'Uvero alto', value: 'uvero_alto' },
        ]}
      />

      <Spacing size={18} />

      <Input
        placeholder={t(`quotation.${formKey}Address`) as string}
        status={
          reactHookForm.formState.errors?.location?.message ? 'error' : ''
        }
        value={reactHookForm.watch('location')}
        onChange={(event) => {
          reactHookForm.clearErrors('location')
          reactHookForm.setValue(`location`, event.target.value)
          reactHookForm.setValue(`arrivalLocation.location`, event.target.value)
          reactHookForm.setValue(`address`, event.target.value)
          reactHookForm.setValue(`arrivalLocation.address`, event.target.value)
          reactHookForm.setValue(
            `departureLocation.address`,
            event.target.value,
          )
          reactHookForm.setValue(
            `departureLocation.location`,
            event.target.value,
          )
        }}
      />
    </>
  )
}

function PuntaCana({
  sameAddress,
  transferType,
}: Pick<AddressProps, 'sameAddress' | 'transferType'>) {
  if (sameAddress === false) {
    return (
      <>
        <PuntaCanaForm formKey="arrival" hasMap hasOrigin />
      </>
    )
  }

  return <PuntaCanaForm formKey="arrival" hasMap hasOrigin />
}

function AirportOrPuntaCana({
  sameAddress,
  transferType,
  transfer,
}: Pick<AddressProps, 'sameAddress' | 'transferType' | 'transfer'>) {
  const reactHookForm = useFormContext<QuotationApiRequest>()
  if (sameAddress === false) {
    return (
      <>
        <Spacing size={18} />

        <AddressSelect
          translationKey="quotation.arrivalAddress"
          formKey="arrivalAddress"
          transferType={transferType}
        />

        <Spacing size={9} />

        <AddressSelect
          translationKey="quotation.departureAddress"
          formKey="departureAddress"
          transferType={transferType}
        />
      </>
    )
  }

  return (
    <>
      <Spacing size={18} />

      <AddressSelect
        translationKey={
          reactHookForm.getValues('transfer') === 'returnTrip'
            ? 'quotation.addressReturnTrip'
            : 'quotation.address'
        }
        formKey="address"
        transferType={transferType}
      />
    </>
  )
}

function Coast() {
  const reactHookForm = useFormContext<QuotationApiRequest>()

  const { t } = useTranslation()

  const handleSetAddress = useCallback(
    (value: string) => {
      const skiStationAddress = HANDLE_SET_ADDRESS_COAST[value]
      reactHookForm.setValue('address', skiStationAddress.address)
      reactHookForm.setValue(
        `arrivalLocation.street`,
        skiStationAddress.address,
      )
      reactHookForm.setValue(
        `departureLocation.street`,
        skiStationAddress.address,
      )

      reactHookForm.setValue(
        `arrivalLocation.district`,
        skiStationAddress.district,
      )
      reactHookForm.setValue(`arrivalLocation.city`, skiStationAddress.city)

      reactHookForm.setValue(
        `arrivalLocation.geo_code`,
        skiStationAddress.geo_code,
      )
      reactHookForm.setValue(
        `departureLocation.district`,
        skiStationAddress.district,
      )
      reactHookForm.setValue(`departureLocation.city`, skiStationAddress.city)
      reactHookForm.setValue(
        `departureLocation.geo_code`,
        skiStationAddress.geo_code,
      )
    },
    [reactHookForm],
  )

  useEffect(() => {
    const coast = reactHookForm.getValues('coast')
    if (coast?.length) {
      handleSetAddress(coast)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactHookForm.getValues('coast')])

  return (
    <>
      <Spacing size={18} />

      <Select
        style={{ width: '100%' }}
        value={reactHookForm.watch('coast')}
        onChange={(value) => {
          reactHookForm.setValue('coast', value)
          reactHookForm.setError('coast', {})
          handleSetAddress(value)
        }}
        status={reactHookForm.formState.errors.coast?.message ? 'error' : ''}
        placeholder={t('quotation.coastAddress') as string}
        options={[
          { value: 'algarrobo', label: 'Algarrobo' },
          { value: 'sanAntonio', label: 'San Antonio' },
          { value: 'valparaiso', label: 'Valparaiso' },
        ]}
      />

      <Spacing size={18} />

      <Input
        placeholder={t('quotation.address') as string}
        status={
          reactHookForm.formState.errors?.location?.message ? 'error' : ''
        }
        onChange={(event) => {
          reactHookForm.clearErrors('location')
          reactHookForm.setValue(`location`, event.target.value)
          reactHookForm.setValue(`arrivalLocation.street`, event.target.value)
          reactHookForm.setValue(`arrivalLocation.location`, event.target.value)
          reactHookForm.setValue(`departureLocation.street`, event.target.value)
          reactHookForm.setValue(
            `departureLocation.location`,
            event.target.value,
          )
          reactHookForm.setValue(`arrivalLocation.address`, event.target.value)
          reactHookForm.setValue(
            `departureLocation.address`,
            event.target.value,
          )
        }}
      />
    </>
  )
}

function SkiStation() {
  const reactHookForm = useFormContext<QuotationApiRequest>()
  const { t } = useTranslation()

  const handleSetAddress = useCallback(
    (value: string) => {
      if (!value || !value?.length) return
      const skiStationAddress = HANDLE_SET_ADDRESS_SKI_STATION[value]
      reactHookForm.setValue('address', skiStationAddress.address)
      reactHookForm.setValue(
        `arrivalLocation.street`,
        skiStationAddress.address,
      )
      reactHookForm.setValue(
        `departureLocation.street`,
        skiStationAddress.address,
      )
      reactHookForm.setValue(
        `arrivalLocation.district`,
        skiStationAddress.district,
      )
      reactHookForm.setValue(`arrivalLocation.city`, skiStationAddress.city)

      reactHookForm.setValue(
        `arrivalLocation.geo_code`,
        skiStationAddress.geo_code,
      )
      reactHookForm.setValue(
        `departureLocation.district`,
        skiStationAddress.district,
      )
      reactHookForm.setValue(`departureLocation.city`, skiStationAddress.city)
      reactHookForm.setValue(
        `departureLocation.geo_code`,
        skiStationAddress.geo_code,
      )
    },
    [reactHookForm],
  )

  useEffect(() => {
    const skiStation = reactHookForm.getValues('skiStation')
    if (skiStation?.length) {
      handleSetAddress(skiStation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactHookForm.getValues('skiStation')])

  return (
    <>
      <Spacing size={18} />

      <Select
        style={{ width: '100%' }}
        value={reactHookForm.watch('skiStation')}
        onChange={(value) => {
          reactHookForm.setValue('skiStation', value)
          reactHookForm.setError('skiStation', {})
          handleSetAddress(value)
        }}
        status={
          reactHookForm.formState.errors.skiStation?.message ? 'error' : ''
        }
        placeholder={t('quotation.skiStationAddress') as string}
        options={[
          { value: 'elColorado', label: 'El Colorado' },
          { value: 'farellones', label: 'Farellones' },
          { value: 'laParva', label: 'La Parva' },
          { value: 'valleNevado', label: 'Valle Nevado' },
        ]}
      />

      <Spacing size={18} />

      <Input
        placeholder={t('quotation.address') as string}
        status={
          reactHookForm.formState.errors?.location?.message ? 'error' : ''
        }
        onChange={(event) => {
          reactHookForm.clearErrors('location')
          reactHookForm.setValue(`location`, event.target.value)
          reactHookForm.setValue(`arrivalLocation.address`, event.target.value)
          reactHookForm.setValue(
            `departureLocation.address`,
            event.target.value,
          )
          reactHookForm.setValue(`arrivalLocation.location`, event.target.value)
          reactHookForm.setValue(
            `departureLocation.location`,
            event.target.value,
          )
        }}
      />
    </>
  )
}

export default function Address({ sameAddress, transferType }: AddressProps) {
  if (transferType === 'coast') {
    return (
      <>
        <Coast />
        <ModalErrorAddressInvalid />
      </>
    )
  }

  if (transferType === 'skiStation') {
    return (
      <>
        <SkiStation />
        <ModalErrorAddressInvalid />
      </>
    )
  }
  if (transferType === 'puntaCana') {
    return (
      <>
        <PuntaCana sameAddress={sameAddress} transferType={transferType} />
        <ModalErrorAddressInvalid />
      </>
    )
  }

  return (
    <>
      <AirportOrPuntaCana
        sameAddress={sameAddress}
        transferType={transferType}
      />
      <ModalErrorAddressIncludesAirport />
      <ModalErrorAddressInvalid />
    </>
  )
}

function ModalErrorAddressIncludesAirport() {
  const {
    closeModalAddressIncludesAirport,
    addressIncludesAirportModalOpened,
  } = useGlobalState()
  return (
    <Modal
      visible={addressIncludesAirportModalOpened}
      onClose={closeModalAddressIncludesAirport}
    >
      <S.Column style={{ maxWidth: '670px' }}>
        <S.Title>{t('quotation.modalInvalidAddressTitle')}</S.Title>

        <S.Row>
          <S.SubTitle>
            {t('quotation.modalAddressIncludesAirportContent')}
          </S.SubTitle>
        </S.Row>
      </S.Column>
    </Modal>
  )
}

function ModalErrorAddressInvalid() {
  const { invalidAddressModalOpened, closeModalInvalidAddress } =
    useGlobalState()
  return (
    <Modal
      visible={invalidAddressModalOpened}
      onClose={closeModalInvalidAddress}
    >
      <S.Column style={{ maxWidth: '670px' }}>
        <S.Title>{t('quotation.modalInvalidAddressTitle')}</S.Title>

        <S.Row>
          <S.SubTitle>
            {t('quotation.modalInvalidAddressContent')}
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => window.open('https://wa.me/56941016076', '_blank')}
            >
              <Whatsapp fill="#1677ff" />
              <span style={{ color: '#1677ff' }}>+56 9 6827 3783</span>
            </div>
          </S.SubTitle>
        </S.Row>
      </S.Column>
    </Modal>
  )
}
