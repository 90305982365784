import { useState, useRef, useCallback } from 'react'

import logo from 'assets/logo.png'
import { ReactComponent as MenuHamburger } from 'assets/menu-hamburger.svg'
import { useOutsideClick } from 'hooks/use-outside-click'

import Menu from './Menu'
import * as S from './styles'

export default function Header() {
  const menuHamburgerReference = useRef()

  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false)

  const onOutsideClick = useCallback(() => {
    if (mobileMenuOpened) {
      setMobileMenuOpened(false)
    }
  }, [mobileMenuOpened])

  useOutsideClick(menuHamburgerReference, onOutsideClick)

  return (
    <S.Header>
      <MenuHamburger
        ref={menuHamburgerReference}
        id="menu-hamburger"
        onClick={() => setMobileMenuOpened((previousState) => !previousState)}
      />

      <div
        className="logo-container"
        onClick={() => {
          window.open('/', '_self')
        }}
      >
        <img src={logo} alt="logo" id="header-logo" />
      </div>

      {mobileMenuOpened ? (
        <S.FloatingMenuContainer>
          <Menu />
        </S.FloatingMenuContainer>
      ) : null}

      <div id="menu-container">
        <Menu />
      </div>
    </S.Header>
  )
}
