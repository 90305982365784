import { useState, useRef, useCallback } from 'react'

import Contact from 'components/Contact'
import { useOutsideClick } from 'hooks/use-outside-click'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Menu() {
  const { t } = useTranslation()
  const languageContainerReference = useRef()

  const [contactModalVisible, setContactModalVisible] = useState<boolean>(false)

  const [desktopLanguageSelectorOpened, setDesktopLanguageSelectorOpened] =
    useState<boolean>(false)

  const onOutsideClick = useCallback(
    (event: MouseEvent) => {
      if ((event.target as HTMLElement).id === 'language-label') return

      if (desktopLanguageSelectorOpened) {
        setDesktopLanguageSelectorOpened(false)
      }
    },
    [desktopLanguageSelectorOpened],
  )

  useOutsideClick(languageContainerReference, onOutsideClick)

  return (
    <>
      <S.MenuItem href="/#quotation">{t('menu.reservations')}</S.MenuItem>

      <S.MenuItem href="/#testimonials">{t('menu.depositions')}</S.MenuItem>

      <S.MenuItem
        as="span"
        onClick={() => {
          setContactModalVisible(true)
        }}
      >
        {t('menu.contact')}
      </S.MenuItem>

      <Contact
        visible={contactModalVisible}
        onClose={() => setContactModalVisible(false)}
      />
    </>
  )
}
