const pt = {
  menu: {
    reservations: 'Reservas',
    depositions: 'Depoimentos',
    contact: 'Contato',
    faq: 'Dúvidas frequentes',
    language: 'Idioma',
    pt: 'PT',
    portuguese: 'Portugués',
    es: 'ES',
    spanish: 'Español',
    en: 'EN',
    english: 'English',
  },
  contact: {
    title: 'Contato',
    description: 'Envie sua mensagem',
    name: 'Nome',
    phone: 'Telefone',
    cellPhone: 'Celular',
    message: 'Mensagem',
    send: 'Enviar',
    error: 'Erro ao enviar o contato, favor tentar novamente!',
    success: 'Contato enviado com sucesso',
  },
  requirements: {
    title: 'Requisitos para viajem',
    puntaCana: {
      requirement1: 'Passaporte - Vigente',
      requirement2:
        'Vacina contra Febre Amarela realizada com um mínimo de 10 dias antes da viagem',
      requirement3:
        'Certificado de Vacina emitido no site do governo, <a style="cursor: pointer;" href="https://www.gov.br" target="_blank" >www.gov.br</a>',
      requirement4:
        'Criança viajando com apenas um dos pais (ou cuidador) será necessário apresentação de documento autorizando a viagem do menor',
    },
    chile: {
      requirement1:
        'Passaporte ou RG. Fique atento: o RG não pode ter mais de 10 anos (a contar da data de emissão)',
      requirement2:
        'Criança viajando com apenas um dos pais (ou cuidador) será necessário apresentação de documento autorizando a viagem do menor',
      requirement3:
        '** NÃO pode ser Certidão de Nascimento,  titulo de eleitor, carteira de motorista, CPF ou qualquer outro documento',
      requirement4:
        '<a style="cursor: pointer;" href="{{link}}" download="FORMULÁRIO DA ADUANA/SAG.pdf" >FORMULÁRIO DA ADUANA/SAG</a>',
      requirement5:
        '** Para passar pela Aduana será necessário apresentar o formulário SAG preenchido (na forma digital ou impresso).',
    },
  },
  faq: {
    title: 'Dúvidas frequentes',
    question1: '1. Necessito colocar o aeroporto de chegada e saída?',
    answer1:
      '- NÃO, coloque apenas o endereço de onde você está ou estará hospedado.',
    question2: '2. Vou ganhar o Kit Bienvenido?',
    answer2:
      '- SIM, se você contratar o Transfer IN/OUT e pagar tudo na chegada. O kit está sujeito a estoque.',
    question3: '3. O Transfer é Privado?',
    answerchile3:
      '- O Transfer é semi-compartilhado, ou seja se houver algum passageiro vindo no mesmo vôo que o seu vocês iram no mesmo carro do contrario irá sozinho.',
    answerpuntaCana3: '- Em Punta Cana só operamos transfer Privado',
    question4: '4. Qual horário o carro irá passar em meu hotel?',
    answer41: '- O Transfer OUT (regresso) será realizado com:',
    answer411: 'Vôo Nacional mais ou menos com 3h antes do vôo',
    answer412: 'Vôo Internacional mais ou menos com 4h antes do vôo',
    answer42:
      '- A logistica é feita após as 18h e após as 20h confirmamos em seu whatsapp, registrado na reserva o horário exato que o motorista passara.',
    question5: '5. Quero sair do meu hotel em outro horário, como faço?',
    answer51:
      '- Você deve avisar por escrito no whatsapp do Transfer (24h antes da logística) ou nas Obervações de sua reserva',
    answer52:
      '- Após ser passado o horário que o carro irá passar NÃO será possível fazer alteração do horário.',
    question6: '6. Posso levar no celular o Eticket?',
    answer6: '- SIM, não necessita imprimir.',
    question7: '7. Onde encontro o Eticket para preencher?',
    answer7:
      '<a href="https://www.migracio.gob.do" target="_blank style="cursor: pointer;">- www.migracio.gob.do</a>',
    question8: '8. O formulário Eticket é o mesmo para toda a família?',
    answer8:
      '- SIM, você pode colocar no mesmo formulário toda sa familia bastar informar no campo que diz "está viajando acompanhado" SIM',
    question9: '9. Posso levar Reais para Punta Cana?',
    answer9:
      '- NÃO, nenhum lugar aceita Reais, nem nas cadas de câmbio. Você deve levar Dólares ou Pesos Dominicanos',
    question10: '10. Necessito de alguma vacina?',
    answer10:
      '- SIM, Vacina de febre amarela, com mínimo de 10 dias antes da viagem',
    question11: '11. Necessito de receita médica para levar medicamentos?',
    answer11: '- NÃO, é necessário de receita',
    question12: '12. Tem casa de câmbio no aeroporto?',
    answer12: '- SIM',
  },
  usefulLinks: {
    title: 'Links úteis',
    link1: 'Termos e Condições',
    link2: 'Guia de Dicas e Informações de Santiago',
    link3: 'Declaração Aduana',
    link4: 'Como preencher o formulário de aduana',
    link5: 'Procedimentos ao desembarcar',
  },
  termsAndConditions: {
    title: 'Termos e condições',
    generalTransportConditions: 'CONDIÇÕES GERAIS DO TRANSPORTE',
    generalTransportConditionsText: `As seguintes condições são aceitas pelo passageiro no momento de contratar nosso serviço.

    Se tem alguma dúvida por favor entrar em contato pelo nosso whatsapp +56 96827 3783.`,
    baggage: 'MALAS',
    baggageText: `Artigo Pessoal: Bolsa, mochila, Laptop ou bolsa de bebê

    Mala de mão: É a mala que é transportada pelo passageiro dentro da cabine do avião.
    
    Mala de Bodega: É a mala despachada no aeroporto, para ir no porão do avião.`,
    baggageComplement: `Será permitido 1 mala de 23kg e 1 mala de 10kg por passageiro pagante 100%, uma bolsa ou mochila, malas adicionais ou bagagem extra será cobrado a parte.

    Os seguintes tipos de equipagem não poderão ser transportadas em uma Van Semicompartilhada e deve contratar um serviço privado e combinar antes em nosso Whatsapp, para que possamos verificar a possibilidade de atender, as reservas realizadas nem nosso site sem que confirmemos a possibilidade de atender serão Canceladas.
    
    Instrumentos musicais (pequeno)
    Prancha de Surf, Windsurf, Snowboard, Ski, etc..
    Televisão`,
    payment: 'PAGAMENTO',
    paymentText: `*Forma de Pagamento para reservas – SANTIAGO

    Cartão de crédito na hora da reserva no site
    PIX ( em reais no brasil antes da viagem)
    WISE em pesos chilenos, desde o Brasil antes da viagem
    NA CHEGADA AO CHILE deverá ser realizado o pagamento de 30% do valor total antes da viagem para efetivar a reserva e o restante apenas com Cartão de crédito em pesos – com acréscimo de 5% sobre o valor normal do Transfer pelas taxas da empresa operadora da maquina de cartão (cartão deve ser Internacional e liberado para uso fora do Brasil)
    **NÃO aceitamos pagamento em dinheiro (para segurança de nossos motoristas e passageiros)
    Nossos motoristas estão proibidos receber em dinheiro.
     
    *Forma de Pagamento para reservas – PUNTA CANA
    
    *Forma de Pagamento para reservas – PUNTA CANA
    Cartão de crédito por PayPal com acréscimo de 6%
    Cartão de crédito parcelado, o juros será por conta do passageiro.
    WISE ou NOMAD em dólares americanos, desde o Brasil antes da viagem
    Para efetivar a reserva deverá ser realizado o pagamento de 30% do valor total, até 2 dias antes da viagem deverá estar tudo pago.
    **NÃO aceitamos pagamento em dinheiro (para segurança de nossos motoristas e passageiros)
    Nossos motoristas estão proibidos receber em dinheiro
    
    **NÃO aceitamos pagamento em dinheiro (para segurança de nossos motoristas e passageiros)
    Nossos motoristas estão proibidos receber em dinheiro.`,
    generalConditionsService: 'CONDIÇÕES GERAIS DO SERVIÇO',
    generalConditionsServiceText: `
    Aeroporto – Hotel
    No Transfer semicompartilhado, se houver alguém vindo no mesmo voô que você, essa pessoa ou família irá na mesma Van, se não houver ninguém no mesmo voô você irá sozinho na Van.
    Não esperamos lotar a Van.
    Toda e qualquer alteração no seu voô deve ser avisado no whatsapp do Transfer.
    Se houver um atraso muito grande do voô ou do passageiro e não tivermos horário disponível encaixaremos o passageiro em outro carro, podendo ocorrer uma espera do mesmo.
    A espera é de no máximo 90 minutos após a aterrisagem do avião, pedimos que quando chegue acesse o wifi do aeroporto e nos mantenha informados, caso o passageiro não aparece dentro desse tempo será considerado No Show.
    No dia do voô enviar uma foto dos passageiros no whatsapp do Transfer com o nome do responsável pela reserva, para facilitar a identificação no aeroporto.
    Nossa equipe estará uniformizada e com uma placa do Transfer esperando na saída do desembarque
    
    Hotel – Aeroporto
    O horário do transfer de regresso será determinado segundo os dados do vôo informado pelo passageiro no momento do agendamento.
    A logística é feita após as 18h e passaremos aos passageiros o horário exato em que devera estar esperando a Van.
    Nacional: Aproximadamente 3 horas antes da saída do voô.
    Internacional: Aproximadamente 4 horas antes da saída do voô.
    O passageiro deverá esperar abaixo na recepção onde possa ver quando a Van chegue, não avisamos quando o motorista chega.
    A Van só espera 10 minutos fora do domicilio indicado, se não conseguimos contato com o passageiro, o veiculo avançara com sua rota em direção ao aeroporto, e o Transfer será considerado realizado.
    Nossa responsabilidade é deixar o passageiro no horário no aeroporto e não podemos deixar os passageiros dentro da Van esperando ou atrasar para buscar outro.`,
    others: 'VALLE NEVADO, FARELLONES, LA PARVA ETC…',
    othersText: `A subida a montanha esta sujeita a condições climáticas, podendo ser alterado horário ou cancelado o transfer devido a mesma.

    Subidas e descidas a montanha de sábado, domingo e feriados estão sujeitas aos horários estipulados pelos Carabineiros.
    
    Subidas somente até as 14h
    Descidas somente após as 15h
    O transfer ida e volta uma vez contratado deve ser como no estipulado no agendamento, no mesmo bairro que foi deixado, caso contrario será cobrada a diferença do bairro.
    
    Toda e qualquer alteração devera ser avisada com 24h de antecedência da solicitação e logística, através do whatsapp do Transfer.
    
    Por favor, aguarde na recepção em um local onde você possa ver quando a Van chegar, conforme horário programado. A van esperara 10 minutos se o passageiro não aparecer entenderemos que não irá ao transfer, configurando No Show (Nesse caso não haverá reembolso). Em respeito aos demais passageiros, o motorista seguira ao aeroporto. Não efetuamos chamadas ao apartamento!
    
    Não nos responsabilizamos por pertences esquecidos nos carros.
    
    Politica de Cancelamento – TRANSFER AEROPORTO-HOTEL-AEROPORTO
    
    Cancelamento caso seja solicitado o cancelamento do serviço o mesmo deverá ser solicitado por escrito no whatsapp da empresa +56 9 6827 3783 dentro do prazo limite mínimo de 48h antes do Transfer e será devolvido 50% do valor pago pelo serviço cancelado ou não utilizado, através da mesma modalidade utilizada pelo passageiro para efetuar o pagamento do serviço em um prazo máximo de 5 dias úteis.
    
    Cancelamento da viagem caso passageiro tenha que cancelar sua viagem e já tenha efetuado o pagamento, deverá comunicar a empresa por escrito no whatsapp +56 9 6827 3783 dentro do prazo limite mínimo de 48h antes do Transfer e o passageiro ficará com um Voucher no valor pago para utilizar em até 1 ano a contar da data de cancelamento, ou o reembolso total do valor pago através da mesma modalidade utilizada pelo passageiro para efetuar o pagamento do serviço em um prazo máximo de 5 dias úteis, com menos de 48h antes do transfer apenas será realizado Voucher no valor pago para utilizar em até 1 ano a contar da data de cancelamento, ou o reembolso de 50% do valor pago através da mesma modalidade utilizada pelo passageiro para efetuar o pagamento do serviço em um prazo máximo de 5 dias úteis
    
    NO SHOW no caso de não comparecimento do passageiro não será devolvido o valor pago.
    
    Politica de Cancelamento – TOUR
    Para cancelamento do Tour, o mesmo deverá ser solicitado por escrito no whatsapp da empresa +56 9 6827 3783 dentro do prazo limite mínimo de 48h antes do primeiro Tour contratado e o passageiro receberá o reembolso total do valor pago através da mesma modalidade utilizada pelo passageiro para efetuar o pagamento do serviço em um prazo máximo de 5 dias úteis, caso seja solicitado o cancelamento com 24h antes do Tour apenas será realizado o reembolso de 50% do valor pago através da mesma modalidade utilizada pelo passageiro para efetuar o pagamento do serviço em um prazo máximo de 5 dias úteis.
    Reagendamentos poderão ser solicitados dentro do prazo limite mínimo de 48h de antecedência da data da saída do passeio, e estará sujeito a disponibilidade de vagas.
    NO SHOW no caso de não comparecimento do passageiro não será devolvido o valor pago.
    
    O passageiro é o único responsável pelos dados ingressados na reserva, tendo em vista que ele é a única pessoal que sabe os dados de sua viagem, sendo assim a empresa Transfer Brasil não se responsabiliza por dados informados errados na reserva.
    Qualquer duvida entrar em contato pelo nosso whatsapp +56 9 6827 3783
    
                       Transfer Brasil
                      Santiago – Chile                                                                                                         
    Transfer Aeroporto – Hotel – Aeroporto
    
          Punta Cana – República Dominicana
    Transfer Aeroporto – Hotel – Aeroporto e Tour
    
                    Cartagena – Colômbia 
    Transfer Aeroporto – Hotel – Aeroporto e Tour`,
    baggageTable: {
      headers: {
        service: 'Serviço',
        personalArticle: 'Artigo Pessoal',
        handbag: 'Mala de Mão',
        bodegaSuitcase: 'Mala de Bodega',
      },
      serviceColumn: {
        semiSharedVan: 'Van Semicompartilhada',
        quantityPerPerson: 'Quantidade por Pessoa',
        private: 'Privado',
        quantityPerCar: 'Quantidade por carro',
      },
      personalArticleColumn: {
        semiSharedVan: '01',
        private: '06',
      },
      handbagColumn: {
        semiSharedVan: '01',
        private: '06',
      },
      bodegaSuitcase: {
        semiSharedVan: '01',
        private: '06',
      },
    },
  },
  paymentMethods: {
    title: 'Formas de pagamento',
    text1:
      'Em Dinheiro na chegada podendo ser em reais, pesos ou dólares. Câmbio do dia da chegada (é feito uma média entre o câmbio do aeroporto e da rua Augustina onde tem o melhor cambio).',
    puntaCanatext2: `O pagamento pode ser realizado das seguintes formas:

    PAGAMENTO EM REAIS
    
        * Cartão de crédito em até 12 x
        * PIX
    
    PAGAMENTO EM DÓLARES - tem 10% de DESCONTO
    
         * WISE em dólares, desde o Brasil antes da viagem
         * NOMAD em dólares, desde o Brasil antes da viagem
    
    Deverá ser pago 30% do valor total para efetivar a reserva 
    
    **NÃO aceitamos pagamento em dinheiro (para segurança de nossos motoristas e passageiros)
    Nossos motoristas estão proibidos receber em dinheiro.`,
    chiletext2: `O pagamento pode ser realizado total das seguintes formas:

    * PIX ( em reais no brasil antes da viagem)
    
    * WISE em pesos chilenos, desde o Brasil antes da viagem
    
    * NA CHEGADA NO CHILE apenas com Cartão de crédito em pesos - com acréscimo de 5% sobre o valor normal do Transfer pelas taxas da empresa operadora da maquina de cartão (cartão deve ser Internacional e liberado para uso fora do Brasil)
    Deverá ser pago 30% do valor total por PIX ou WISE para efetivar a reserva 
    
    
    **NÃO aceitamos pagamento em dinheiro (para segurança de nossos motoristas e passageiros)
    Nossos motoristas estão proibidos receber em dinheiro.
    
    **Pagamento em PIX a conversão para reais será pelo câmbio utilizado pela empresa`,
  },
  semiShared: {
    title: 'Semi compartilhado',
    text1:
      'O Transfer semi-compartilhado, significa que você terá um preço muito mais acessível sem precisar esperar horas até a van encher, como acontece com os transfer compartilhados.',
    text2:
      'Operamos somente transfers agendados, você só ira dividir a van caso no mesmo voo que o seu haja algum outro passageiro nosso indo para algum endereço próximo ao seu. Caso contrário você irá sozinho na van.',
  },
  welcomeKit: {
    title: 'Kit de boas vindas',
    text1: 'Fechando ida e volta* você ganha:',
    text2: '1 Chip por Adulto',
    text3: '1 Caneta Detecta Notas Falsas',
    text4: '1 Guia Dicas e Informações de Santiago',
    chiletext5: '*até esgotar estoque',
    puntaCanatext5:
      '1 Parada a caminho ao Hotel para compra de chip de celular. (a noite o local é fechado então não conseguimos fazer essa parada)',
    text6:
      '1 Adaptador de tomadas por casal (visto que as tomadas de Punta Cana são diferentes das do Brasil)',
  },
  quotation: {
    readTermsConditions:
      '<p>Li o contrato e concordo com os termos. <span style="cursor: pointer;margin-right: 5px;color:#1677ff;">Leia o contrato</span></p>',
    foundAddressSame: 'Encontrado endereço parecido',
    addressSameSkiStationOrCoast:
      'Este endereço é de uma estação de esqui ou de um litoral?',
    total: 'Total',
    reservationValue: 'Valor da reserva',
    paymentValue: 'Valor a pagar',
    unitValue: 'Valor unitário',
    additionalItem: 'Item adicional',
    additional: 'Adicional',
    quantity: 'Quantidade',
    makeYourQuote: 'Faça sua cotação',
    chooseYourDestiny: 'Escolha o seu destino',
    airport: 'Aeroporto',
    skiStation: 'Estação de Ski',
    coast: 'Litoral',
    puntaCana: 'Punta Cana',
    tour: 'Passeio',
    roundTrip: 'Ida e volta',
    oneWay: 'Somente ida',
    returnTrip: 'Somente volta',
    address: 'Para onde? - Endereço completo',
    addressReturnTrip: 'Onde estará? - Endereço completo',
    locateYourStay: 'Localize sua estadia',
    departureAddress: 'Onde estará? - Endereço completo',
    arrivalAddress: 'Para onde? - Endereço completo',
    skiStationAddress: 'Estação de Ski',
    arrivalLocationHotel: 'Localização do hotel de destino',
    departureLocationHotel: 'Localização do hotel de partida',
    coastAddress: 'Costa',
    origin: 'Origem',
    arrivalOrigin: 'Origem de destino',
    departureOrigin: 'Origem de partida',
    adults: 'Adultos',
    children: 'Crianças ',
    fiveToNineYears: '(5 a 9 anos)',
    babies: 'Bebês ',
    zeroToFourYears: '(0 a 4 anos)',
    specialBag: 'Bagagem especial ',
    specialBagDescription:
      'Cadeira de rodas, Carrinho de Bebê, Prancha de Surf, Snowboard, Ski e etc.',
    quotation: 'Cotar',
    quotationDetails: 'Detalhes da cotação',
    transportType: 'Tipo de transporte',
    paymentDetails: 'Dados de pagamento',
    transfer: 'Transfer',
    transferType: 'Opção',
    selectTransferType: 'Selecione o tipo de Transfer que deseja',
    semiShared: 'Compartilhada',
    maximumEightPeople: 'Máximo 8 pessoas',
    private: 'Privado',
    moneyPayment: 'Pagamento em dinheiro',
    reserveInMoneyPayment: 'Valor da reserva em dinheiro',
    semiSharedMoneyPaymentValue: 'CLP $ 45.000,00 pesos',
    privateMoneyPaymentValue: 'CLP $ 55.000,00 pesos',
    creditCardPayment: 'Pagamento em cartão',
    reserveInCardPayment: 'Cartão de Crédito',
    reserveInPixPayment: 'Pix',
    valuePixOnlyTodayQuote:
      'Esse valor em Cartão de crédito/Pix é somente para a cotação de hoje',
    valuePaymentPaidLess15Days:
      'Você pode realizar o pagamento com cartão de crédito até 15 dias antes da data do seu embarque',
    reserveInWisePayment: 'Transferência wise',
    semiSharedTooltipText:
      'Se estiver vindo alguém que ja tenha reserva conosco no mesmo voo irá junto no mesmo carro, se não você(s) irão sozinho no carro.',
    privateTooltipText: 'Será um carro só para você e sua família ou amigos.',
    baggageAllowancePerPassenger: 'Bagagem permitida por passageiro',
    passengers: 'Passageiros',
    paymentForm: 'Forma de pagamento',
    totalValueIn: 'Valor total em:',
    bodegaSuitcase: 'Mala de Bodega',
    handbag: 'Mala de mão',
    personalArticle: 'Artigo pessoal',
    next: 'Próximo',
    previous: 'Voltar',
    changeData: 'Alterar dados',
    transport: 'Transporte',
    totalPassengers: 'Total',
    flightData: 'Dados do voo',
    arrivalData: 'Dados da chegada',
    departureData: 'Dados da saída',
    flightDataDetails:
      'No Transfer Out a saída do hotel é realizada com 3 a 4 horas antes do voo dependendo do horário da saída.',
    contactDetails: 'Dados do contato',
    arrivalDate: 'Data de chegada',
    arrivalFlightTime: 'Horário do voo de chegada',
    arrivalFlightNumber: 'Nº do voo de chegada',
    departureDate: 'Data da saída',
    departureFlightTime: 'Horário do voo de saída',
    departureFlightNumber: 'Nº do voo de saída',
    flightNumber: 'Nº do voo',
    flight: 'Voo',
    flightType: 'Tipo de Voo',
    type: 'Tipo',
    hour: 'Hora',
    date: 'Data',
    national: 'Nacional',
    international: 'Internacional',
    observations: 'Observações',
    name: 'Nome',
    cpf: 'CPF',
    phone: 'Telefone',
    cellPhone: 'Celular',
    email: 'Email',
    whereYouMetUs: 'Onde nos conheceu?',
    confirm: 'Confirmar',
    makePayment: 'Efetuar pagamento',
    bookingConfirmation: 'Confirmação de reserva',
    reservationMadeSuccessfully: 'Reserva efetuada com sucesso Nº',
    resume: 'Resumo',
    unavailableDate: 'Data Indisponível',
    description: 'Descrição',
    reservationMadeSuccessfullySubtitle:
      'A confirmação do seu agendamento foi enviada automaticamente para seu whatsapp e e-mail, por favor verificar inclusive na caixa de spam.',
    instructionForPayment:
      'As instruções para pagamento foram enviadas para o seu whatsapp',
    reservationDetails: 'Dados da reserva',
    clientDetails: 'Dados do cliente',
    scheduled: 'Data e hora',
    scheduledLanding: 'Data e hora de chegada',
    scheduledTakeoff: 'Data e Hora de saída',
    arrival: 'Chegada',
    departure: 'Saída',
    paymentType: 'Formas de pagamento',
    paymentType1:
      'Oferecemos duas formas de realizar o pagamento da sua reserva:',
    paymentType2:
      'No momento da realização do transfer. Optando por essa modalidade, o pagamento terá que ser efetuado em dinheiro, em uma das seguintes moedas: Peso Chileno. Real ou Dolar Americano. Usamos a média do câmbio do dia do transfer.',
    paymentType3:
      'Através do cartão de credito cliclando no botão abaixo. O valor a ser pago será convertido para dólar na hora da concretização do pagamento.',
    payWithCash: 'Realizar pagamento com dinheiro',
    payWithCreditCard: 'Realizar pagamento com cartão',
    close: 'Fechar',
    cashPaymentInstructions:
      'No momento da realização do transfer. Optando por essa modalidade, o pagamento terá que ser efetuado em dinheiro, em uma das seguintes moedas: Peso Chileno. Real ou Dolar Americano. Usamos a média do câmbio do dia do transfer.',
    confirmation: 'Confirmação',
    contactWhatsappToSchedule:
      'Por favor entrar em contato com nosso WhatsApp +56 9 6827 3783 para realizar seu agendamento.',
    sameAddress: 'Os endereços de chegada e partida são os mesmo?',
    ok: 'Ok',
    yes: 'Sim',
    no: 'Não',
    modalInvalidAddressTitle: 'Endereço inválido',
    inputInvalidAddress: 'Endereço inválido',
    modalInvalidAddressContent:
      'Não localizamos o bairro do endereço de destino digitado em nossa lista de abrangência. Por favor, entre em contato com a Transfer Brasil pelo whatsapp:',
    modalAddressIncludesAirportContent:
      'Você não deve digitar o endereço do aeroporto, mas sim o lugar onde nosso motorista irá te deixar ou buscar',
  },
  informations: {
    title: 'Informações',
    payments: 'Formas de pagamento',
    semiShared: 'Compartilhado',
    welcomeKit: 'Kit de boas vindas',
    reserve: 'Como efetuar reserva?',
    chileRequirements: 'Requisitos para viajar para o Chile',
    puntaCanaRequirements: 'Requisitos para viajar para Punta Cana',
    questions: 'Perguntas frequentes',
    links: 'Links importantes',
    protocolCovid: 'Protocolo Covid-19',
    chileInformations: 'Informações do Chile',
    dominicanRepublicInformations: 'Informações de Punta Cana',
  },
  testimonials: {
    title: 'Depoimentos',
    description: 'O que as pessoas estão dizendo sobre nós.',
    firstTestimonial:
      '”Gostaria de agradecer a vocês por todos as dicas que peguei e que foram muito úteis na minha viagem, quero agradecer a Vânia e a Fátima pelo nosso transfer, serviço de qualidade!”',
    firstName: 'Jessica Hamada',
    secondTestimonial:
      '"Agradeço a @Mãe Chile @Vania @Transfer Brasil pelo serviço nota 1000, profissionalismo e pontualidade desde a Chegada e a saída maravilhosas uma pena não ter conhecido as meninas, parabéns e obrigada por proporcionar conforto no transfer chegamos cansados da viagem e o transfer das meninas é maravilhoso Gracias."',
    secondName: 'Siliene',
    thirdTestimonial:
      'Queria agradecer a Transfer Brasil pelo ótimo atendimento e trabalho. Já está indicado em todos os meus grupos. Espero retornar ao Chile, SDQ, no inverno algum dia. Obrigada e hasta luego.',
    thirdName: 'Bel',
    fourthTestimonial:
      'Empresa séria e organizada é outra coisa. Parabéns...nos sentimos seguros assim.',
    fourthName: 'Fernanda Couto',
    fifthTestimonial:
      'Olá Fátima e equipe, gostaríamos de agradecer a pontualidade, seriedade e competência. Com toda a certeza teremos o prazer indica-los. Obrigado.',
    fifthName: 'Abiel e Gisa',
    sixthTestimonial:
      'Obrigada @Transfer Brasil por toda a dedicação, foram impecáveis!!',
    sixthName: 'Bruna Ramires',
    seventhTestimonial:
      'Passando para agradecer o compromisso da Tranfer Brasil. Recomendarei! Obrigada.',
    seventhName: 'Tânia Vilhaça',
    eighthTestimonial:
      'Gostaria de agradecer pela pontualidade e profissionalismo com que nos atenderam em nossa viagem. Muito obrigada.',
    eighthName: 'Cristina e família',
    ninthTestimonial:
      'Excelente trabalho super pontuais, motoristas simpáticos. Super recomendado!!! Obrigado pela dica!',
    ninthName: 'Francisco Freitas',
    tenthTestimonial:
      'Ótimo trabalho do Transfer Brasil, super pontuais e atenciosos. Recomendarei com certeza.',
    tenthName: 'Sarah',
  },
  ourVehicles: {
    title: 'Nossos veículos',
  },
  ourTeam: {
    title: 'Nossa equipe',
    floorControl: 'Controladora de piso',
    driver: 'Condutor',
  },
  footer: {
    contacts: 'Contatos',
    address: 'General Del Canto, 488',
    phone: '+56 9 6827 3783',
    email: 'contato@transferbrasil.cl',
    links: 'Links',
    termsAndConditions: 'Termos e condições',
    privacyPolicy: 'Política de privacidade',
    socialMedia: 'Redes sociais',
    copyrights:
      '2018 - {{currentYear}} © Transfer Brasil - Todos os direitos reservados | Site desenvolvido por Jet Soluções Web',
  },
  errors: {
    generic: 'Ocorreu um erro',
    chooseAnOption: 'Escolha uma opção',
    chooseAnOptionPaymentForm: 'Escolha uma opção de pagamento',
    required: 'Campo obrigatório',
    invalidCpf: 'CPF inválido',
    departureLessArrivalDate: 'Data de partida é inferior a data de chegada',
    departureIsSamaArrivalDate: 'As data de chegada e partida são iguais',
  },
}

export default pt
