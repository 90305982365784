import ptBR from './pt'

const es = {
  ...ptBR,
  menu: {
    ...ptBR.menu,
    language: 'Lenguaje',
  },
}

export default es
