import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styles'

type InputFieldProps = PropsWithChildren<{
  label?: string | ReactNode
  fullWidth?: boolean
}>

export default function InputField({
  label,
  children,
  fullWidth,
}: InputFieldProps) {
  return (
    <S.Container fullWidth={fullWidth}>
      <S.InputLabel>{label}</S.InputLabel>
      {children}
    </S.Container>
  )
}
