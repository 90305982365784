import Modal, { ModalProps } from 'components/Modal'
import Spacing from 'components/Spacing'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function PaymentMethods({
  currentTab,
  ...props
}: Pick<ModalProps, 'visible' | 'onClose'> & { currentTab: string }) {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('paymentMethods.title')}</S.Title>

        <Spacing size={12} />

        <S.Text>{t(`paymentMethods.${currentTab}text2`)}</S.Text>
      </S.Container>
    </Modal>
  )
}
