import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.div<{ topGallery?: boolean }>`
  position: absolute;
  left: 0px;
  display: none;

  @media ${devicesMinWidth.laptopL} {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin-top: ${(props) => (props.topGallery ? '0' : '150px')};
    justify-content: center;
  }
`

export const Title = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 50px;
  text-align: center;
  color: #14183e;
  margin-bottom: 25px;
`

export const PhotosContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const Current = styled.div<{ imageSource: string }>`
  z-index: -1;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 600px;
  padding: 30px;
  background-image: ${(props) => `url(${props.imageSource})`};
  background-repeat: no-repeat;
  background-position: right;
`

export const CurrentVideo = styled.video`
  position: absolute;
  z-index: -1;
  width: auto;
  height: 600px;
  top: 0;
  right: 0;
`
export const VideoContainer = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  background: black;
  overflow: hidden;
  height: 600px;
`

export const Next = styled(Current)`
  position: absolute;
  background-color: white;
  left: 15px;
  box-shadow: none;
  border: 2px solid #f7f7f7;
  width: 80%;
  height: 100%;
  top: unset;
  bottom: -72px;
  justify-content: flex-end;

  @media ${devicesMinWidth.laptop} {
    top: 80px;
    left: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: column;
  }
`

export const NavigationContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 80px;
  margin-top: 150px;

  z-index: 1;

  svg {
    margin: 0px 10px;
    cursor: pointer;
    transform: rotate(270deg);
  }
`
