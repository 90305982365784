import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.div`
  bottom: 0;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1.5px solid #f0f0f0;
  font-family: 'Sora';
  font-style: normal;
  font-size: 11px;
  font-weight: 400;
  color: #080808;
  text-align: center;

  @media ${devicesMinWidth.laptop} {
    font-size: 12px;
    p {
      margin-left: 72px;
    }
  }
`
