import { useState, useMemo, useCallback } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Col, Typography, Tooltip, Row, AutoCompleteProps } from 'antd'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'
import Radio from 'antd/lib/radio'
import Segmented from 'antd/lib/segmented'
import bodegaSuitcase from 'assets/bodega-suitcase.png'
import chile from 'assets/chile.png'
import dominicanRepublic from 'assets/dominican-republic.png'
import handbag from 'assets/handbag.png'
import personalArticle from 'assets/personal-article.png'
import InputField from 'components/InputField'
import LoadingSpin from 'components/LoadingSpin'
import Modal from 'components/Modal'
import Spacing from 'components/Spacing'
import TextWithLabel from 'components/TextWithLabel'
import { Whatsapp } from 'components/WhatsappIcon'
import ENDPOINTS from 'constants/endpoints'
import { MASK } from 'constants/mask'
import { TRANSFER_TYPES } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import dayjs from 'dayjs'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import OldApiService from 'services/api/old-api'
import { encodedBase64 } from 'utils/base64'
import { generateLinkWhatsapp } from 'utils/generateLinkWhatsapp'
import { monetaryMask, roundUpperNumber, unMaskMonetary } from 'utils/maks'

import Address from './address'
import ContactDetails from './contact-details'
import Details from './details'
import FlightDetails from './flight-details'
import Payment from './payment'
import Resume from './resume'
import * as S from './styles'

const SHOW_MODAL_SAME_ADDRESS = ['airport']

enum TRANSFER {
  ROUNDTRIP = 'roundtrip',
  ONE_WAY_TRIP = 'oneWayTrip',
  RETURN_TRIP = 'returnTrip',
}
enum DESTINY {
  CHILE,
  PUNTA_CANA,
}

enum Steps {
  PERSONAL_INFORMATIONS,
  TYPE_OF_TRANSPORT,
  DETAILS,
  FLIGHT_DETAILS,
  CONTACT_DETAILS,
  RESUME,
  PAY_WITH_CASH,
  PAYMENT,
}

interface CheckAvailableParams {
  field: string
  today: string
  tomorrow: string
  hour: string
  formValues: QuotationApiRequest
}

const handleGetNumberTransfer = {
  [TRANSFER.ROUNDTRIP]: 1,
  [TRANSFER.ONE_WAY_TRIP]: 2,
  [TRANSFER.RETURN_TRIP]: 3,
}

const handleGetNumberTypeOfTransport = {
  semiShared: 1,
  private: 2,
}

const handleGetFieldFlightType: Record<FlightType, number> = {
  national: 0,
  international: 1,
}

export default function Quotation() {
  const { t } = useTranslation()
  const { isLoadingValidateAddress, setCurrentTransferType, transferType } =
    useGlobalState()
  const reactHookForm = useForm<QuotationApiRequest>({
    defaultValues: {
      transferType: TRANSFER_TYPES[0],
    },
  })

  const typeOfTransportFormValue = reactHookForm.watch('typeOfTransport')

  const [showSameAddressModal, setShowSameAddressModal] =
    useState<boolean>(false)
  const [showUnavailableReservation, setShowUnavailableReservation] =
    useState<boolean>(false)
  const [sameAddress, setSameAddress] = useState<boolean | undefined>()
  const [choiceDestiny, setChoiceDestiny] = useState(DESTINY.CHILE)

  const [transfer, setTransfer] = useState<TRANSFER>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [quoteData, setQuoteData] = useState<QuoteApiResponse>()
  const [reservationData, setReservationData] =
    useState<ReservationApiResponse>()
  const [partners, setPartners] = useState<AutoCompleteProps['options']>()
  const [errorMessageNotAvailable, setErrorMessageNotAvailable] = useState('')
  const [
    errorMessageNotAvailableArrivalDate,
    setErrorMessageNotAvailableArrivalDate,
  ] = useState('')
  const [
    errorMessageNotAvailableDepartureDate,
    setErrorMessageNotAvailableDepartureDate,
  ] = useState('')
  const [currentStep, setCurrentStep] = useState<Steps>()

  const [segmentedValues, setSegmentedValues] = useState([
    {
      label: t('quotation.airport'),
      value: 'airport',
    },
    { label: t('quotation.skiStation'), value: 'skiStation' },
    { label: t('quotation.coast'), value: 'coast' },
  ])

  const handleGetQuoteResponseValueField: Record<
    number,
    () => keyof QuoteQueryValue | undefined
  > = useMemo(
    () => ({
      1: () => 'Chegada',
      2: () => 'Chegada',
      3: () => 'Partida',
    }),
    [],
  )

  const handleGetFieldCalcByTypeReservation: Record<number, string> = useMemo(
    () => ({
      1: 'valor_volta_ida',
      2: 'valor_ida',
      3: 'valor_volta',
    }),
    [],
  )

  const getCalcColetive = useCallback(
    (queryValue: QuoteArrival): number => {
      const field = handleGetFieldCalcByTypeReservation[
        queryValue.typeReservation as number
      ] as keyof QuoteArrival['DestinosOpcao']
      let valorTrajeto: string | number = unMaskMonetary(
        queryValue.DestinosOpcao[field],
      )
      if (queryValue.Unitario) {
        const extraValue =
          (unMaskMonetary(queryValue.Unitario[field]) / 2) *
          Number(queryValue.children)
        valorTrajeto = valorTrajeto + extraValue
      }
      return valorTrajeto as number
    },
    [handleGetFieldCalcByTypeReservation],
  )
  const getCalcIndividual = useCallback(
    (queryValue: QuoteArrival) => {
      const field = handleGetFieldCalcByTypeReservation[
        queryValue.typeReservation as number
      ] as keyof QuoteArrival['DestinosOpcao']

      let valorTrajeto =
        unMaskMonetary(queryValue.DestinosOpcao[field]) *
        Number(queryValue.adults)
      if (queryValue.Unitario) {
        const extraValue =
          (unMaskMonetary(queryValue.Unitario[field]) / 2) *
          Number(queryValue.children)
        valorTrajeto = valorTrajeto + extraValue
      }
      return valorTrajeto as number
    },
    [handleGetFieldCalcByTypeReservation],
  )

  const handleGetFunctionCalcByTypeBudget: Record<
    string,
    (queryValue: QuoteArrival) => number
  > = useMemo(
    () => ({
      C: (queryValue: QuoteArrival) => getCalcColetive(queryValue),
      I: (queryValue: QuoteArrival) => getCalcIndividual(queryValue),
    }),
    [getCalcColetive, getCalcIndividual],
  )

  const getCalcQuote = useCallback(
    (quoteData: QuoteApiResponse) => {
      if (!quoteData.typeReservation) return 0
      if (quoteData.typeReservation === 1 && quoteData.sameAddress === false) {
        const queryValueArrival = {
          ...quoteData.consultaValor.Chegada,
          typeReservation: quoteData.typeReservation,
          adults: quoteData.adults,
          children: quoteData.children,
        }
        const queryValueDeparture = {
          ...quoteData.consultaValor.Partida,
          typeReservation: quoteData.typeReservation,
          adults: quoteData.adults,
          children: quoteData.children,
        }
        const arraivalValue = handleGetFunctionCalcByTypeBudget[
          queryValueArrival.DestinosOpcao.tipo_cobranca
        ]?.({
          ...queryValueArrival,
          typeReservation: 3,
        })
        const departureValue = handleGetFunctionCalcByTypeBudget[
          queryValueDeparture.DestinosOpcao.tipo_cobranca
        ]?.({ ...queryValueDeparture, typeReservation: 2 })
        const total =
          unMaskMonetary(arraivalValue) + unMaskMonetary(departureValue)
        return total
      }

      const field =
        handleGetQuoteResponseValueField[quoteData.typeReservation]?.()
      if (field) {
        const queryValue = {
          ...quoteData.consultaValor[field],
          typeReservation: quoteData.typeReservation,
          adults: quoteData.adults,
          children: quoteData.children,
        }

        const value =
          handleGetFunctionCalcByTypeBudget[
            queryValue.DestinosOpcao.tipo_cobranca
          ]?.(queryValue)

        return value as number
      }
      return 0
    },
    [handleGetFunctionCalcByTypeBudget, handleGetQuoteResponseValueField],
  )

  const handleCreateReservation = useCallback(async () => {
    const formValues = reactHookForm.watch()
    const typeTransferField =
      sameAddress === false ? 'departureLocation' : 'arrivalLocation'

    if (!formValues.typeOfTransport) return

    setIsLoading(true)

    const formData = new FormData()
    const values = {
      Reserva: {
        tipo: handleGetNumberTransfer[formValues.transfer],
        data_chegada: dayjs(formValues.arrivalDate).format(MASK.DATE.ISO),
        data_partida: dayjs(formValues.departureDate).format(MASK.DATE.ISO),
        horario_chegada: formValues.arrivalFlightTime,
        horario_partida: formValues.departureFlightTime,
        voo_numero_chegada: formValues.arrivalFlightNumber,
        voo_numero_partida: formValues.departureFlightNumber,
        tipo_voo: handleGetFieldFlightType[formValues.arrivalFlightType],
        voo_tipo_chegada:
          handleGetFieldFlightType[formValues.arrivalFlightType],
        voo_tipo_partida:
          handleGetFieldFlightType[formValues.departureFlightType],
        observacoes: formValues.observations || '',
        tem_cupom_desconto: 0,
        cupom_desconto: '',
        hash: {
          tiporeserva: handleGetNumberTransfer[formValues.transfer],
          origem: formValues.origin || 1,
          tipo_transporte:
            handleGetNumberTypeOfTransport[formValues.typeOfTransport],
          mesmoEndereco: sameAddress ? 1 : 0,
          parceiro: sameAddress ? 1 : 0,
          cupom: '',
          item: formValues.specialBag
            ? {
                2: formValues.specialBag,
              }
            : undefined,
          Reserva: {
            metodo_pagamento: formValues.paymentForm,
            endereco_destino: formValues.arrivalLocation?.address,
            localidade_destino: formValues.arrivalLocation?.street,
            bairro_destino: formValues.arrivalLocation?.district,
            cidade_destino: formValues.arrivalLocation?.city,
            geocode_destino: formValues.arrivalLocation?.geo_code,
            endereco_destino_validado: 1,
            qtd_adt: formValues.adults,
            qtd_chd_10: formValues.children,
            qtd_chd_5: formValues.babies,
            endereco_saida: formValues[typeTransferField]?.address,
            localidade_saida: formValues[typeTransferField]?.street,
            bairro_saida: formValues[typeTransferField]?.district,
            cidade_saida: formValues[typeTransferField]?.city,
            geocode_saida: formValues[typeTransferField]?.geo_code,
            endereco_saida_validado: 1,
            data_hora_cotacao: quoteData?.date,
            enderecos_diferentes: sameAddress ? 0 : 1,
            valor_trajeto: monetaryMask(
              roundUpperNumber(quoteData?.valueTrip || 0),
              'pt-BR',
              {
                maximumFractionDigits:
                  formValues.paymentForm === 'wise' &&
                  formValues.transferType !== 'puntaCana'
                    ? 0
                    : 2,
                minimumFractionDigits:
                  formValues.paymentForm === 'wise' &&
                  formValues.transferType !== 'puntaCana'
                    ? 0
                    : 2,
              },
            ),
            valor_adicionais: roundUpperNumber(quoteData?.valueSpecialBag || 0),
          },
        },
      },

      Cliente: {
        nome: formValues.name,
        cpf: formValues.document,
        telefone: formValues.cellPhone,
        e_mail: formValues.email,
        parceiro_id: formValues.whereYouMetUs,
      },
    }

    formData.append('json', JSON.stringify(values))
    try {
      const responseDetailsReservation = await OldApiService.Reservation.create(
        formData,
      )
      if (!responseDetailsReservation) {
        return
      }

      const responsePaymentLink =
        await OldApiService.Reservation.getLinkPayment(
          encodedBase64(responseDetailsReservation?.reserva?.id) as string,
        )
      if (!responsePaymentLink) {
        return
      }
      setReservationData({
        ...responseDetailsReservation,
        paypal_url: decodeURIComponent(responsePaymentLink),
      })
      setCurrentStep(Steps.PAYMENT)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'reservation_made',
        name: formValues.name,
      })
    } catch (error) {
      toast.error(t('errors.generic'))
    }

    setIsLoading(false)
  }, [
    quoteData?.date,
    quoteData?.valueSpecialBag,
    quoteData?.valueTrip,
    reactHookForm,
    sameAddress,
    t,
  ])

  const onConfirm = useCallback(async () => {
    reactHookForm.clearErrors()
    const formValues = reactHookForm.watch()

    let hasError = false
    const typeTransferField =
      sameAddress === false ? 'departureLocation' : 'arrivalLocation'
    if (transferType === 'puntaCana' && !formValues.origin) {
      hasError = true
      reactHookForm.setError('origin', {
        message: t('errors.required') as string,
      })
    }

    if (!formValues.arrivalLocation?.address) {
      hasError = true
      reactHookForm.setError('location', {
        message: t('errors.required') as string,
      })
    }

    if (
      !formValues.arrivalLocation?.street ||
      !formValues.arrivalLocation?.city ||
      !formValues.arrivalLocation?.district
    ) {
      hasError = true
      reactHookForm.setError(sameAddress ? 'address' : 'arrivalAddress', {
        message: t('errors.required') as string,
      })
    }
    if (
      sameAddress === false &&
      (!formValues.departureLocation?.street ||
        !formValues.departureLocation?.city ||
        !formValues.departureLocation?.district)
    ) {
      hasError = true
      reactHookForm.setError('departureAddress', {
        message: t('errors.required') as string,
      })
    }

    if (formValues.typeOfTransport === undefined) {
      hasError = true
      reactHookForm.setError('typeOfTransport', {
        message: t('errors.chooseAnOption') as string,
      })
    }
    if (formValues.transfer === undefined) {
      hasError = true
      reactHookForm.setError('transfer', {
        message: t('errors.chooseAnOption') as string,
      })
    }

    if (
      formValues.transferType === 'airport' ||
      formValues.transferType === 'puntaCana'
    ) {
      if (sameAddress && formValues.address === undefined) {
        hasError = true
        reactHookForm.setError('address', {
          message: t('errors.required') as string,
        })
      }
    }

    if (formValues.adults === undefined) {
      hasError = true
      reactHookForm.setError('adults', {
        message: t('errors.required') as string,
      })
    }

    if (
      formValues.transferType === 'skiStation' &&
      formValues.skiStation === undefined
    ) {
      hasError = true
      reactHookForm.setError('skiStation', {
        message: t('errors.required') as string,
      })
    }

    if (formValues.transferType === 'coast' && formValues.coast === undefined) {
      hasError = true
      reactHookForm.setError('coast', {
        message: t('errors.required') as string,
      })
    }

    if (hasError) return

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'quotation',
      address: formValues.address,
      arrivalAddress: formValues.arrivalAddress,
      adults: formValues.adults,
      children: formValues.children,
      babies: formValues.babies,
    })

    setIsLoading(true)

    if (formValues.specialBag === undefined) {
      reactHookForm.setValue('specialBag', 0)
      formValues.specialBag = 0
    }

    if (formValues.children === undefined) {
      reactHookForm.setValue('children', 0)
      formValues.children = 0
    }

    if (formValues.babies === undefined) {
      reactHookForm.setValue('babies', 0)
      formValues.babies = 0
    }

    const formData = new FormData()

    const values = {
      tiporeserva: handleGetNumberTransfer[formValues.transfer],
      mesmoEndereco: sameAddress === false ? 0 : 1,
      item: {
        2: formValues.specialBag,
      },
      Reserva: [
        {
          endereco_destino: formValues.arrivalLocation?.street,
          localidade_destino: formValues.arrivalLocation?.street,
          bairro_destino: formValues.arrivalLocation?.district,
          cidade_destino: formValues.arrivalLocation?.city,
          qtd_adt: formValues.adults,
          qtd_chd_10: formValues.children,
          endereco_saida: formValues[typeTransferField]?.street,
          localidade_saida: formValues[typeTransferField]?.street,
          bairro_saida: formValues[typeTransferField]?.district,
          cidade_saida: formValues[typeTransferField]?.city,
          origem: formValues.origin || 1,
        },
      ],
    }

    reactHookForm.setValue('origin', formValues.origin || 1)

    formData.append('json', JSON.stringify(values))
    try {
      const responseDetailsQuote = await OldApiService.Quote.get(formData)
      if (!responseDetailsQuote) {
        return
      }
      let value = unMaskMonetary(
        getCalcQuote({
          ...responseDetailsQuote,
          adults: formValues.adults,
          children: formValues.children,
          sameAddress,
          typeReservation: handleGetNumberTransfer[
            formValues.transfer
          ] as ReservationTypes,
        }),
      )

      if (
        formValues.typeOfTransport &&
        Number(handleGetNumberTypeOfTransport[formValues.typeOfTransport]) ===
          2 &&
        reactHookForm.getValues('origin') === 1
      ) {
        const totalPaying =
          Number(formValues.adults) + Number(formValues.children)
        if (!(totalPaying >= 7 && totalPaying <= 9)) {
          value =
            value *
            (1 +
              Number(responseDetailsQuote.cotacao.Cotacao.taxa_exclusividade) /
                100)
        }
      }
      let specialBagValue = unMaskMonetary(
        responseDetailsQuote.consultaItens?.['2']?.ItensAdicional?.valor || 0,
      )
      if (formValues.transferType === 'puntaCana') {
        specialBagValue = roundUpperNumber(
          specialBagValue / Number(responseDetailsQuote.cotacao.Cotacao.dolar),
        )
      }

      const calcValueSpecialBag =
        specialBagValue * Number(formValues.specialBag) || 0

      const valueTripButSpecialBag = value + calcValueSpecialBag

      // let calcValueCreditCard =
      //   valueTripButSpecialBag *
      //   (1 + Number(responseDetailsQuote.cotacao.Cotacao.taxa_desconto) / 100)
      // if (formValues.transferType !== 'puntaCana') {
      //   calcValueCreditCard =
      //     calcValueCreditCard /
      //     Number(responseDetailsQuote.cotacao.Cotacao.dolar)
      // }

      let calcValueCreditCard =
        valueTripButSpecialBag *
        (1 + Number(responseDetailsQuote.cotacao.Cotacao.taxa_desconto) / 100)
      if (formValues.transferType !== 'puntaCana') {
        calcValueCreditCard =
          calcValueCreditCard /
          Number(responseDetailsQuote.cotacao.Cotacao.real)
      }

      let calcValuePix = valueTripButSpecialBag
      if (formValues.transferType !== 'puntaCana') {
        calcValuePix =
          calcValuePix / Number(responseDetailsQuote.cotacao.Cotacao.real)
      }

      if (responseDetailsQuote.consultaItens?.['2']?.ItensAdicional?.valor) {
        responseDetailsQuote.consultaItens['2'].ItensAdicional.valor =
          roundUpperNumber(specialBagValue).toString()
      }

      setQuoteData({
        ...responseDetailsQuote,
        valueTrip: value,
        valueCreditCard: calcValueCreditCard,
        valuePix: calcValuePix,
        valueSpecialBag: calcValueSpecialBag,
        date: new Date().getTime(),
      })
      setCurrentStep(Steps.DETAILS)
      if (responseDetailsQuote.parceiros) {
        const partnersArray = Object.entries(responseDetailsQuote.parceiros)
        setPartners(
          partnersArray
            .sort(([, currentPartner], [, nextPartner]) => {
              return currentPartner.localeCompare(nextPartner)
            })
            .map(([partnerKey, partnerValue]) => ({
              label: partnerValue,
              value: partnerKey,
            })),
        )
      }
    } catch (error) {
      toast.error(t('errors.generic'))
    }
    setIsLoading(false)
  }, [getCalcQuote, reactHookForm, sameAddress, t, transferType])

  const onModalClose = useCallback(() => {
    setCurrentStep(Steps.PERSONAL_INFORMATIONS)
  }, [])
  const handleGetAvailable = {
    [TRANSFER.ROUNDTRIP]: async (
      props: Omit<CheckAvailableParams, 'transfer'>,
    ) => {
      const hasAvailable = await checkAvailable({
        ...props,
        field: 'arrival',
      })
      if (!hasAvailable) return
      const hasAvailableDeparture = await checkAvailable({
        ...props,
        field: 'departure',
      })
      if (hasAvailableDeparture && hasAvailable) {
        setCurrentStep(Steps.CONTACT_DETAILS)
      }
    },
    [TRANSFER.ONE_WAY_TRIP]: async (
      props: Omit<CheckAvailableParams, 'transfer'>,
    ) => {
      const hasAvailable = await checkAvailable({ ...props })
      if (!hasAvailable) return
      setCurrentStep(Steps.CONTACT_DETAILS)
    },
    [TRANSFER.RETURN_TRIP]: async (
      props: Omit<CheckAvailableParams, 'transfer'>,
    ) => {
      const hasAvailable = await checkAvailable({ ...props })
      if (!hasAvailable) return
      setCurrentStep(Steps.CONTACT_DETAILS)
    },
  }

  const checkAvailable = useCallback(
    async ({
      field,
      today,
      tomorrow,
      hour,
      formValues,
    }: CheckAvailableParams) => {
      const date = dayjs(
        formValues[(field + 'Date') as keyof QuotationApiRequest] as string,
        MASK.DATE.LOCALE,
      )
      const time =
        formValues[(field + 'FlightTime') as keyof QuotationApiRequest]
      if (
        date.isSame(dayjs(today, MASK.DATE.ISO), 'day') ||
        (date.isSame(dayjs(tomorrow, MASK.DATE.ISO), 'day') &&
          Number(hour) >= 18)
      ) {
        setShowUnavailableReservation(true)
        setErrorMessageNotAvailable(
          t('quotation.contactWhatsappToSchedule') as string,
        )
        return false
      }
      setIsLoading(true)
      const formData = new FormData()
      formData.append('data_transfer', date.format(MASK.DATE.ISO))
      formData.append('hora_transfer', time?.toString() as string)
      try {
        const disponibilidade = await OldApiService.Reservation.checkAvailable(
          formData,
        )
        if (disponibilidade) {
          if (
            dayjs(formValues.arrivalDate, MASK.DATE.LOCALE).isSame(
              dayjs(
                disponibilidade.Indisponibilidade.data_inicio,
                MASK.DATE.LOCALE,
              ),
            )
          ) {
            setErrorMessageNotAvailableArrivalDate(
              `<p>Chegada: <strong style="margin-left: 5px;">${dayjs(
                formValues.arrivalDate,
                MASK.DATE.LOCALE,
              ).format(MASK.DATE.LOCALE)}</strong></p>`,
            )
          }
          if (
            dayjs(formValues.departureDate, MASK.DATE.LOCALE).isSame(
              dayjs(
                disponibilidade.Indisponibilidade.data_inicio,
                MASK.DATE.LOCALE,
              ),
            )
          ) {
            setErrorMessageNotAvailableDepartureDate(
              `<p>Partida: <strong style="margin-left: 5px;">${dayjs(
                formValues.departureDate,
                MASK.DATE.LOCALE,
              ).format(MASK.DATE.LOCALE)}</strong></p>`,
            )
          }
          setErrorMessageNotAvailable(
            disponibilidade.Indisponibilidade.mensagem_cliente,
          )
          setShowUnavailableReservation(true)
          setIsLoading(false)
          return false
        }
        return true
      } catch (error) {
        setIsLoading(false)
        return true
      }
    },
    [t],
  )

  const handlePayment = useCallback(() => {
    const formValues = reactHookForm.getValues()

    if (formValues.paymentForm === 'pix') {
      const splitedName = formValues.name.split(' ')
      const linkWhatsapp = generateLinkWhatsapp({
        message: `Olá, me chamo ${splitedName[0]} ${
          splitedName[splitedName.length - 1]
        }, quero efetuar o pagamento em PIX da minha reserva Nº ${
          reservationData?.reserva.id
        }`,
        number: '+56968273783',
      })
      window.open(linkWhatsapp, '_blank')
      return
    }

    if (!reservationData || !reservationData.paypal_url) return
    window.open(reservationData.paypal_url, '_blank')
  }, [reactHookForm, reservationData])

  return (
    <>
      <FormProvider {...reactHookForm}>
        <S.Container id="quotation">
          <S.Title>{t('quotation.makeYourQuote')}</S.Title>
          <S.SubTitle>{t('quotation.chooseYourDestiny')}</S.SubTitle>

          <Form.Item
            style={{ marginBottom: 0 }}
            help={reactHookForm.formState.errors.typeOfTransport?.message}
            validateStatus={
              reactHookForm.formState.errors.typeOfTransport?.message
                ? 'error'
                : ''
            }
          >
            <S.Row style={{ gap: '24px' }}>
              <S.Row style={{ gap: '5px' }}>
                <Radio
                  checked={choiceDestiny === DESTINY.CHILE}
                  onClick={() => {
                    setChoiceDestiny(DESTINY.CHILE)
                    setSegmentedValues([
                      {
                        label: t('quotation.airport'),
                        value: 'airport',
                      },
                      { label: t('quotation.skiStation'), value: 'skiStation' },
                      { label: t('quotation.coast'), value: 'coast' },
                    ])
                    reactHookForm.setValue('transferType', 'airport')
                    reactHookForm.setValue('address', undefined)
                    reactHookForm.setValue('origin', undefined)
                    setCurrentTransferType('airport')
                    reactHookForm.setValue('typeOfTransport', undefined)
                  }}
                />

                <S.Column style={{ gap: '0' }}>
                  <S.TabItemContainer>
                    <img
                      src={chile}
                      alt="chile"
                      id="chile"
                      height={32}
                      width="auto"
                    />
                    <span>Chile</span>
                  </S.TabItemContainer>
                </S.Column>
              </S.Row>

              <S.Row style={{ gap: '5px' }}>
                <Radio
                  checked={choiceDestiny === DESTINY.PUNTA_CANA}
                  onClick={() => {
                    setChoiceDestiny(DESTINY.PUNTA_CANA)
                    setSegmentedValues([
                      { label: t('quotation.puntaCana'), value: 'puntaCana' },
                      { label: t('quotation.tour'), value: 'tour' },
                    ])
                    reactHookForm.setValue('transferType', 'puntaCana')
                    reactHookForm.setValue('address', undefined)
                    reactHookForm.setValue('origin', undefined)
                    setCurrentTransferType('puntaCana')
                    reactHookForm.setValue('typeOfTransport', 'private')
                    reactHookForm.setError('typeOfTransport', {})
                  }}
                />

                <S.Column style={{ gap: '0' }}>
                  <S.TabItemContainer>
                    <img
                      src={dominicanRepublic}
                      alt="dominican-republic"
                      id="dominican-republic"
                      height={32}
                      width="auto"
                    />
                    <span>Republica Dominicana</span>
                  </S.TabItemContainer>
                </S.Column>
              </S.Row>
            </S.Row>
          </Form.Item>

          <Segmented
            options={segmentedValues}
            value={transferType}
            block
            style={{
              maxWidth: choiceDestiny === DESTINY.CHILE ? 'initial' : '350px',
            }}
            onChange={(value) => {
              if (value === 'tour') {
                window.open(ENDPOINTS.TOUR_LINK.ROOT, '_blank')
                return
              }
              reactHookForm.setValue('transferType', value as TransferType)
              reactHookForm.setValue('address', undefined)
              reactHookForm.setValue('origin', undefined)
              setCurrentTransferType(value as TransferType)
              if (value === 'puntaCana') {
                reactHookForm.setValue('typeOfTransport', 'private')
                reactHookForm.setError('typeOfTransport', {})
              }
              if (value !== 'puntaCana') {
                reactHookForm.setValue('typeOfTransport', undefined)
              }
            }}
          />

          <S.Transfer>{t('quotation.transportType')}</S.Transfer>

          <Form.Item
            style={{ marginBottom: 0 }}
            help={reactHookForm.formState.errors.typeOfTransport?.message}
            validateStatus={
              reactHookForm.formState.errors.typeOfTransport?.message
                ? 'error'
                : ''
            }
          >
            <S.Row style={{ gap: '24px' }}>
              {transferType !== 'puntaCana' && (
                <S.Row style={{ gap: '5px' }}>
                  <Radio
                    checked={typeOfTransportFormValue === 'semiShared'}
                    onClick={() => {
                      reactHookForm.setValue('typeOfTransport', 'semiShared')
                      reactHookForm.setError('typeOfTransport', {})
                    }}
                  />

                  <S.Column style={{ gap: '0' }}>
                    <S.Label>{t('quotation.semiShared')}</S.Label>
                  </S.Column>

                  <Tooltip
                    color="white"
                    autoAdjustOverflow
                    placement="bottom"
                    overlayStyle={{ minWidth: '460px' }}
                    title={
                      <S.Column style={{ minWidth: '460px', padding: '30px' }}>
                        <S.Title>{t('quotation.semiShared')}</S.Title>

                        <S.Row>
                          <TextWithLabel
                            label=""
                            value={
                              t('quotation.semiSharedTooltipText') as string
                            }
                          />
                        </S.Row>

                        <S.HorizontalLine />

                        <S.Row>
                          <S.Label>
                            {t('quotation.baggageAllowancePerPassenger')}
                          </S.Label>
                        </S.Row>

                        <S.Row>
                          <S.Column style={{ gap: 0, alignItems: 'center' }}>
                            <img
                              src={bodegaSuitcase}
                              alt={t('quotation.bodegaSuitcase') as string}
                              style={{
                                width: '29px',
                                height: '37px',
                              }}
                            />
                            <S.Label>{t('quotation.bodegaSuitcase')}</S.Label>
                            <S.Label>1</S.Label>
                          </S.Column>

                          <S.Column style={{ gap: 0, alignItems: 'center' }}>
                            <img
                              src={handbag}
                              alt={t('quotation.bodegaSuitcase') as string}
                              style={{
                                width: '29px',
                                height: '37px',
                              }}
                            />
                            <S.Label>{t('quotation.handbag')}</S.Label>
                            <S.Label>1</S.Label>
                          </S.Column>

                          <S.Column style={{ gap: 0, alignItems: 'center' }}>
                            <img
                              src={personalArticle}
                              alt={t('quotation.bodegaSuitcase') as string}
                              style={{
                                width: '29px',
                                height: '37px',
                              }}
                            />
                            <S.Label>{t('quotation.personalArticle')}</S.Label>
                            <S.Label>1</S.Label>
                          </S.Column>
                        </S.Row>
                      </S.Column>
                    }
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                </S.Row>
              )}

              <S.Row style={{ gap: '5px' }}>
                <Radio
                  checked={typeOfTransportFormValue === 'private'}
                  onClick={() => {
                    reactHookForm.setValue('typeOfTransport', 'private')
                    reactHookForm.setError('typeOfTransport', {})
                  }}
                />

                <S.Column style={{ gap: '0' }}>
                  <S.Label>{t('quotation.private')}</S.Label>
                </S.Column>

                <Tooltip
                  color="white"
                  autoAdjustOverflow
                  placement="bottom"
                  overlayStyle={{ minWidth: '460px' }}
                  title={
                    <S.Column style={{ minWidth: '460px', padding: '30px' }}>
                      <S.Title>{t('quotation.private')}</S.Title>

                      <S.Row>
                        <TextWithLabel
                          label=""
                          value={t('quotation.privateTooltipText') as string}
                        />
                      </S.Row>

                      <S.HorizontalLine />

                      <S.Row>
                        <S.Label>
                          {t('quotation.baggageAllowancePerPassenger')}
                        </S.Label>
                      </S.Row>

                      <S.Row>
                        <S.Column style={{ gap: 0, alignItems: 'center' }}>
                          <img
                            src={bodegaSuitcase}
                            alt={t('quotation.bodegaSuitcase') as string}
                            style={{
                              width: '29px',
                              height: '37px',
                            }}
                          />
                          <S.Label>{t('quotation.bodegaSuitcase')}</S.Label>
                          <S.Label>6</S.Label>
                        </S.Column>

                        <S.Column style={{ gap: 0, alignItems: 'center' }}>
                          <img
                            src={handbag}
                            alt={t('quotation.bodegaSuitcase') as string}
                            style={{
                              width: '29px',
                              height: '37px',
                            }}
                          />
                          <S.Label>{t('quotation.handbag')}</S.Label>
                          <S.Label>6</S.Label>
                        </S.Column>

                        <S.Column style={{ gap: 0, alignItems: 'center' }}>
                          <img
                            src={personalArticle}
                            alt={t('quotation.bodegaSuitcase') as string}
                            style={{
                              width: '29px',
                              height: '37px',
                            }}
                          />
                          <S.Label>{t('quotation.personalArticle')}</S.Label>
                          <S.Label>6</S.Label>
                        </S.Column>
                      </S.Row>
                    </S.Column>
                  }
                >
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              </S.Row>
            </S.Row>
          </Form.Item>

          <S.Transfer>{t('quotation.transfer')}</S.Transfer>

          <Form.Item
            style={{ marginBottom: 0 }}
            help={reactHookForm.formState.errors.transfer?.message}
            validateStatus={
              reactHookForm.formState.errors.transfer?.message ? 'error' : ''
            }
          >
            <Radio.Group
              value={transfer}
              style={{ display: 'flex' }}
              onChange={(event) => {
                setSameAddress(undefined)

                const value = event.target.value as Transfer

                if (
                  value === 'roundtrip' &&
                  SHOW_MODAL_SAME_ADDRESS.includes(
                    reactHookForm.getValues('transferType'),
                  )
                ) {
                  setShowSameAddressModal(true)
                }

                reactHookForm.setValue('transfer', value)
                reactHookForm.setError('transfer', {})
                setTransfer(value as TRANSFER)
              }}
            >
              <Radio
                value={TRANSFER.ROUNDTRIP}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {t('quotation.roundTrip')}
              </Radio>

              <Radio
                value={TRANSFER.ONE_WAY_TRIP}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {t('quotation.oneWay')}
              </Radio>

              <Radio
                value={TRANSFER.RETURN_TRIP}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {t('quotation.returnTrip')}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Address
            transferType={transferType}
            sameAddress={sameAddress}
            transfer={transfer}
          />

          <Spacing size={18} />

          <Row justify="space-between">
            <Col span={4} style={{ minWidth: '90px' }}>
              <InputField label={t('quotation.adults')} fullWidth>
                <Controller
                  name="adults"
                  control={reactHookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <InputNumber
                      min={0}
                      max={29}
                      controls
                      onChange={onChange}
                      value={value}
                      status={
                        reactHookForm.formState.errors.adults?.message
                          ? 'error'
                          : ''
                      }
                    />
                  )}
                />
              </InputField>
            </Col>

            <Col span={4} style={{ minWidth: '120px' }}>
              <InputField
                label={
                  <>
                    <Typography.Text>{t('quotation.children')}</Typography.Text>

                    <Typography.Text style={{ fontSize: '10px' }}>
                      {t('quotation.fiveToNineYears')}
                    </Typography.Text>
                  </>
                }
              >
                <Controller
                  name="children"
                  control={reactHookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <InputNumber
                      min={0}
                      max={29}
                      controls
                      onChange={onChange}
                      value={value}
                      style={{ width: '120px' }}
                      status={
                        reactHookForm.formState.errors.children?.message
                          ? 'error'
                          : ''
                      }
                    />
                  )}
                />
              </InputField>
            </Col>

            <Col span={4} style={{ minWidth: '105px' }}>
              <InputField
                label={
                  <>
                    <Typography.Text>{t('quotation.babies')}</Typography.Text>

                    <Typography.Text style={{ fontSize: '10px' }}>
                      {t('quotation.zeroToFourYears')}
                    </Typography.Text>
                  </>
                }
              >
                <Controller
                  name="babies"
                  control={reactHookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <InputNumber
                      min={0}
                      max={29}
                      controls
                      onChange={onChange}
                      value={value}
                      style={{ width: '105px' }}
                      status={
                        reactHookForm.formState.errors.babies?.message
                          ? 'error'
                          : ''
                      }
                    />
                  )}
                />
              </InputField>
            </Col>
          </Row>

          <Spacing size={18} />

          {transferType !== 'puntaCana' && (
            <S.Row>
              <Col span={24}>
                <InputField
                  label={
                    <Tooltip title={t('quotation.specialBagDescription')}>
                      <Typography.Text>
                        {t('quotation.specialBag')}
                      </Typography.Text>
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                >
                  <Controller
                    name="specialBag"
                    control={reactHookForm.control}
                    render={({ field: { value, onChange } }) => (
                      <InputNumber
                        min={0}
                        max={5}
                        controls
                        onChange={onChange}
                        value={value}
                        style={{ width: '135px' }}
                        status={
                          reactHookForm.formState.errors.specialBag?.message
                            ? 'error'
                            : ''
                        }
                      />
                    )}
                  />
                </InputField>
              </Col>
            </S.Row>
          )}

          <Spacing size={26} />

          <S.Row>
            <Col span={24}>
              <Button
                type="primary"
                shape="round"
                style={{ width: '100%' }}
                onClick={onConfirm}
                loading={isLoadingValidateAddress}
                disabled={!reactHookForm.getValues('arrivalLocation')}
              >
                {t('quotation.quotation')}
              </Button>
            </Col>
          </S.Row>
        </S.Container>

        <Details
          visible={currentStep === Steps.DETAILS}
          sameAddress={sameAddress}
          onClose={onModalClose}
          onPreviousClick={() => setCurrentStep(Steps.PERSONAL_INFORMATIONS)}
          onNextClick={() => {
            const formValues = reactHookForm.getValues()
            if (formValues.paymentForm === undefined) {
              reactHookForm.setError('paymentForm', {
                message: t('errors.chooseAnOptionPaymentForm') as string,
              })
              return
            }
            setCurrentStep(Steps.FLIGHT_DETAILS)
          }}
          quoteData={quoteData}
        />

        <FlightDetails
          visible={currentStep === Steps.FLIGHT_DETAILS}
          onPreviousClick={() => setCurrentStep(Steps.DETAILS)}
          onClose={onModalClose}
          onNextClick={async () => {
            reactHookForm.clearErrors()
            const formValues = reactHookForm.watch()
            let hasError = false

            if (
              formValues.transfer === 'roundtrip' ||
              formValues.transfer === 'oneWayTrip'
            ) {
              if (!formValues.arrivalFlightType) {
                hasError = true

                reactHookForm.setError('arrivalFlightType', {
                  message: t('errors.required') as string,
                })
              }
              if (!formValues.arrivalDate) {
                hasError = true

                reactHookForm.setError('arrivalDate', {
                  message: t('errors.chooseAnOption') as string,
                })
              }

              if (!formValues.arrivalFlightTime) {
                hasError = true

                reactHookForm.setError('arrivalFlightTime', {
                  message: t('errors.required') as string,
                })
              }

              if (!formValues.arrivalFlightTime) {
                hasError = true

                reactHookForm.setError('arrivalFlightTime', {
                  message: t('errors.required') as string,
                })
              }

              if (!formValues.arrivalFlightNumber) {
                hasError = true

                reactHookForm.setError('arrivalFlightNumber', {
                  message: t('errors.required') as string,
                })
              }
            }

            if (
              formValues.transfer === 'roundtrip' ||
              formValues.transfer === 'returnTrip'
            ) {
              if (!formValues.departureFlightType) {
                hasError = true

                reactHookForm.setError('departureFlightType', {
                  message: t('errors.required') as string,
                })
              }
              if (!formValues.departureDate) {
                hasError = true

                reactHookForm.setError('departureDate', {
                  message: t('errors.required') as string,
                })
              }

              if (!formValues.departureFlightTime) {
                hasError = true

                reactHookForm.setError('departureFlightTime', {
                  message: t('errors.required') as string,
                })
              }
              if (
                dayjs(
                  `${dayjs(formValues.departureDate).format(
                    MASK.DATE.LOCALE,
                  )} ${formValues.departureFlightTime}`,
                  `${MASK.DATE.LOCALE} ${MASK.DATE.TIME}`,
                ).isBefore(
                  dayjs(
                    `${dayjs(formValues.arrivalDate).format(
                      MASK.DATE.LOCALE,
                    )} ${formValues.arrivalFlightTime}`,
                    `${MASK.DATE.LOCALE} ${MASK.DATE.TIME}`,
                  ),
                )
              ) {
                hasError = true

                reactHookForm.setError('departureDate', {
                  message: t('errors.departureLessArrivalDate') as string,
                })
                reactHookForm.setError('departureFlightTime', {
                  message: t('errors.departureLessArrivalDate') as string,
                })
              }
              if (
                dayjs(
                  `${dayjs(formValues.departureDate).format(
                    MASK.DATE.LOCALE,
                  )} ${formValues.departureFlightTime}`,
                  `${MASK.DATE.LOCALE} ${MASK.DATE.TIME}`,
                ).isSame(
                  dayjs(
                    `${dayjs(formValues.arrivalDate).format(
                      MASK.DATE.LOCALE,
                    )} ${formValues.arrivalFlightTime}`,
                    `${MASK.DATE.LOCALE} ${MASK.DATE.TIME}`,
                  ),
                )
              ) {
                hasError = true

                reactHookForm.setError('departureDate', {
                  message: t('errors.departureIsSamaArrivalDate') as string,
                })
                reactHookForm.setError('departureFlightTime', {
                  message: t('errors.departureIsSamaArrivalDate') as string,
                })
                reactHookForm.setError('arrivalDate', {
                  message: t('errors.departureIsSamaArrivalDate') as string,
                })
                reactHookForm.setError('arrivalFlightTime', {
                  message: t('errors.departureIsSamaArrivalDate') as string,
                })
              }

              if (!formValues.departureFlightNumber) {
                hasError = true

                reactHookForm.setError('departureFlightNumber', {
                  message: t('errors.required') as string,
                })
              }
            }

            if (hasError) return

            setIsLoading(true)
            const today = dayjs().format(MASK.DATE.ISO)
            const tomorrow = dayjs().add(1, 'd').format(MASK.DATE.ISO)
            const hour = dayjs().format('HH')

            await handleGetAvailable[formValues.transfer]?.({
              field:
                formValues.transfer === 'oneWayTrip' ? 'arrival' : 'departure',
              formValues,
              hour,
              today,
              tomorrow,
            })
            setIsLoading(false)
          }}
        />

        <ContactDetails
          visible={currentStep === Steps.CONTACT_DETAILS}
          onPreviousClick={() => setCurrentStep(Steps.FLIGHT_DETAILS)}
          onClose={onModalClose}
          onNextClick={() => {
            reactHookForm.clearErrors()
            const formValues = reactHookForm.watch()
            let hasError = false

            if (!formValues.name) {
              hasError = true

              reactHookForm.setError('name', {
                message: t('errors.required') as string,
              })
            }

            if (!formValues.document) {
              hasError = true

              reactHookForm.setError('document', {
                message: t('errors.required') as string,
              })
            }

            if (!formValues.cellPhone) {
              hasError = true

              reactHookForm.setError('cellPhone', {
                message: t('errors.required') as string,
              })
            }

            if (!formValues.email) {
              hasError = true

              reactHookForm.setError('email', {
                message: t('errors.required') as string,
              })
            }

            if (hasError) return

            setCurrentStep(Steps.RESUME)
          }}
          partners={partners}
        />

        <Resume
          visible={currentStep === Steps.RESUME}
          onClose={onModalClose}
          onNextClick={handleCreateReservation}
          quoteData={quoteData}
          onPreviousClick={() => setCurrentStep(Steps.CONTACT_DETAILS)}
        />

        {currentStep === Steps.PAYMENT && (
          <Payment
            onClose={() => {
              reactHookForm.reset()
              reactHookForm.setValue('origin', undefined)
              reactHookForm.setValue('region', '')
              reactHookForm.setValue('location', '')
              reactHookForm.setValue('arrivalLocation', {
                city: '',
                district: '',
                geo_code: '',
                address: '',
                location: '',
                street: '',
              })
              reactHookForm.setValue('departureLocation', {
                city: '',
                district: '',
                geo_code: '',
                address: '',
                location: '',
                street: '',
              })
              setTransfer(undefined)
              onModalClose()
            }}
            visible={currentStep === Steps.PAYMENT}
            onPayment={handlePayment}
            reservation={reservationData}
            quoteData={quoteData}
          />
        )}

        <Modal
          visible={showSameAddressModal}
          onClose={() => setShowSameAddressModal(false)}
        >
          <S.Column style={{ maxWidth: '670px' }}>
            <S.Title>{t('quotation.confirmation')}</S.Title>

            <S.Row>
              <S.SubTitle>{t('quotation.sameAddress')}</S.SubTitle>
            </S.Row>

            <S.Row style={{ alignSelf: 'flex-end' }}>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  setSameAddress(false)
                  setShowSameAddressModal(false)
                }}
              >
                {t('quotation.no')}
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setSameAddress(true)
                  setShowSameAddressModal(false)
                }}
              >
                {t('quotation.yes')}
              </Button>
            </S.Row>
          </S.Column>
        </Modal>

        <Modal
          visible={showUnavailableReservation}
          onClose={() => setShowUnavailableReservation(false)}
        >
          <S.Column style={{ maxWidth: '670px' }}>
            <S.Title>{t('quotation.unavailableDate')}</S.Title>
            {errorMessageNotAvailableArrivalDate && (
              <S.Row>
                <S.SubTitle
                  dangerouslySetInnerHTML={{
                    __html: errorMessageNotAvailableArrivalDate,
                  }}
                />
              </S.Row>
            )}
            {errorMessageNotAvailableDepartureDate && (
              <S.Row>
                <S.SubTitle
                  dangerouslySetInnerHTML={{
                    __html: errorMessageNotAvailableDepartureDate,
                  }}
                />
              </S.Row>
            )}
            <S.Row style={{ flexDirection: 'column' }}>
              <TextWithLabel
                label={t('quotation.description')}
                value={errorMessageNotAvailable}
              />
              <S.SubTitle>
                {!!errorMessageNotAvailable.includes(
                  t('quotation.contactWhatsappToSchedule'),
                ) && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        window.open('https://wa.me/56941016076', '_blank')
                      }
                    >
                      <Whatsapp fill="#1677ff" />
                      <span style={{ color: '#1677ff' }}>+56 9 6827 3783</span>
                    </div>
                  </>
                )}
              </S.SubTitle>
            </S.Row>
            <S.Row style={{ alignSelf: 'flex-end' }}>
              <Button
                type="primary"
                style={{ padding: '0.5rem 1rem', height: 'unset' }}
                onClick={() => {
                  setShowUnavailableReservation(false)
                }}
              >
                {t('quotation.ok').toUpperCase()}
              </Button>
            </S.Row>
          </S.Column>
        </Modal>
      </FormProvider>
      {!!isLoading && <LoadingSpin />}
    </>
  )
}
