import ENDPOINTS from 'constants/endpoints'

import apiInstance from './axiosInstance'

export const send = async (
  data: ContactApiRequest,
): Promise<ContactApiResponse> => {
  try {
    const sendResponse = await apiInstance.post<ContactApiResponse>(
      ENDPOINTS.CONTACT,
      data,
    )

    return sendResponse.data
  } catch (sendError) {
    return Promise.reject()
  }
}
