import ROUTES from 'constants/router'
import Home from 'pages/home'
import PrivacyPolicy from 'pages/useful-links/privacy-policy'
import TermsConditions from 'pages/useful-links/terms-conditions'
import { createBrowserRouter } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <Home />,
  },

  {
    path: ROUTES.TERMS_CONDITIONS,
    element: <TermsConditions />,
  },

  {
    path: ROUTES.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
])

export default router
