import LANGUAGES from 'constants/languages'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'
import es from './es'
import pt from './pt'

i18n.use(initReactI18next).init({
  resources: {
    [LANGUAGES.pt]: { translation: pt },
    [LANGUAGES.es]: { translation: es },
    [LANGUAGES.en]: { translation: en },
  },
  lng: LANGUAGES.pt,
  fallbackLng: LANGUAGES.pt,
  interpolation: {
    escapeValue: false,
  },
})
