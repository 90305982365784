export const HANDLE_SET_ADDRESS_SKI_STATION: Record<string, AddressRequest> = {
  elColorado: {
    address: 'El Colorado',
    district: 'Lo Barnechea',
    city: 'Santiago',
    geo_code: '-33.348702195783055, -70.2913957433722',
  },
  farellones: {
    address: 'Farellones, Lo Barnechea, Chile',
    district: 'Lo Barnechea',
    city: 'Santiago',
    geo_code: '-33.32846308982682, -70.24529198117912',
  },
  laParva: {
    address: 'La Parva, Lo Barnechea, Chile',
    district: 'Lo Barnechea',
    city: 'Santiago',
    geo_code: '-33.33649649062798, -70.28923268225597',
  },
  valleNevado: {
    address: 'Valle Nevado Ski Resort parking, Lo Barnechea, Chile',
    district: 'Lo Barnechea',
    city: 'Santiago Province',
    geo_code: '-33.356643498001944, -70.25220407078992',
  },
}
export const ADDRESS_SKI_STATION_OPTIONS = Object.entries(
  HANDLE_SET_ADDRESS_SKI_STATION,
).map(([key, value]) => ({ label: key, value: value.address || '' }))

export const HANDLE_SET_ADDRESS_COAST: Record<string, AddressRequest> = {
  algarrobo: {
    address: 'Algarrobo',
    district: 'Viña del Mar',
    city: 'Valparaiso',
    geo_code: '-33.01065793001922, -71.49613318189148',
  },
  valparaiso: {
    address: 'Valparaíso, Chile',
    district: 'Valparaíso',
    city: 'Valparaíso',
    geo_code: '-33.047033221803126, -71.6125078413113',
  },
  // vinaDelMar: '',
  sanAntonio: {
    address: 'San Antonio, Chile',
    district: 'San Antonio',
    city: 'San Antonio',
    geo_code: '-33.595810653706025, -71.60735720700673',
  },
}

export const HANDLE_SET_ADDRESS_PUNTA_CANA: Record<string, AddressRequest> = {
  bávaro: {
    address: 'Bávaro, Punta Cana, República Dominicana',
    district: 'Bávaro',
    city: 'Punta Cana',
    geo_code: '18.68676716506575, -68.43880787197254',
  },
  uvero_alto: {
    address: 'Uvero Alto, Punta Cana, República Dominicana',
    district: 'Uvero Alto',
    city: 'Punta Cana',
    geo_code: '18.809018206585687, -68.58301183348014',
  },
  bayahibe: {
    address: 'Bayahíbe, República Dominicana',
    district: 'Bayahíbe',
    city: 'San Rafael del Yuma',
    geo_code: '18.367083221675536, -68.83393370504562',
  },
  la_romana: {
    address: 'La Romana, República Dominicana',
    district: 'La Romana',
    city: 'La Romana',
    geo_code: '18.433519428365788, -68.96908799788042',
  },
  macao: {
    address: 'Macao, Punta Cana, República Dominicana',
    district: 'Macao',
    city: 'Punta Cana',
    geo_code: '18.76050595201886, -68.53031184951065',
  },
  arena_gorda: {
    address: 'Arena Gorda Beach, Punta Cana, República Dominicana',
    district: 'Arena Gorda',
    city: 'Punta Cana',
    geo_code: '18.727440738341443, -68.45947441713182',
  },
  el_cortecito: {
    address: 'El Cortecito, Punta Cana, República Dominicana',
    district: 'Cortecito',
    city: 'Punta Cana',
    geo_code: '18.685174927920745, -68.42452100167932',
  },
  cabeza_de_toro: {
    address: 'Cabeza De Toro, Punta Cana, República Dominicana',
    district: 'Cabeza de Toro',
    city: 'Higüey',
    geo_code: '18.644862515642096, -68.35749905970754',
  },
  cap_cana: {
    address: 'Cap Cana, República Dominicana',
    district: 'Cap Cana',
    city: 'Punta Cana',
    geo_code: '18.48217030883818, -68.40712331813108',
  },
}

export const ADDRESS_COAST_OPTIONS = Object.entries(
  HANDLE_SET_ADDRESS_COAST,
).map(([key, value]) => ({ label: key, value: value.address || '' }))

export const BLACK_LIST_WORD_ADDRESS = ['airport', 'aeroporto', 'aeropuerto']
