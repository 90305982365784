import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  background: #ffffff;
  margin-top: 10px;
  width: 100%;
  margin-left: -12px;
  z-index: 10;

  .ant-segmented {
    margin-top: 17px;
  }

  @media ${devicesMinWidth.tablet} {
    margin-left: 0;
  }

  @media ${devicesMinWidth.laptop} {
    box-shadow: 0px 4px 20px rgba(94, 118, 123, 0.38);
    border-radius: 20px;
    padding: 50px;
    min-height: 540px;
  }

  @media ${devicesMinWidth.laptopL} {
    max-width: 500px;
  }
`

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #181e4b;
`

export const SubTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
`

export const Transfer = styled(Title)`
  margin-top: 18px;
  font-size: 16px;
`

export const Step2Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: auto;

  @media ${devicesMinWidth.laptop} {
    flex-direction: row;
    height: auto;
  }
`

export const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 12px;
`

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;

  .custom-radio {
    .ant-radio .ant-radio-inner {
      border-color: #1677ff !important ;
    }
  }
`

export const VerticalLine = styled.div`
  border: 1px solid #f0f0f0;
  margin: 24px 0;

  @media ${devicesMinWidth.laptop} {
    height: auto;
    padding: 0;
  }
`

export const TabItemContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
`

export const HorizontalLine = styled.div`
  border: 1px solid #f0f0f0;
  width: 100%;
`

export const Label = styled.label`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`

export const Value = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #aaaaaa;
`
