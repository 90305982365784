import Modal, { ModalProps } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Faq({
  currentTab,
  ...props
}: Pick<ModalProps, 'visible' | 'onClose'> & { currentTab: string }) {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('faq.title')}</S.Title>

        <S.Text>{t('faq.question1')}</S.Text>
        <S.Text>{t('faq.answer1')}</S.Text>

        <S.Text>{t('faq.question2')}</S.Text>
        <S.Text>{t('faq.answer2')}</S.Text>

        <S.Text>{t('faq.question3')}</S.Text>
        <S.Text>{t(`faq.answer${currentTab}3`)}</S.Text>

        <S.Text>{t('faq.question4')}</S.Text>
        <S.Text>{t('faq.answer41')}</S.Text>
        {currentTab !== 'puntaCana' && (
          <S.Text style={{ marginLeft: 24 }}>{t('faq.answer411')}</S.Text>
        )}
        <S.Text style={{ marginLeft: 24 }}>{t('faq.answer412')}</S.Text>
        <S.Text>{t('faq.answer42')}</S.Text>

        <S.Text>{t('faq.question5')}</S.Text>
        <S.Text>{t('faq.answer51')}</S.Text>
        <S.Text>{t('faq.answer52')}</S.Text>

        {currentTab === 'puntaCana' && (
          <>
            <S.Text>{t('faq.question6')}</S.Text>
            <S.Text>{t('faq.answer6')}</S.Text>
            <S.Text>{t('faq.question7')}</S.Text>
            <S.Text dangerouslySetInnerHTML={{ __html: t('faq.answer7') }} />
            <S.Text>{t('faq.question8')}</S.Text>
            <S.Text>{t('faq.answer8')}</S.Text>
            <S.Text>{t('faq.question9')}</S.Text>
            <S.Text>{t('faq.answer9')}</S.Text>
            <S.Text>{t('faq.question10')}</S.Text>
            <S.Text>{t('faq.answer10')}</S.Text>
            <S.Text>{t('faq.question11')}</S.Text>
            <S.Text>{t('faq.answer11')}</S.Text>
            <S.Text>{t('faq.question12')}</S.Text>
            <S.Text>{t('faq.answer12')}</S.Text>
          </>
        )}
      </S.Container>
    </Modal>
  )
}
