import { useCallback, useState } from 'react'

import Tabs from 'antd/lib/tabs'
import chile from 'assets/flags-brush/chile.png'
import colombia from 'assets/flags-brush/colombia.png'
import dominicanRepublic from 'assets/flags-brush/rd.png'
import links from 'assets/links.png'
import payment from 'assets/payments.png'
import questions from 'assets/questions.png'
import requirements from 'assets/requirements.png'
import reserve from 'assets/reserve.png'
import semiShared from 'assets/semi-shared.png'
import welcomeKit from 'assets/welcome-kit.png'
import ENDPOINTS from 'constants/endpoints'
import { useTranslation } from 'react-i18next'

import Faq from './Modals/faq'
import ImportantLinks from './Modals/Important-links'
import PaymentMethods from './Modals/payment-methods'
import Requirements from './Modals/reqirements'
import SemiShared from './Modals/semi-shared'
import WelcomeKit from './Modals/welcome-kit'
import * as S from './styles'

enum Modals {
  EMPTY,
  PAYMENT_METHODS,
  SEMI_SHARED,
  WELCOME_KIT,
  IMPORTANT_LINKS,
  REQUIREMENTS,
  FAQ,
}

enum TABS {
  CHILE = 'chile',
  PUNTA_CANA = 'puntaCana',
  COLOMBIA = 'colombia',
}

function TabContent({ currentTab }: { currentTab: TABS }) {
  const { t } = useTranslation()

  const [showModal, setModal] = useState<Modals>(Modals.EMPTY)

  const closeModal = useCallback(() => {
    setModal(Modals.EMPTY)
  }, [])

  return (
    <>
      <S.Container>
        <S.Item onClick={() => setModal(Modals.PAYMENT_METHODS)}>
          <img src={payment} alt="payments" id="payments" />
          <S.ItemLabel>{t('informations.payments')}</S.ItemLabel>
        </S.Item>

        {currentTab === TABS.CHILE && (
          <S.Item onClick={() => setModal(Modals.SEMI_SHARED)}>
            <S.ItemWithBox>
              <img src={semiShared} alt="semi-shared" id="semi-shared" />
              <S.ItemLabel>{t('informations.semiShared')}</S.ItemLabel>
            </S.ItemWithBox>
          </S.Item>
        )}

        {currentTab === TABS.CHILE && (
          <S.Item onClick={() => setModal(Modals.WELCOME_KIT)}>
            <img src={welcomeKit} alt="welcome-kit" id="welcome-kit" />
            <S.ItemLabel>{t('informations.welcomeKit')}</S.ItemLabel>
          </S.Item>
        )}
        {currentTab === TABS.CHILE && (
          <S.Item
            onClick={() => {
              // TODO: get reserve video
            }}
          >
            <img src={reserve} alt="reserve" id="reserve" />
            <S.ItemLabel>{t('informations.reserve')}</S.ItemLabel>
          </S.Item>
        )}

        <S.Item onClick={() => setModal(Modals.REQUIREMENTS)}>
          <img src={requirements} alt="requirements" id="requirements" />
          <S.ItemLabel>
            {t(`informations.${currentTab}Requirements`)}
          </S.ItemLabel>
        </S.Item>

        <S.Item onClick={() => setModal(Modals.FAQ)}>
          <img src={questions} alt="questions" id="questions" />
          <S.ItemLabel>{t('informations.questions')}</S.ItemLabel>
        </S.Item>

        <S.Item onClick={() => setModal(Modals.IMPORTANT_LINKS)}>
          <img src={links} alt="links" id="links" />
          <S.ItemLabel>{t('informations.links')}</S.ItemLabel>
        </S.Item>
      </S.Container>

      <PaymentMethods
        visible={showModal === Modals.PAYMENT_METHODS}
        onClose={closeModal}
        currentTab={currentTab}
      />

      <SemiShared
        visible={showModal === Modals.SEMI_SHARED}
        onClose={closeModal}
      />

      <ImportantLinks
        visible={showModal === Modals.IMPORTANT_LINKS}
        onClose={closeModal}
        currentTab={currentTab}
      />
      <WelcomeKit
        visible={showModal === Modals.WELCOME_KIT}
        onClose={closeModal}
        currentTab={currentTab}
      />

      <Faq
        visible={showModal === Modals.FAQ}
        onClose={closeModal}
        currentTab={currentTab}
      />
      <Requirements
        visible={showModal === Modals.REQUIREMENTS}
        onClose={closeModal}
        currentTab={currentTab}
      />
    </>
  )
}

export default function Informations() {
  const { t } = useTranslation()

  const tab = document.querySelector<HTMLElement>('.ant-tabs-tab-disabled')
  if (tab) {
    tab.style.cursor = 'pointer'
  }

  return (
    <>
      <S.Title>{t('informations.title')}</S.Title>

      <Tabs
        style={{ width: '100%' }}
        centered={true}
        defaultValue={TABS.CHILE}
        items={[
          {
            key: TABS.CHILE,
            label: (
              <S.TabItemContainer>
                <img
                  src={chile}
                  alt="chile"
                  id="chile"
                  height={50}
                  width="auto"
                />
                <span>Chile</span>
              </S.TabItemContainer>
            ),
            children: <TabContent currentTab={TABS.CHILE} />,
          },
          {
            key: TABS.PUNTA_CANA,
            label: (
              <S.TabItemContainer>
                <img
                  src={dominicanRepublic}
                  alt="dominican-republic"
                  id="dominican-republic"
                  height={50}
                  width="auto"
                />
                <S.TabItemText>
                  <span>Republica</span>
                  <span>Dominicana</span>
                </S.TabItemText>
              </S.TabItemContainer>
            ),
            children: <TabContent currentTab={TABS.PUNTA_CANA} />,
          },
          {
            key: TABS.COLOMBIA,
            disabled: true,
            label: (
              <a
                href={ENDPOINTS.TOUR_LINK.ROOT}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#1e1d4c' }}
              >
                <S.TabItemContainer>
                  <img
                    src={colombia}
                    alt="colombia"
                    id="colombia"
                    height={50}
                    width="auto"
                  />
                  <S.TabItemText>
                    <span>Colômbia</span>
                  </S.TabItemText>
                </S.TabItemContainer>
              </a>
            ),
            children: <></>,
          },
        ]}
      />
    </>
  )
}
