import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Copyrights() {
  const { t } = useTranslation()

  return (
    <S.Container>
      <p>{t('footer.copyrights', { currentYear: new Date().getFullYear() })}</p>
    </S.Container>
  )
}
