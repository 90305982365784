import * as Partner from './partner'
import * as Quote from './quote'
import * as Reservation from './reservation'

const OldApiService = {
  Quote,
  Reservation,
  Partner,
}

export default OldApiService
