import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`

export const Title = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #181e4b;
`

export const Text = styled.p`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0b0b0b;
  white-space: pre-line;
  a {
    text-decoration: none;
    color: #0b0b0b;

    &:hover {
      color: #1677ff;
    }
  }
`

export const Item = styled.li`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #616161;
  width: 100%;
  cursor: pointer;

  & + & {
    margin-top: 12px;
  }
`
