import { useCallback, useMemo, useState } from 'react'

import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Testimonials() {
  const { t } = useTranslation()

  const [current, setCurrent] = useState<number>(0)

  const TESTIMONIALS = useMemo(
    () => [
      {
        testimonial: t('testimonials.firstTestimonial'),
        name: t('testimonials.firstName'),
      },
      {
        testimonial: t('testimonials.secondTestimonial'),
        name: t('testimonials.secondName'),
      },
      {
        testimonial: t('testimonials.thirdTestimonial'),
        name: t('testimonials.thirdName'),
      },
      {
        testimonial: t('testimonials.fourthTestimonial'),
        name: t('testimonials.fourthName'),
      },
      {
        testimonial: t('testimonials.fifthTestimonial'),
        name: t('testimonials.fifthName'),
      },
      {
        testimonial: t('testimonials.sixthTestimonial'),
        name: t('testimonials.sixthName'),
      },
      {
        testimonial: t('testimonials.seventhTestimonial'),
        name: t('testimonials.seventhName'),
      },
      {
        testimonial: t('testimonials.eighthTestimonial'),
        name: t('testimonials.eighthName'),
      },
      {
        testimonial: t('testimonials.ninthTestimonial'),
        name: t('testimonials.ninthName'),
      },
      {
        testimonial: t('testimonials.tenthTestimonial'),
        name: t('testimonials.tenthName'),
      },
    ],
    [t],
  )

  const NEXT_TESTIMONIAL = useMemo(() => {
    if (current === TESTIMONIALS.length - 1) {
      return 0
    }

    return current + 1
  }, [current, TESTIMONIALS.length])

  const nextTestimonial = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === TESTIMONIALS.length - 1) {
        return 0
      }

      return prevState + 1
    })
  }, [TESTIMONIALS.length])

  const previousTestimonial = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === 0) {
        return TESTIMONIALS.length - 1
      }

      return prevState - 1
    })
  }, [TESTIMONIALS.length])

  return (
    <S.Container id="testimonials">
      <S.TitleContainer>
        <S.Title>{t('testimonials.title')}</S.Title>
        <S.Description>{t('testimonials.description')}</S.Description>
      </S.TitleContainer>

      <S.TestimonialsContainer>
        <S.CurrentTestimonial>
          <S.Testimonial>{TESTIMONIALS[current].testimonial}</S.Testimonial>

          <S.Name>{TESTIMONIALS[current].name}</S.Name>
        </S.CurrentTestimonial>

        <S.NextTestimonial>
          <S.Testimonial></S.Testimonial>

          <S.Name>{TESTIMONIALS[NEXT_TESTIMONIAL].name}</S.Name>
        </S.NextTestimonial>

        <S.NavigationContainer>
          <ArrowUp onClick={previousTestimonial} />

          <ArrowDown onClick={nextTestimonial} />
        </S.NavigationContainer>
      </S.TestimonialsContainer>
    </S.Container>
  )
}
