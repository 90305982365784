import Modal, { ModalProps } from 'components/Modal'
import Spacing from 'components/Spacing'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function SemiShared(
  props: Pick<ModalProps, 'visible' | 'onClose'>,
) {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('semiShared.title')}</S.Title>

        <Spacing size={12} />

        <S.Text>{t('semiShared.text1')}</S.Text>
        <S.Text>{t('semiShared.text2')}</S.Text>
      </S.Container>
    </Modal>
  )
}
