import car from 'assets/car.jpeg'

import * as S from './styles'

export default function Slogan2() {
  return (
    <S.Container>
      <S.Cover imageSource={car}>
        <div>
          <p>Surpreenda-se com nossos serviços de</p>
          <p>Transfer Aeroporto e Passeios!</p>
          <p>Qualidade e Credibilidade com</p>
          <p>+7 Anos de experiência.</p>
        </div>
      </S.Cover>
    </S.Container>
  )
}
