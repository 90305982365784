import { useEffect, useMemo } from 'react'

import Button from 'antd/lib/button'
import Modal, { ModalProps } from 'components/Modal'
import { MASK } from 'constants/mask'
import { PAYMENT_SYMBOLS } from 'constants/payment-symbol'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { monetaryMask, roundUpperNumber } from 'utils/maks'

import * as S from './styles'

type PaymentProps = Pick<ModalProps, 'visible'> & {
  reservation?: ReservationApiResponse
  quoteData?: QuoteApiResponse
  onClose: () => void
  onPayment: () => void
}

export default function Payment({
  visible,
  onClose,
  onPayment,
  reservation,
  quoteData,
}: PaymentProps) {
  const { t } = useTranslation()
  const reactHookForm = useFormContext<QuotationApiRequest>()
  const values = reactHookForm.getValues()

  const handleGetLabelPaymentForm = useMemo(
    () => ({
      creditCard: t('quotation.reserveInCardPayment'),
      pix: t('quotation.reserveInPixPayment'),
      wise: t('quotation.reserveInWisePayment'),
    }),
    [t],
  )

  const handleGetValueReservation: Record<PaymentForms, number | undefined> =
    useMemo(
      () => ({
        creditCard: quoteData?.valueCreditCard,
        pix: quoteData?.valuePix,
        wise: (quoteData?.valueTrip || 0) + (quoteData?.valueSpecialBag || 0),
      }),
      [
        quoteData?.valueCreditCard,
        quoteData?.valuePix,
        quoteData?.valueSpecialBag,
        quoteData?.valueTrip,
      ],
    )

  useEffect(() => {
    const data = {
      event: 'confirmed_reservation',
      transactionId: reservation?.reserva.id,
      transactionTotal: roundUpperNumber(
        handleGetValueReservation[values.paymentForm as PaymentForms] || 0,
      ),
      transactionCurrency:
        values.transferType === 'puntaCana' && values.paymentForm === 'wise'
          ? 'USD'
          : PAYMENT_SYMBOLS[values.paymentForm as PaymentForms] || 'CLP',
      transactionMethod: values.paymentForm,
    }
    // eslint-disable-next-line no-console
    console.log(data, window)
    if (window) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(data)
    }
  }, [
    handleGetValueReservation,
    reservation?.reserva.id,
    values.paymentForm,
    values.transferType,
  ])

  return (
    <Modal visible={visible} onClose={onClose}>
      <S.Column style={{ maxWidth: '670px' }}>
        <S.Title style={{ fontSize: '1.5rem', textAlign: 'center' }}>
          {t('quotation.bookingConfirmation')}
        </S.Title>

        <S.SubTitle>
          {t('quotation.reservationMadeSuccessfully')}
          <strong>{' ' + reservation?.reserva.id}</strong>,
          {` na forma de pagamento: `}
          <strong>
            {handleGetLabelPaymentForm[values.paymentForm as PaymentForms]}
          </strong>
          ,{` no valor de: `}
          <strong>
            {monetaryMask(
              roundUpperNumber(
                handleGetValueReservation[values.paymentForm as PaymentForms] ||
                  0,
              ),
              'pt-BR',
              {
                style: 'currency',
                currency:
                  values.transferType === 'puntaCana' &&
                  (values.paymentForm === 'creditCard' ||
                    values.paymentForm === 'wise')
                    ? 'USD'
                    : PAYMENT_SYMBOLS[values.paymentForm as PaymentForms] ||
                      'CLP',
                currencyDisplay: 'symbol',
                maximumFractionDigits:
                  values.paymentForm === 'wise' &&
                  values.transferType !== 'puntaCana'
                    ? 0
                    : 2,
                minimumFractionDigits:
                  values.paymentForm === 'wise' &&
                  values.transferType !== 'puntaCana'
                    ? 0
                    : 2,
              },
            )}
            {values.paymentForm === 'pix' ||
              (values.transferType !== 'puntaCana' &&
                values.paymentForm === 'creditCard' && (
                  <strong>
                    {' '}
                    -
                    <strong style={{ marginLeft: '5px', color: 'red' }}>
                      Esse valor é somente para a cotação de hoje (
                      {dayjs().format(MASK.DATE.LOCALE)})
                    </strong>
                  </strong>
                ))}
          </strong>
        </S.SubTitle>
        <S.SubTitle>
          {t('quotation.reservationMadeSuccessfullySubtitle')}
        </S.SubTitle>
        {values.transferType !== 'puntaCana' && (
          <S.SubTitle>{t('quotation.instructionForPayment')}</S.SubTitle>
        )}

        {values.transferType === 'puntaCana' &&
          values.paymentForm === 'creditCard' && (
            <S.Row style={{ justifyContent: 'center' }}>
              <Button
                type="primary"
                onClick={onPayment}
                style={{ padding: '0.5rem 1rem', height: 'unset' }}
              >
                {t('quotation.makePayment')}
              </Button>
            </S.Row>
          )}
      </S.Column>
    </Modal>
  )
}
