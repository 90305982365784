import React from 'react'

import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
`

const Label = styled.label`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`

const Value = styled(Label)<{ maxWidth?: string; noWrap?: boolean }>`
  font-weight: 700;
  line-height: 28px;
  color: #000000;
  ${(props) => {
    if (props.maxWidth) {
      return css`
        max-width: ${props.maxWidth};
      `
    }
    if (props.noWrap) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    }

    return undefined
  }}
`

export default function TextWithLabel({
  label,
  value,
  maxWidth,
  noWrap,
  style,
}: {
  label: string
  value: string | number
  maxWidth?: string
  noWrap?: boolean
  style?: React.CSSProperties
}) {
  return (
    <Container style={style}>
      <Label>{label}</Label>
      <Value maxWidth={maxWidth} noWrap={noWrap}>
        {value}
      </Value>
    </Container>
  )
}
