import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { SpinProps } from 'antd/es/spin'

import * as Styled from './styles'

type LoadingSpinProps = SpinProps

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />

const LoadingSpin: React.FC<LoadingSpinProps> = ({ ...props }) => (
  <Styled.Overlay data-testid="modal_overlay_loading_spin">
    <Spin indicator={antIcon} {...props} />
  </Styled.Overlay>
)

export default LoadingSpin
