interface WhatsappProps {
  fill?: string
}

export const Whatsapp = ({ fill = '#080808' }: WhatsappProps) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 31.454L12.2926 25.7183C11.0485 23.484 10.6533 20.8767 11.1794 18.3749C11.7054 15.8731 13.1176 13.6447 15.1566 12.0984C17.1957 10.5522 19.7248 9.79189 22.28 9.95707C24.8353 10.1223 27.2451 11.2018 29.0674 12.9977C30.8896 14.7935 32.0021 17.1852 32.2006 19.7339C32.3991 22.2825 31.6704 24.8171 30.1481 26.8726C28.6258 28.9281 26.4121 30.3666 23.9131 30.9241C21.4142 31.4817 18.7977 31.1209 16.5438 29.9079L10.75 31.454ZM16.8231 27.7616L17.1814 27.9738C18.814 28.9398 20.7209 29.3396 22.6048 29.1108C24.4887 28.882 26.2439 28.0374 27.5969 26.7087C28.9498 25.3801 29.8245 23.6419 30.0846 21.7651C30.3448 19.8883 29.9757 17.9783 29.035 16.3328C28.0943 14.6872 26.6348 13.3986 24.8839 12.6676C23.133 11.9367 21.1891 11.8045 19.355 12.2917C17.521 12.7789 15.9 13.858 14.7444 15.361C13.5889 16.864 12.9639 18.7064 12.9667 20.6011C12.9652 22.1721 13.4004 23.7127 14.2238 25.0514L14.4485 25.4212L13.5862 28.6225L16.8231 27.7616Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5194 22.0738C25.3094 21.9047 25.0634 21.7857 24.8003 21.7258C24.5372 21.6659 24.2639 21.6667 24.0011 21.7283C23.6064 21.892 23.3513 22.5104 23.0962 22.8196C23.0425 22.8937 22.9634 22.9457 22.874 22.9658C22.7845 22.9859 22.6908 22.9727 22.6104 22.9287C21.1652 22.3636 19.9538 21.3273 19.173 19.9882C19.1064 19.9046 19.0749 19.7985 19.0851 19.6922C19.0953 19.5859 19.1464 19.4877 19.2277 19.4182C19.5122 19.1371 19.721 18.7888 19.835 18.4057C19.8603 17.9831 19.7633 17.5622 19.5556 17.1931C19.3951 16.6756 19.0895 16.2149 18.675 15.8653C18.4612 15.7693 18.2242 15.7371 17.9925 15.7726C17.7608 15.8081 17.5444 15.9098 17.3693 16.0653C17.0653 16.3272 16.824 16.6537 16.6632 17.021C16.5024 17.3883 16.4262 17.7868 16.4401 18.1874C16.441 18.4124 16.4696 18.6363 16.5251 18.8544C16.6661 19.3782 16.883 19.8786 17.1689 20.3398C17.3751 20.6931 17.6001 21.0352 17.843 21.3645C18.6322 22.4461 19.6243 23.3645 20.7642 24.0686C21.3362 24.4264 21.9475 24.7173 22.5861 24.9356C23.2495 25.2358 23.9819 25.3511 24.7056 25.2691C25.118 25.2068 25.5087 25.0442 25.8433 24.7958C26.1779 24.5473 26.4463 24.2206 26.6247 23.8443C26.7296 23.6169 26.7614 23.3628 26.7158 23.1167C26.6065 22.6135 25.9324 22.3164 25.5194 22.0738Z"
        fill={fill}
      />
    </svg>
  )
}
