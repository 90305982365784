import axios from 'axios'

const oldApiAxiosInstance = axios.create({
  baseURL: 'https://reservas.transferbrasil.cl/Services',
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
  },
})

export default oldApiAxiosInstance
