import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  align-items: center;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  /* width: 100%; */
  margin-bottom: 53px;
  flex-direction: column;
  align-items: center;

  img {
    align-self: center;
  }

  @media ${devicesMinWidth.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ContentLogos = styled.div`
  display: flex;
  width: 90%;
  margin-bottom: 53px;
  flex-direction: column;
  align-items: center;

  img {
    align-self: center;
  }

  @media ${devicesMinWidth.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
  }
`

export const FooterItem = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: 20px;

  #sernatur,
  #sello-turistico {
    width: auto;
  }

  @media ${devicesMinWidth.laptop} {
    margin-left: 72px;

    &#social-media {
      margin-left: 72px;
    }
  }
`

export const Title = styled.span`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 138.5%;
  letter-spacing: 0.08em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #080808;
`

export const Item = styled(Title)`
  font-weight: 400;
  text-decoration: none;
`

export const SocialMediaContainer = styled.div`
  display: flex;

  svg {
    cursor: pointer;
  }
`

export const Copyrights = styled.span`
  /* width: 100vw; */
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1.5px solid #f0f0f0;
  font-family: 'Sora';
  font-style: normal;
  font-size: 11px;
  font-weight: 400;
  color: #080808;
  text-align: center;
  margin-left: -19px;

  @media ${devicesMinWidth.laptop} {
    margin-left: -122px;
    font-size: 12px;
  }

  @media ${devicesMinWidth.desktop} {
    width: 130%;
    margin-left: -15%;
  }
`
