import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  text-align: center;

  > div {
    max-width: 800px;
  }
  @media ${devicesMinWidth.tablet} {
    p {
      font-size: 30px;
    }
  }
`
