export const getTransferLabel = (
  t: (value: string) => string,
  transfer: Transfer,
) => {
  const TRANSFER_LABELS: Record<Transfer, string> = {
    roundtrip: t('quotation.roundTrip'),
    oneWayTrip: t('quotation.oneWay'),
    returnTrip: t('quotation.returnTrip'),
  }

  return TRANSFER_LABELS[transfer]
}

export const getTransferTypeLabel = (
  t: (value: string) => string,
  transferType: TransferType,
) => {
  const TRANSFER_TYPES_LABELS: Record<TransferType, string> = {
    airport: t('quotation.airport'),
    skiStation: t('quotation.skiStation'),
    coast: t('quotation.coast'),
    puntaCana: t('quotation.puntaCana'),
  }

  return TRANSFER_TYPES_LABELS[transferType]
}

export const getTypeOfTransportLabel = (
  t: (value: string) => string,
  typeOfTransport: TypeOfTransport,
) => {
  const TYPE_OF_TRANSPORT_LABELS: Record<TypeOfTransport, string> = {
    semiShared: t('quotation.semiShared'),
    private: t('quotation.private'),
  }

  return TYPE_OF_TRANSPORT_LABELS[typeOfTransport]
}

export const getFlightTypeLabel = (
  t: (value: string) => string,
  flightType: FlightType,
) => {
  const FLIGHT_TYPE_LABELS: Record<FlightType, string> = {
    national: t('quotation.national'),
    international: t('quotation.international'),
  }

  return FLIGHT_TYPE_LABELS[flightType]
}
