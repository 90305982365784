import { useCallback, useEffect, useState } from 'react'

import { isValidCPF } from '@brazilian-utils/brazilian-utils'
import { AutoCompleteProps, Form } from 'antd'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import InputField from 'components/InputField'
import LoadingSpin from 'components/LoadingSpin'
import Modal, { ModalProps } from 'components/Modal'
import { MASK } from 'constants/mask'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/pt-br'
import InputMask from 'react-input-mask'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import OldApiService from 'services/api/old-api'

import * as S from './styles'

type Step4Props = Pick<ModalProps, 'visible'> & {
  onNextClick: () => void
  onPreviousClick?: () => void
  onClose: () => void
  partners: AutoCompleteProps['options']
}

export default function ContactDetails({
  visible,
  onNextClick,
  onPreviousClick,
  onClose,
  partners,
}: Step4Props) {
  const [queryParas] = useSearchParams()
  const partnerQuery = queryParas.get('parceiro')
  const { t } = useTranslation()
  const reactHookForm = useFormContext<QuotationApiRequest>()
  const [loading, setLoading] = useState(true)

  const loadPartners = useCallback(async () => {
    try {
      const partners = await OldApiService.Partner.get()
      const findSelectedPartner = partners.find(
        (partner) => partner.Parceiro.url_amigavel === partnerQuery,
      )
      if (findSelectedPartner) {
        reactHookForm.setValue('whereYouMetUs', findSelectedPartner.Parceiro.id)
        reactHookForm.setError('whereYouMetUs', {})
      }
    } catch (error) {
      toast.error(t('errors.generic'))
    }
    setLoading(false)
  }, [partnerQuery, reactHookForm, t])

  useEffect(() => {
    loadPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingSpin />
  }

  return (
    <Modal visible={visible} onClose={onClose}>
      <S.Column style={{ maxWidth: '670px' }}>
        <S.Title>{t('quotation.contactDetails')}</S.Title>
        <S.SubTitle>{t('quotation.flightDataDetails')}</S.SubTitle>

        <S.Row>
          <div style={{ flexGrow: 1, flexBasis: '75%' }}>
            <InputField label={t('quotation.name')}>
              <Controller
                name="name"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('name', {})
                    }}
                    status={
                      reactHookForm.formState.errors.name?.message
                        ? 'error'
                        : ''
                    }
                    value={value}
                  />
                )}
              />
            </InputField>
          </div>

          <div style={{ flexGrow: 1, flexBasis: '25%' }}>
            <Form.Item
              style={{ marginBottom: 0 }}
              help={reactHookForm.formState.errors.document?.message}
              validateStatus={
                reactHookForm.formState.errors.document?.message ? 'error' : ''
              }
            >
              <InputField label={t('quotation.cpf')}>
                <Controller
                  name="document"
                  control={reactHookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <InputMask
                      mask={MASK.CPF}
                      value={value}
                      onChange={(event) => {
                        reactHookForm.setError('document', {})
                        onChange(event)
                        if (
                          event.target.value?.replace(/\D+/g, '')?.length ===
                            11 &&
                          !isValidCPF(event.target.value)
                        ) {
                          reactHookForm.setError('document', {
                            message: t('errors.invalidCpf') as string,
                          })
                        }
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      {/* @ts-ignore */}
                      {(props) => (
                        <Input
                          {...props}
                          status={
                            reactHookForm.formState.errors.document?.message
                              ? 'error'
                              : ''
                          }
                        />
                      )}
                    </InputMask>
                  )}
                />
              </InputField>
            </Form.Item>
          </div>
        </S.Row>

        <S.Row>
          <div style={{ flexGrow: 1, flexBasis: '75%' }}>
            <InputField label={t('quotation.email')}>
              <Controller
                name="email"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('email', {})
                    }}
                    status={
                      reactHookForm.formState.errors.email?.message
                        ? 'error'
                        : ''
                    }
                    value={value}
                  />
                )}
              />
            </InputField>
          </div>

          <div style={{ flexGrow: 1, flexBasis: '25%' }}>
            <InputField label={t('quotation.cellPhone')}>
              <Controller
                name="cellPhone"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <InputMask
                    mask={MASK.CELL_PHONE}
                    value={value}
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('cellPhone', {})
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    {(props) => (
                      <Input
                        {...props}
                        status={
                          reactHookForm.formState.errors.cellPhone?.message
                            ? 'error'
                            : ''
                        }
                      />
                    )}
                  </InputMask>
                )}
              />
            </InputField>
          </div>
        </S.Row>

        <S.Row>
          <div style={{ flexGrow: 1 }}>
            <InputField label={t('quotation.whereYouMetUs')} fullWidth>
              <Select
                style={{ width: '33%' }}
                value={reactHookForm.watch('whereYouMetUs')}
                onChange={(value) => {
                  reactHookForm.setValue('whereYouMetUs', value)
                  reactHookForm.setError('whereYouMetUs', {})
                }}
                status={
                  reactHookForm.formState.errors.whereYouMetUs?.message
                    ? 'error'
                    : ''
                }
                options={partners}
              />
            </InputField>
          </div>
        </S.Row>

        <S.Row style={{ justifyContent: 'space-between' }}>
          <Button type="primary" ghost onClick={onPreviousClick}>
            {t('quotation.previous')}
          </Button>
          <Button type="primary" onClick={onNextClick}>
            {t('quotation.next')}
          </Button>
        </S.Row>
      </S.Column>
    </Modal>
  )
}
