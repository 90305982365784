import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  margin-top: 4rem;

  @media ${devicesMinWidth.tablet} {
    gap: 30px;
    margin-left: 0;
  }

  @media ${devicesMinWidth.laptopL} {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

export const Title = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 50px;
  text-align: center;
  color: #14183e;
  margin-bottom: 25px;
`

export const Card = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 280px;
  max-width: 100%;
  padding: 30px 10px;

  background: #f0f3f6;

  h5 {
    margin: 5px 0px;
  }

  p {
    font-size: 12px;
    margin: 0px;
  }

  img {
    width: 90px;
    height: 90px;
  }
  @media ${devicesMinWidth.tablet} {
    width: 320px;
  }
`
