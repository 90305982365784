import Modal, { ModalProps } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import Declaration from '../../../../assets/documents/joint-customs-declaration.pdf'
import * as S from './styles'

export default function Requirements({
  currentTab,
  ...props
}: Pick<ModalProps, 'visible' | 'onClose'> & { currentTab: string }) {
  const { t } = useTranslation()

  if (currentTab === 'puntaCana') {
    return (
      <Modal {...props}>
        <S.Container>
          <S.Title>{t('requirements.title')}</S.Title>

          <S.Text>{t('requirements.puntaCana.requirement1')}</S.Text>
          <S.Text>{t('requirements.puntaCana.requirement2')}</S.Text>
          <S.Text
            dangerouslySetInnerHTML={{
              __html: t('requirements.puntaCana.requirement3'),
            }}
          />
          <S.Text>{t('requirements.puntaCana.requirement4')}</S.Text>
        </S.Container>
      </Modal>
    )
  }

  return (
    <Modal {...props}>
      <S.Container>
        <S.Title>{t('requirements.title')}</S.Title>

        <S.Text>{t('requirements.chile.requirement1')}</S.Text>
        <S.Text>{t('requirements.chile.requirement2')}</S.Text>
        <S.Text>{t('requirements.chile.requirement3')}</S.Text>

        <S.Text
          dangerouslySetInnerHTML={{
            __html: t('requirements.chile.requirement4', { link: Declaration }),
          }}
        />
        <S.Text>{t('requirements.chile.requirement5')}</S.Text>
      </S.Container>
    </Modal>
  )
}
