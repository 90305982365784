import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  margin-top: 105px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @media ${devicesMinWidth.laptop} {
    flex-flow: row nowrap;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  @media ${devicesMinWidth.laptop} {
    width: 45%;
  }
`

export const Title = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 62px;
  color: #5e6282;
  text-transform: uppercase;
`

export const Description = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 62px;
  color: #14183e;
`

export const TestimonialsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  @media ${devicesMinWidth.laptop} {
    width: 40%;
  }
`

export const CurrentTestimonial = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
`

export const NextTestimonial = styled(CurrentTestimonial)`
  position: absolute;
  z-index: -1;
  background-color: white;
  left: 15px;
  box-shadow: none;
  border: 2px solid #f7f7f7;
  width: 80%;
  height: 100%;
  top: unset;
  bottom: -72px;
  justify-content: flex-end;

  @media ${devicesMinWidth.laptop} {
    top: 80px;
    left: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: column;
  }
`

export const Testimonial = styled.span`
  font-family: 'Sora';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #5e6282;

  @media ${devicesMinWidth.laptop} {
    min-height: 112px;
    min-width: 436px;
  }
`

export const Name = styled.span`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #5e6282;
  margin-top: 30px;
`

export const NavigationContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 80px;
  margin-left: 80px;
  margin-top: 150px;

  svg {
    cursor: pointer;
  }
`
