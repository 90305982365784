import { Form } from 'antd'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import DatePicker from 'components/DatePicker'
import InputField from 'components/InputField'
import Modal, { ModalProps } from 'components/Modal'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'

import * as S from './styles'

type Step4Props = Pick<ModalProps, 'visible'> & {
  onNextClick: () => void
  onPreviousClick?: () => void
  onClose: () => void
}

export default function FlightDetails({
  visible,
  onNextClick,
  onPreviousClick,
  onClose,
}: Step4Props) {
  const { t } = useTranslation()
  const reactHookForm = useFormContext<QuotationApiRequest>()
  const transfer = reactHookForm.watch('transfer')

  return (
    <Modal visible={visible} onClose={onClose}>
      <S.Column style={{ maxWidth: '670px' }}>
        <S.Title>{t('quotation.flightData')}</S.Title>
        <S.SubTitle>{t('quotation.flightDataDetails')}</S.SubTitle>

        <S.Title style={{ fontSize: '16px' }}>
          {t('quotation.arrivalData')}
        </S.Title>

        {(transfer === 'roundtrip' || transfer === 'oneWayTrip') && (
          <S.Row>
            <InputField label={t('quotation.date')}>
              <Controller
                name="arrivalDate"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    disabledDate={(current) => {
                      return (
                        current &&
                        current < dayjs().subtract(1, 'day').endOf('day')
                      )
                    }}
                    value={value ? dayjs(value) : undefined}
                    onChange={onChange}
                    status={
                      reactHookForm.formState.errors.arrivalDate?.message
                        ? 'error'
                        : ''
                    }
                  />
                )}
              />
            </InputField>

            <InputField label={t('quotation.hour')}>
              <Controller
                name="arrivalFlightTime"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <InputMask
                    mask="99:99"
                    value={value}
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('arrivalFlightTime', {})
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    {(props) => (
                      <Input
                        {...props}
                        status={
                          reactHookForm.formState.errors.arrivalFlightTime
                            ?.message
                            ? 'error'
                            : ''
                        }
                      />
                    )}
                  </InputMask>
                )}
              />
            </InputField>

            <InputField label={t('quotation.flightNumber')}>
              <Controller
                name="arrivalFlightNumber"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('arrivalFlightNumber', {})
                    }}
                    status={
                      reactHookForm.formState.errors.arrivalFlightNumber
                        ?.message
                        ? 'error'
                        : ''
                    }
                    value={value}
                  />
                )}
              />
            </InputField>

            <InputField label={t('quotation.type')}>
              <Controller
                name="arrivalFlightType"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    style={{ width: '150px' }}
                    value={value}
                    onChange={onChange}
                    status={
                      reactHookForm.formState.errors.arrivalFlightType?.message
                        ? 'error'
                        : ''
                    }
                    options={[
                      { value: 'national', label: t('quotation.national') },
                      {
                        value: 'international',
                        label: t('quotation.international'),
                      },
                    ]}
                  />
                )}
              />
            </InputField>
          </S.Row>
        )}

        <S.Title style={{ fontSize: '16px' }}>
          {t('quotation.departureData')}
        </S.Title>

        {(transfer === 'roundtrip' || transfer === 'returnTrip') && (
          <S.Row>
            <Form.Item
              style={{ marginBottom: 0 }}
              help={reactHookForm.formState.errors.departureDate?.message}
              validateStatus={
                reactHookForm.formState.errors.departureDate?.message
                  ? 'error'
                  : ''
              }
            >
              <InputField label={t('quotation.departureDate')}>
                <Controller
                  name="departureDate"
                  control={reactHookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      disabledDate={(current) => {
                        return (
                          current &&
                          current < dayjs().subtract(1, 'day').endOf('day')
                        )
                      }}
                      value={value ? dayjs(value) : undefined}
                      onChange={onChange}
                      status={
                        reactHookForm.formState.errors.departureDate?.message
                          ? 'error'
                          : ''
                      }
                    />
                  )}
                />
              </InputField>
            </Form.Item>

            <InputField label={t('quotation.hour')}>
              <Controller
                name="departureFlightTime"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <InputMask
                    mask="99:99"
                    value={value}
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('departureFlightTime', {})
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    {(props) => (
                      <Input
                        {...props}
                        status={
                          reactHookForm.formState.errors.departureFlightTime
                            ?.message
                            ? 'error'
                            : ''
                        }
                      />
                    )}
                  </InputMask>
                )}
              />
            </InputField>

            <InputField label={t('quotation.flightNumber')}>
              <Controller
                name="departureFlightNumber"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    onChange={(event) => {
                      onChange(event)
                      reactHookForm.setError('departureFlightNumber', {})
                    }}
                    status={
                      reactHookForm.formState.errors.departureFlightNumber
                        ?.message
                        ? 'error'
                        : ''
                    }
                    value={value}
                  />
                )}
              />
            </InputField>

            <InputField label={t('quotation.type')}>
              <Controller
                name="departureFlightType"
                control={reactHookForm.control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    style={{ width: '150px' }}
                    value={value}
                    onChange={onChange}
                    status={
                      reactHookForm.formState.errors.departureFlightType
                        ?.message
                        ? 'error'
                        : ''
                    }
                    options={[
                      { value: 'national', label: t('quotation.national') },
                      {
                        value: 'international',
                        label: t('quotation.international'),
                      },
                    ]}
                  />
                )}
              />
            </InputField>
          </S.Row>
        )}

        <S.Row>
          <InputField label={t('quotation.observations')} fullWidth>
            <Controller
              name="observations"
              control={reactHookForm.control}
              render={({ field: { value, onChange } }) => (
                <Input.TextArea rows={4} onChange={onChange} value={value} />
              )}
            />
          </InputField>
        </S.Row>

        <S.Row style={{ justifyContent: 'space-between' }}>
          <Button type="primary" ghost onClick={onPreviousClick}>
            {t('quotation.previous')}
          </Button>
          <Button type="primary" onClick={onNextClick}>
            {t('quotation.next')}
          </Button>
        </S.Row>
      </S.Column>
    </Modal>
  )
}
