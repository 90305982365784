import ENDPOINTS from 'constants/endpoints'

import oldApiAxiosInstance from './oldApiAxiosInstance'

export const get = async (): Promise<PartnerApiResponse[]> => {
  try {
    const response = await oldApiAxiosInstance.get<PartnerApiResponse[]>(
      ENDPOINTS.OLD.PARTNER.GET,
    )

    return response.data
  } catch (sendError) {
    return Promise.reject()
  }
}
