import ENDPOINTS from 'constants/endpoints'

import oldApiAxiosInstance from './oldApiAxiosInstance'

export const checkAvailable = async (
  data: FormData,
): Promise<QuoteApiResponse> => {
  try {
    const response = await oldApiAxiosInstance.post<QuoteApiResponse>(
      ENDPOINTS.OLD.RESERVATION.CHECK_AVAILABLE,
      data,
    )

    return response.data
  } catch (sendError) {
    return Promise.reject()
  }
}
export const create = async (
  data: FormData,
): Promise<ReservationApiResponse> => {
  try {
    const response = await oldApiAxiosInstance.post<ReservationApiResponse>(
      ENDPOINTS.OLD.RESERVATION.CREATE,
      data,
    )

    return response.data
  } catch (sendError) {
    return Promise.reject()
  }
}
export const getLinkPayment = async (id: string): Promise<string> => {
  try {
    const response = await oldApiAxiosInstance.get<string>(
      ENDPOINTS.OLD.RESERVATION.GET_LINK_PAYMENT(id),
    )

    return response.data
  } catch (sendError) {
    return Promise.reject()
  }
}
