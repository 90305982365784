import { devicesMinWidth } from 'constants/media-queries'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  text-align: center;

  p {
    margin: 5px 0px;
    color: #d2d2d2;

    @media ${devicesMinWidth.tablet} {
      font-size: 24px;
    }
  }
`

export const Cover = styled.div<{ imageSource: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;

  background-image: ${(props) => `url(${props.imageSource})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  > div {
    width: 100%;
    height: auto;
    text-align: center;

    @media ${devicesMinWidth.tablet} {
      width: auto;
      background: none;
      margin-left: auto;
      margin-right: 100px;
    }
    @media ${devicesMinWidth.laptopL} {
      margin-left: auto;
      margin-right: 300px;
    }
  }
`
