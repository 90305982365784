import { useCallback } from 'react'

import { ReactComponent as Facebook } from 'assets/facebook.svg'
import { ReactComponent as Gmail } from 'assets/gmail.svg'
import { ReactComponent as Instagram } from 'assets/instagram.svg'
import logo from 'assets/logo.png'
import selloTuristico from 'assets/sello-turistico.png'
import sernatur from 'assets/sernatur.png'
import { ReactComponent as WhatsApp } from 'assets/whatsapp.svg'
import { ReactComponent as Youtube } from 'assets/youtube.svg'
import ROUTES from 'constants/router'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Footer() {
  const { t } = useTranslation()

  const goToSocialMedia = useCallback((link: string) => {
    window.open(link, '_blank')
  }, [])

  return (
    <S.Container>
      <S.ContentLogos>
        <S.FooterItem>
          <img src={logo} alt="logo" height={100} />
        </S.FooterItem>
        <S.FooterItem>
          <a
            target="_blank"
            href="https://www.tripadvisor.cl/Attraction_Review-g294305-d15046241-Reviews-Transfer_Brasil-Santiago_Santiago_Metropolitan_Region.html"
            rel="noreferrer"
          >
            <img
              src="https://www.tripadvisor.cl/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png"
              alt="TripAdvisor"
              className="widCOEImg"
              id="CDSWIDCOELOGO"
            />
          </a>
        </S.FooterItem>
        <S.FooterItem>
          <a
            target="_blank"
            href="https://www.tripadvisor.cl/Attraction_Review-g294305-d15046241-Reviews-Transfer_Brasil-Santiago_Santiago_Metropolitan_Region.html"
            rel="noreferrer"
          >
            <img
              src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2023_LL.png"
              alt="TripAdvisor"
              className="widCOEImg"
              id="CDSWIDCOELOGO"
            />
          </a>
        </S.FooterItem>

        <S.FooterItem>
          <img
            src={selloTuristico}
            alt="sello-turistico"
            id="sello-turistico"
            height={100}
          />
        </S.FooterItem>

        <S.FooterItem>
          <img src={sernatur} alt="sernatur" id="sernatur" height={100} />
        </S.FooterItem>
      </S.ContentLogos>
      <S.Content>
        <S.FooterItem>
          <S.Title>{t('footer.contacts')}</S.Title>

          <S.Item>{t('footer.address')}</S.Item>
          <S.Item>{t('footer.phone')}</S.Item>
        </S.FooterItem>

        <S.FooterItem>
          <S.Title>{t('footer.links')}</S.Title>

          <S.Item as="a" href={ROUTES.TERMS_CONDITIONS}>
            {t('footer.termsAndConditions')}
          </S.Item>

          <S.Item as="a" href={ROUTES.PRIVACY_POLICY}>
            {t('footer.privacyPolicy')}
          </S.Item>
        </S.FooterItem>

        <S.FooterItem id="social-media">
          <S.Title style={{ paddingLeft: '10px' }}>
            {t('footer.socialMedia')}
          </S.Title>

          <S.SocialMediaContainer>
            <Instagram
              onClick={() =>
                goToSocialMedia('https://www.instagram.com/transferbrasil')
              }
            />

            <Facebook
              onClick={() =>
                goToSocialMedia('https://www.facebook.com/transfer.brasil')
              }
            />

            <Youtube
              onClick={() =>
                goToSocialMedia(
                  'https://www.youtube.com/@transferbrasilsantiagochil5836',
                )
              }
            />

            <WhatsApp
              onClick={() => goToSocialMedia('https://wa.me/56941016076')}
            />

            <Gmail
              onClick={() =>
                goToSocialMedia('mailto:contato@transferbrasil.cl')
              }
            />
          </S.SocialMediaContainer>
        </S.FooterItem>
      </S.Content>
    </S.Container>
  )
}
