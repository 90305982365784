import { createContext, useContext, useState, useCallback } from 'react'
import type { PropsWithChildren } from 'react'

import { LANGUAGE } from 'constants/keys'
import LANGUAGES from 'constants/languages'
import { TRANSFER_TYPES } from 'constants/types'
import i18next from 'i18next'

import * as Types from './types'

const GlobalContext = createContext<Types.GlobalState>({} as Types.GlobalState)

export const GlobalStateProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [transferType, setTransferType] = useState<TransferType>(
    TRANSFER_TYPES[0],
  )
  const [languageSelected, setLanguageSelected] = useState<LanguagesTypes>(
    () => {
      const storageLanguage = localStorage.getItem(LANGUAGE)
      return (storageLanguage as LanguagesTypes) || LANGUAGES.pt
    },
  )
  const [invalidAddressModalOpened, setInvalidAddressModalOpened] =
    useState<boolean>(false)
  const [
    addressIncludesAirportModalOpened,
    setAddressIncludesAirportModalOpened,
  ] = useState<boolean>(false)
  const [isLoadingValidateAddress, setIsLoadingValidateAddress] =
    useState(false)

  const handleChangeLanguage = useCallback((language: LanguagesTypes) => {
    localStorage.setItem(LANGUAGE, language)
    i18next.changeLanguage(language)
    setLanguageSelected(language)
  }, [])

  const closeModalInvalidAddress = useCallback(() => {
    setInvalidAddressModalOpened(false)
  }, [])

  const openModalInvalidAddress = useCallback(() => {
    setInvalidAddressModalOpened(true)
  }, [])

  const setLoadingValidateAddress = useCallback((value: boolean) => {
    setIsLoadingValidateAddress(value)
  }, [])

  const closeModalAddressIncludesAirport = useCallback(() => {
    setAddressIncludesAirportModalOpened(false)
  }, [])
  const openModalAddressIncludesAirport = useCallback(() => {
    setAddressIncludesAirportModalOpened(true)
  }, [])

  const setCurrentTransferType = useCallback((value: TransferType) => {
    setTransferType(value)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        invalidAddressModalOpened,
        closeModalInvalidAddress,
        openModalInvalidAddress,
        closeModalAddressIncludesAirport,
        openModalAddressIncludesAirport,
        addressIncludesAirportModalOpened,
        handleChangeLanguage,
        languageSelected,
        setLoadingValidateAddress,
        isLoadingValidateAddress,
        transferType,
        setCurrentTransferType,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error(
      'useGlobalState should be encapsuled inside GlobalContextProvider',
    )
  }

  return useContext(GlobalContext)
}
